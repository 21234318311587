<template>
   <div class="wrapper">
        <composite v-if="this.$store.state.user_auth != null"> </composite>
       
             <main role="main" class="main-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="row align-items-center my-4 align-items-center">
                                <div class="col">
                                    <h2 class="h3 mb-0 page-title">Calendrier</h2>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-primary float-right" data-toggle="modal" data-target=".modal-full"><span class="fe fe-filter fe-12 mr-2"></span>Etablir un horaire</button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12" v-if="this.calendarFreesHorsRdvs.length == 0">
                                    <lottie-player v-if="this.calendarFreesHorsRdvs.length == 0" class="text-center"
                                    :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                    background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                </div>
    
                                <div class="col-md-12" v-if="this.calendarFreesHorsRdvs.length > 0">
                                    <div class="row justify-content-center">
                                        <div class="col-md-4" v-for="(dat, index) in calendarFreesHorsRdvs" :key="index">
                                            <div class="row m-2 justify-content-center border border-white border-rounded" style="text-align: center;padding: -3px;border-radius: 28px;">
                                                <div class="col-8"  style="cursor:pointer;padding-top: 4px !important;" data-toggle="modal" data-target=".modal-right" @click.prevent="selectHoraireFreeTimeCible(dat.horaire_coding)">
                                                    <span>
                                                        {{ this.gb_capitalisingFormat(this.bg_simple_date_format(dat.horaire_date, "dddd")) }},
                                                        {{ this.bg_simple_date_format(dat.horaire_date, "DD-MM-YYYY") }}
                                                    </span>
                                                </div>
                                                <div class="col-4">
                                                    <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="text-muted sr-only">Action</span>
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-right ">
                                                        <a class="dropdown-item" data-toggle="modal" data-target=".modal-right" href="# ">Détails</a>
                                                        <a class="dropdown-item text-danger" href="./support-ticket-detail.html ">Supprimer</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 d-none" v-if="this.calendarFreesHorsRdvs.length > 0">
                                    <div class="row justify-content-center">
                                        <div class="col-md-6" v-for="(dat, index) in calendarFreesHorsRdvs" :key="index">
                                            <tr scope="col">
                                                <td scope="col"  style="cursor:pointer;" data-toggle="modal" data-target=".modal-right" @click.prevent="selectHoraireFreeTimeCible(dat.horaire_coding)">
                                                    <p>
                                                        {{ dat.horaire_date.toString().substring(0,10) }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="text-muted sr-only">Action</span>
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-right ">
                                                        <a class="dropdown-item " href="./support-ticket-detail.html ">Détails</a>
                                                        <a class="dropdown-item " data-toggle="modal" data-target=".modal-right" href="# ">Ajournée</a>
                                                        <a class="dropdown-item " href="# ">Note</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade modal-notif modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true" style="z-index:99999999999">
                    <div class="modal-dialog modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="defaultModalLabel">Notifications</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body">
                                <div class="list-group list-group-flush my-n3">
                                    <div class="list-group-item bg-transparent">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span class="fe fe-box fe-24"></span>
                                            </div>
                                            <div class="col">
                                                <small><strong>Package has uploaded successfull</strong></small>
                                                <div class="my-0 text-muted small">Package is zipped and uploaded</div>
                                                <small class="badge badge-pill badge-light text-muted">1m ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item bg-transparent">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span class="fe fe-download fe-24"></span>
                                            </div>
                                            <div class="col">
                                                <small><strong>Widgets are updated successfull</strong></small>
                                                <div class="my-0 text-muted small">Just create new layout Index, form, table</div>
                                                <small class="badge badge-pill badge-light text-muted">2m ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item bg-transparent">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span class="fe fe-inbox fe-24"></span>
                                            </div>
                                            <div class="col">
                                                <small><strong>Notifications have been sent</strong></small>
                                                <div class="my-0 text-muted small">Fusce dapibus, tellus ac cursus commodo</div>
                                                <small class="badge badge-pill badge-light text-muted">30m ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item bg-transparent">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span class="fe fe-link fe-24"></span>
                                            </div>
                                            <div class="col">
                                                <small><strong>Link was attached to menu</strong></small>
                                                <div class="my-0 text-muted small">New layout has been attached to the menu</div>
                                                <small class="badge badge-pill badge-light text-muted">1h ago</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal">Clear All</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade modal-delete modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true" style="z-index:99999999999">
                    <div class="modal-dialog modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="defaultModalLabel">Notifications</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body">
                                <div class="list-group list-group-flush my-n3">
                                    <div class="list-group-item bg-transparent">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span class="fe fe-info fe-24"></span>
                                            </div>
                                            <div class="col">
                                                <small class="text-uppercase"><strong>A savoir</strong></small>
                                                <div class="my-0 text-muted small">Le Pastor supprimé n'aura plus droit d'accèder à la plateforme avec ses accès, ni consulté quoi que ce soit sur la plateforme. Tout ses accès son prohibé.</div>
                                                <!-- <small class="badge badge-pill badge-light text-muted">1m ago</small> -->
                                                <!-- <small class="badge badge-info badge-light text-muted">1m ago</small> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal" aria-label="Close">Fermer</button>
                                <button type="button" class="btn btn-danger btn-block"  @click.prevent="delete_pastor">Supprimer</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade modal-update-status modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true" style="z-index:99999999999">
                    <div class="modal-dialog modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="defaultModalLabel">Notifications</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body">
                                <div class="list-group list-group-flush my-n3">
                                    <div class="list-group-item bg-transparent">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span class="fe fe-info fe-24"></span>
                                            </div>
                                            <div class="col">
                                                <small class="text-uppercase"><strong>A savoir</strong></small>
                                                <div class="my-0 text-muted small">Le Pastor désactivé n'aura plus droit d'accèder à la plateforme avec ses accès, ni consulté quoi que ce soit sur la plateforme. Tout ses accès son prohibé.</div>
                                                <div class="my-0 text-muted small">Le Pastor activé dispose de pplein droit d'accès selon son profil.</div>
                                                <!-- <small class="badge badge-pill badge-light text-muted">1m ago</small> -->
                                                <!-- <small class="badge badge-info badge-light text-muted">1m ago</small> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal" aria-label="Close">Fermer</button>
                                <button type="button" class="btn btn-info btn-block"  @click.prevent="toggle_status_pastor">Changer status</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade modal-shortcut modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="defaultModalLabel">Shortcuts</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body px-5">
                                <div class="row align-items-center">
                                    <div class="col-6 text-center">
                                        <div class="squircle bg-success justify-content-center">
                                            <i class="fe fe-cpu fe-32 align-self-center text-white"></i>
                                        </div>
                                        <p>Control area</p>
                                    </div>
                                    <div class="col-6 text-center">
                                        <div class="squircle bg-primary justify-content-center">
                                            <i class="fe fe-activity fe-32 align-self-center text-white"></i>
                                        </div>
                                        <p>Activity</p>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-6 text-center">
                                        <div class="squircle bg-primary justify-content-center">
                                            <i class="fe fe-droplet fe-32 align-self-center text-white"></i>
                                        </div>
                                        <p>Droplet</p>
                                    </div>
                                    <div class="col-6 text-center">
                                        <div class="squircle bg-primary justify-content-center">
                                            <i class="fe fe-upload-cloud fe-32 align-self-center text-white"></i>
                                        </div>
                                        <p>Upload</p>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-6 text-center">
                                        <div class="squircle bg-primary justify-content-center">
                                            <i class="fe fe-users fe-32 align-self-center text-white"></i>
                                        </div>
                                        <p>Users</p>
                                    </div>
                                    <div class="col-6 text-center">
                                        <div class="squircle bg-primary justify-content-center">
                                            <i class="fe fe-settings fe-32 align-self-center text-white"></i>
                                        </div>
                                        <p>Settings</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="this.pastor !=null" class="modal fade modal-right modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="defaultModalLabel">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                            </div>
                            <div class="modal-body">
                                
                            
                            
                              <div class="container-fluid">
                                <div class="row justify-content-center">
                                    <div class="col-12 col-lg-10 col-xl-12">
                                        <!-- <h2 class="h3 page-title">Profile</h2> -->
                                        <div class="row mt-2 align-items-center">
                                            <div class="col-md-3 text-center mb-5">
                                                <div class="avatar avatar-xl">
                                                    <img :src="this.$store.state.app_url_api_storage_img_dev + this.pastor.identity?.user?.user_avatar" alt="..." class="avatar-img rounded-circle">
                                                </div>
                                            </div>
                                            <div class="col-md-offset-md-1 col-md-8">
                                                <div class="row align-items-center mb-3">
                                                    <div class="col-md-12">
                                                    <h4 class="mb-1">{{ (this.pastor.identity.identity_firstname != null && this.pastor.identity.identity_firstname.trim() != "" ? this.pastor.identity.identity_firstname : "") + "  " + (this.pastor.identity.identity_middlename != null && this.pastor.identity.identity_middlename.trim() != "" ? this.pastor.identity.identity_middlename : "") + "  " + (this.pastor.identity.identity_lastname != null && this.pastor.identity.identity_lastname.trim() != "" ? this.pastor.identity.identity_lastname : "") }}, <small class="small"> {{ this.pastor?.identity?.identity_sex == "H" ? "Mr.":"Mme." }}</small></h4>
                                                    <p class="small mb-3">
                                                        <span :class="'dot dot-lg  mr-1 small ' + (this.pastor.status_id == 1 ? 'bg-success' : 'bg-danger')"></span> 
                                                            {{ this.pastor.status_id == 1 ? 'ACTIF' : 'INACTIF' }}
                                                    </p>
                                                    <!-- <p class="small mb-3"><span class="badge badge-dark">New York, USA</span></p> -->
                                                    </div>
                                                </div>
                                                <div class="row mb-4">
                                                    <div class="col-md-10">
                                                        <!-- <p class="text-muted">{{ this.pastor.note }}</p> -->
                                                        <p class="text-muted"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris blandit nisl ullamcorper, rutrum metus in, congue lectus. In hac habitasse platea dictumst. Cras urna quam, malesuada vitae risus at, pretium blandit sapien. </p>
                                                    </div>
                                                    <div class="col-10">
                                                        <p class="small mb-0 text-muted"><i class="fe fe-phone"></i> {{ this.pastor?.identity?.identity_phone_number_one }}</p>
                                                        <p class="small mb-0 text-muted"> <i class="fe fe-message-square"></i>  {{ this.pastor?.identity?.identity_email}}</p>
                                                        <!-- <p class="small mb-0 text-muted">P.O. Box 464, 5975 Eget Avenue</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="my-4 d-none">
                                        <div class="form-row d-none">
                                            <div class="form-group col-md-6">
                                            <label for="firstname">Firstname</label>
                                            <input type="text" id="firstname" class="form-control" placeholder="Brown">
                                            </div>
                                            <div class="form-group col-md-6">
                                            <label for="lastname">Lastname</label>
                                            <input type="text" id="lastname" class="form-control" placeholder="Asher">
                                            </div>
                                        </div>
                                        <div class="form-group d-none">
                                            <label for="inputEmail4">Email</label>
                                            <input type="email" class="form-control" id="inputEmail4" placeholder="brown@asher.me">
                                        </div>
                                        <div class="form-group d-none">
                                            <label for="inputAddress5">Address</label>
                                            <input type="text" class="form-control" id="inputAddress5" placeholder="P.O. Box 464, 5975 Eget Avenue">
                                        </div>
                                        <div class="form-row d-none">
                                            <div class="form-group col-md-6">
                                            <label for="inputCompany5">Company</label>
                                            <input type="text" class="form-control" id="inputCompany5" placeholder="Nec Urna Suscipit Ltd">
                                            </div>
                                            <div class="form-group col-md-4">
                                            <label for="inputState5">State</label>
                                            <select id="inputState5" class="form-control">
                                                <option selected="">Choose...</option>
                                                <option>...</option>
                                            </select>
                                            </div>
                                            <div class="form-group col-md-2">
                                            <label for="inputZip5">Zip</label>
                                            <input type="text" class="form-control" id="inputZip5" placeholder="98232">
                                            </div>
                                        </div>
                                        <hr class="my-4 d-none">
                                        <div class="row mb-4 d-none">
                                            <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="inputPassword4">Old Password</label>
                                                <input type="password" class="form-control" id="inputPassword5">
                                            </div>
                                            <div class="form-group">
                                                <label for="inputPassword5">New Password</label>
                                                <input type="password" class="form-control" id="inputPassword5">
                                            </div>
                                            <div class="form-group">
                                                <label for="inputPassword6">Confirm Password</label>
                                                <input type="password" class="form-control" id="inputPassword6">
                                            </div>
                                            </div>
                                            <div class="col-md-6">
                                            <p class="mb-2">Password requirements</p>
                                            <p class="small text-muted mb-2"> To create a new password, you have to meet all of the following requirements: </p>
                                            <ul class="small text-muted pl-4 mb-0">
                                                <li> Minimum 8 character </li>
                                                <li>At least one special character</li>
                                                <li>At least one number</li>
                                                <li>Can’t be the same as a previous password </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-secondary float-right m-2" data-toggle="modal" data-target=".modal-update-status">{{ this.pastor.status_id == 1 ?  "Désactivée" : "Activé" }}</button>
                                        <button type="button" class="btn btn-secondary float-right m-2" data-toggle="modal" data-target=".modal-delete">Supprimer</button>
                                        <button type="button" class="btn btn-secondary float-right m-2">Régénerer mot de passe</button>
                                    </div> 
                                </div>
                            </div>
                            
                            
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn mb-2 btn-secondary" data-dismiss="modal">Femer</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade modal-full" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <button aria-label="" type="button" class="close px-2" data-dismiss="modal" aria-hidden="true">
                              <span aria-hidden="true">×</span>
                            </button>
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body text-center">
                                <p class="d-none"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                <form class="form-inline justify-content-center d-none">
                                    <input class="form-control form-control-lg mr-sm-2 bg-transparent" type="search" placeholder="Search" aria-label="Search">
                                    <button class="btn btn-primary btn-lg mb-2 my-2 my-sm-0" type="submit">Search</button>
                                </form>

                                <div class=" row">
                                    <form @submit.prevent="RegisterCanlendrier" class=" col-12 text-left">
                                        <div class="mx-auto text-left my-4">
                                            <a class="navbar-brand mx-auto mt-2 flex-fill text-center" href="./index.html">
                                                <img src="assets/images/images/cropped-Phila-1-1-192x192.png" width="30%" alt="" srcset="">
                                            </a>
                                            <h2 class="my-3">Nouveau Horaire</h2>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="firstname">Date départ <span class="text-danger">*</span></label>
                                                <input v-model="this.form.horaire_date" type="date" id="prénom" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="lastname">Date de fin (Facultative)</label>
                                                <input v-model="this.form.horaire_date_end" type="date" id="Nom" class="form-control">
                                            </div>
                                        </div>
                                         <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="firstname">Heure de début <span class="text-danger">*</span></label>
                                                <input v-model="this.form.time_start" type="time" id="prénom" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="lastname">Heure de fin (Facultative)</label>
                                                <input v-model="this.form.time_end" type="time" id="Nom" class="form-control">
                                            </div>
                                        </div>
                                          <div class="form-grow">
                                            <div class="form-group col-md-12">
                                                <label>Temps de réception(Facultative)</label>
                                                <div class="form-row d-flex">
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                        <input v-model="this.form.fullTime" type="checkbox" class="form-check-input" name="optionsRadios"  value="1">
                                                        Toute la journée </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="my-4">
                                        <button class="btn btn-lg btn-primary bg-text-phila small float-right px-5 mx-2 " type="submit">Enregistrer</button>
                                        <button class="btn btn-lg btn-secondary small float-right px-5" type="button" data-dismiss="modal" aria-hidden="true" @click.prevent="fermer">Fermer</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- small modal -->
            </main>
    </div>
</template>

<script>

import Composite from '../layouts/Composite.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
    name: 'PastorPage',
    components: {
        Composite
    },

    data() {
        return {
        user: null,
        calendarFrees: [],
        calendarFreesHorsRdvs: [],
        pastor_search: [],
        pastors: [],
        pastor: null,
        requesting: false,
        OneTicket:null,
        form: {
            horaire_date:null,
            horaire_date_end:null,
            time_start:null,
            time_end:null,
            fullTime:null,
            full_time:null,
            pastor_id:null,
            search: "",
            identity_sex: "M",
            identity_firstname: null,
            identity_lastname: null,
            identity_middlename: null,
            identity_email: null,
            identity_phone_number_two: null,
            identity_phone_number_one: null,
            pastor_note: "Je suis un de pasteur de Phila C.E",
        },
        step: 1,
        checkForm: false,
        lottieloading: false
        };
    },
  
    setup() {
        const toast = useToast();
        return { toast }
    },

    mounted() {
        this.gb_loadReFreshUser();

         if (this.$store.state.user_auth != null && this.$store.state.user_auth.status.id == 1) {
             this.all_calendrier_free_pastor();
             setInterval(() => {
                this.all_calendrier_free_pastor();
             }, 120000);
        }
    },
    methods: {

        async all_calendrier_free_pastor() {

            if (this.$store.state.user_auth !=null  && this.$store.state.user_auth.identity != null   && this.$store.state.user_auth.identity.pastor != null) {
                this.lottieloading = true;
                await axios.get(this.$store.state.app_url_dev + "horaire_times_free").then(({ data }) => {
                    if (data.status == 1) {
                        var frees = data.data;
                        if (frees.length > 0) {
                            this.calendarFrees = frees.filter((free)=>{
                                return free.horaire_room_pastoral.pastor_id == this.$store.state.user_auth.identity.pastor.id
                            }).sort((a, b) => {
                                    // return b?.horaire_room_pastoral?.horaire?.horaire_date - a?.horaire_room_pastoral?.horaire?.horaire_date
                                    return a?.horaire_room_pastoral?.horaire?.horaire_date.toString() >  b?.horaire_room_pastoral?.horaire?.horaire_date.toString()
                            });

                            // vérifie que c'est pas un mardi ou un mercreedi
                                // calendarFreesHorsRdvs
                            var HorsRdvs = this.calendarFrees.filter((free) => {
                                    return   new Date(free?.horaire_room_pastoral?.horaire?.horaire_date).getDay()  != 2 &&  new Date(free?.horaire_room_pastoral?.horaire?.horaire_date).getDay()  != 3
                            })

                            if (HorsRdvs.length > 0) {
                                var calendarFreesHorsRdvs = [];
                                HorsRdvs.forEach(free => {
                                        calendarFreesHorsRdvs.push(free?.horaire_room_pastoral?.horaire);
                                    });
                                    var jsonObject = calendarFreesHorsRdvs.map(JSON.stringify);
                                    var uniqueSet = new Set(jsonObject);
                                    var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                                var FreesHorsRdvs = uniqueArray.sort((a, b) => {
                                            // return a?.horaire_date - b?.horaire_date
                                            return a?.horaire_date.toString() > b?.horaire_date.toString()
                                });

                                this.calendarFreesHorsRdvs = FreesHorsRdvs;

                                this.HorsRdvsArray = this.calendarFreesHorsRdvs.filter((free) => {
                                        return   this.OneTicket?.horaire_room_pastoral_time?.horaire_room_pastoral?.horaire?.horaire_date.toString() !=  free?.horaire_date.toString()
                                });
                                this.lottieloading = false;
                            }

                                // lundi = 1, ......... samedi = 6 et  dimanche = 0
                                // var jrDate = new Date(dateAdd).getDay();
                                // console.log(jrDate);
                                // if (jrDate == 2 || jrDate == 3) {
                        }
                    } else {
                        this.toast.error(data.message, {
                            timeout: 6000
                        });
                        this.lottieloading = false;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        },

        async RegisterCanlendrier() {
            if (this.Online()) {
            this.requesting = true;
            this.form.pastor_id = this.$store.state.user_auth.identity.pastor.id;

            if (this.form.horaire_date != null && this.form.horaire_date.trim() != "" && this.form.pastor_id != null) {

                if(this.form.fullTime){
                    this.form.full_time  = 1;
                }

                await axios.post(this.$store.state.app_url_dev + "st_planning_pastor", this.form).then(({ data }) => {
                this.requesting = false;
                if (data.status == 1) {
                    this.user = data;
                    this.toast.success("L'horaire a éé crée avec success  !!!");
                    
                    this.refreshForm();
                    this.all_calendrier_free_pastor();
                    this.fermer();
                } else {
                    this.toast.error("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 6000
                    });
                }
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                this.toast.error("Veuillez completer les champs manquants !!!", {
                timeout: 6000
                });
            }
            }
        },

        fermer(){
            console.log("fermer");
        },

        getNumberFormatSms(value) {
            if (value.length > 0) {
                if (value.length == 13 && value.substring(0, 1) == '+') {
                    return value.substring(1, value.length);
                } else if (value.length == 12) {
                    return value;
                } else if (value.length == 10) {
                    return '243' + value.substring(1, value.length);
                }
            }
        },

        onePastor(pastor_id) {
            if (this.$store.state.user_auth != null && pastor_id != null && pastor_id.toString().trim() != "" && this.pastors.length > 0) {
                var pastor = this.pastors.find((past) => past.id == pastor_id);
                if (pastor != null) {
                    this.pastor = pastor;
                    console.log(this.pastor);
                } else {
                    this.pastor = null;
                }
            }
        },

        async delete_pastor() {
            if (this.pastor !=null && this.pastor.id !=null) {
                await axios.post(this.$store.state.app_url_dev + "dl-pastor", {
                    "id":this.pastor.id
                }).then(({ data }) => {
                    if (data.status == 1) {
                         this.toast.success("Suppression éffectué avec success !!!", {
                            timeout: 2000
                         });

                         setTimeout(() => {
                             this.refreshForm();
                         }, 2001);
                         
                    } else {
                        this.toast.error(data.message, {
                            timeout: 6000
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                        timeout: 2000
                    });
                });
            }
        },

        async toggle_status_pastor() {
            if (this.pastor !=null && this.pastor.id !=null) {
                await axios.put(this.$store.state.app_url_dev + "toggle-status-pastor", {
                    "id": this.pastor.id,
                    "status_id":this.pastor.status_id == 1 ? 2 :1
                }).then(({ data }) => {
                    if (data.status == 1) {
                         this.toast.success("Statut changé  avec success !!!", {
                            timeout: 2000
                         });

                         setTimeout(() => {
                             this.refreshForm();
                         }, 2001);
                         
                    } else {
                        this.toast.error(data.message, {
                            timeout: 6000
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                        timeout: 2000
                    });
                });
            }
        },

        refreshForm() {
            this.form.horaire_date=null,
            this.form.horaire_date_end=null,
            this.form.time_start=null,
            this.form.time_end=null,
            this.form.fullTime=null,
            this.form.full_time=null,
            this.form.search = "",
            this.form.identity_sex = "M",
            this.form.identity_firstname = null,
            this.form.identity_lastname = null,
            this.form.identity_middlename = null,
            this.form.identity_email = null,
            this.form.identity_phone_number_two = null,
            this.form.identity_phone_number_one = null,
            this.messageErrors = null
        },

        access_step_two() {
            if (this.form.prenom != null && this.form.prenom.trim() != "" && this.form.nom != null && this.form.nom.trim() != "") {
            this.lottieloading = true;
            setTimeout(() => {
                this.step = 2;
                this.lottieloading = false
            }, 3000);
            } else {
            this.checkForm = true;
            this.toast.error("Veuillez compléter les champs manquants", {
                timeout: 5000
            });
            }
        },

        to_access_step_three() {
            if (this.form.prenom != null && this.form.prenom.trim() != "" && this.form.nom != null && this.form.nom.trim() != "") {
            this.lottieloading = true;
            setTimeout(() => {
                this.step = 3;
            }, 4000);
            } else {
            this.checkForm = true;
            this.toast.error("Veuillez compléter les champs manquants", {
                timeout: 5000
            });
            }
        },

        changesexe(typesexe) {
            if (this.isSexe) {
            this.isSexe = false;
            } else {
            this.isSexe = true;
            }
            this.form.sexe = typesexe;
        },

        change_type_input() {
            if (this.type_input_password == false) {
            this.type_input_password = true;
            } else {
            this.type_input_password = false;
            }
        },

        IsEmail(email) {
            var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
            if (!regex.test(email)) {
            this.toast.error("Adresse email invalide", {
                timeout: 5000
            });
            return false;
            } else {
            return true;
            }
        },

        // verifi numero e telephone
        getValidNumber(value) {
            if (value.length == 10 || value.length == 12 || value.length == 13) {

            if (value.length == 10) {
                if (value.substring(0, 1) == '0') {
                if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
                    value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
                    value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }

            } else if (value.length == 12) {

                if (value.substring(0, 3) == '243') {

                if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
                    value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
                    value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
            } else if (value.length == 13) {

                if (value.substring(0, 1) == '+') {

                if (value.substring(1, 4) == '243') {
                    if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                    value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                    value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                    return true;
                    } else {
                    this.toast.error("Numéro de téléphone invalide", {
                        timeout: 5000
                    });
                    return false;
                    }

                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
            }

            } else {
            this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
            });
            return false;
            }
            return false;
        },

        getNumberFormat(value) {
            if (value.length > 0) {
            if (value.length == 13 && value.substring(0, 1) == '+') {
                return value;
            } else if (value.length == 12) {
                return '+' + value;
            } else if (value.length == 10) {
                return '+243' + value.substring(1, value.length);
            }
            }
        },
    },

    computed: {
    getfilter_pastors() {
                return this.pastors.filter((pastor) => {
                    return (
                        pastor.pastor_coding
                            .toLowerCase()
                            .includes(this.form.search.toLowerCase()) ||  pastor.identity.identity_firstname.toLowerCase()
                            .includes(this.form.search.toLowerCase()) || pastor.identity.identity_lastname.toLowerCase()
                                .includes(this.form.search.toLowerCase())  || pastor.identity.identity_middlename.toLowerCase()
                                    .includes(this.form.search.toLowerCase())  || pastor.identity.identity_email.toLowerCase()
                                        .includes(this.form.search.toLowerCase())  || pastor.identity.identity_phone_number_one.toLowerCase()
                                            .includes(this.form.search.toLowerCase())
                    );
                });
            },
    },
}
</script>
