<template>
    <div class="wrapper">
         <composite v-if="this.$store.state.user_auth != null"> </composite>
        <main role="main" class="main-content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="row align-items-center mb-2">
                            <div class="col">
                                <h2 class="h5 page-title">Bienvenue!</h2>
                            </div>
                            <div class="col-auto">
                                <form class="form-inline">
                                    <div class="form-group d-none d-lg-inline">
                                        <label for="reportrange" class="sr-only">Date Ranges</label>
                                        <div id="reportrange" class="px-2 py-2 text-muted">
                                            <span class="small"></span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="button" class="btn btn-sm"><span class="fe fe-refresh-ccw fe-16 text-muted"></span></button>
                                        <button type="button" class="btn btn-sm mr-2"><span class="fe fe-filter fe-16 text-muted"></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row my-4">
                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">UTILISATEURS</small>
                                                <h3 class="card-title mb-0">278</h3>
                                                <!-- <p class="small text-muted mb-0"><span class="fe fe-arrow-up fe-12 text-success"></span><span>37.7% Last week</span></p> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <!-- <span class="sparkline inlinebar"></span> -->
                                                <i class="fe fe-32 fe-users"></i>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>

                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">DEMANDES</small>
                                                <h3 class="card-title mb-0">278</h3>
                                                <!-- <p class="small text-muted mb-0"><span class="fe fe-arrow-up fe-12 text-success"></span><span>37.7% Last week</span></p> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <!-- <span class="sparkline inlinebar"></span> -->
                                                <i class="fe fe-32 fe-message-square"></i>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>

                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">RESERVATIONS RDV</small>
                                                <h3 class="card-title mb-0">278</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <!-- <span class="sparkline inlinebar"></span> -->
                                                <i class="fe fe-32 fe-mail"></i>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>

                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">RENDEZ-VOUS</small>
                                                <h3 class="card-title mb-0">1168</h3>
                                                <!-- <p class="small text-muted mb-0"><span class="fe fe-arrow-down fe-12 text-danger"></span><span>-18.9% Last week</span></p> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <i class="fe fe-32 fe-users"></i>
                                                <i class="fe  fe-32 fe-check-circle"></i>
                                                <!-- <span class="sparkline inlineline"></span> -->
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>

                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">SALLES</small>
                                                <h3 class="card-title mb-0">1168</h3>
                                                <!-- <p class="small text-muted mb-0"><span class="fe fe-arrow-down fe-12 text-danger"></span><span>-18.9% Last week</span></p> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <!-- <span class="sparkline inlineline"></span> -->
                                                <i class="fe fe-home fe-32"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /. col -->
                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">PASTEURS</small>
                                                <h3 class="card-title mb-0">68</h3>
                                                <!-- <p class="small text-muted mb-0"><span class="fe fe-arrow-up fe-12 text-warning"></span><span>+1.9% Last week</span></p> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <span class="fe fe-user fe-32"></span>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>
                            <!-- /. col -->

                            <!-- /. col -->


                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">ENTRETIENS</small>
                                                <h3 class="card-title mb-0">1168</h3>
                                                <p class="small text-muted mb-0"><span class="fe fe-arrow-down fe-12 text-danger"></span><span>-18.9% Last week</span></p>
                                            </div>
                                            <div class="col-4 text-right">
                                                <!-- <span class="sparkline inlineline"></span> -->
                                                <i class="fe fe-32 fe-users"></i>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>
                            <!-- /. col -->
                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">Conversion</small>
                                                <h3 class="card-title mb-0">68</h3>
                                                <p class="small text-muted mb-0"><span class="fe fe-arrow-up fe-12 text-warning"></span><span>+1.9% Last week</span></p>
                                            </div>
                                            <div class="col-4 text-right">
                                                <span class="sparkline inlinepie"></span>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>
                            <!-- /. col -->
                            <div class="col-md-4">
                                <div class="card shadow mb-4">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <small class="text-muted mb-1">Visitors</small>
                                                <h3 class="card-title mb-0">108</h3>
                                                <p class="small text-muted mb-0"><span class="fe fe-arrow-up fe-12 text-success"></span><span>37.7% Last week</span></p>
                                            </div>
                                            <div class="col-4 text-right">
                                                <span class="sparkline inlinebar"></span>
                                            </div>
                                        </div>
                                        <!-- /. row -->
                                    </div>
                                    <!-- /. card-body -->
                                </div>
                                <!-- /. card -->
                            </div>
                            <!-- /. col -->
                        </div>
                        <!-- end section -->
                        <!-- linechart -->
                        <div class="my-4">
                            <div id="lineChart"></div>
                        </div>

                        <div class="row items-align-baseline">
                            <div class="col-md-12 col-lg-4">
                                <div class="card shadow eq-card mb-4">
                                    <div class="card-body mb-n3">
                                        <div class="row items-align-baseline h-100">
                                            <div class="col-md-6 my-3">
                                                <p class="mb-0"><strong class="mb-0 text-uppercase text-muted">Earning</strong></p>
                                                <h3>$2,562</h3>
                                                <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                            <div class="col-md-6 my-4 text-center">
                                                <div lass="chart-box mx-4">
                                                    <div id="radialbarWidget"></div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 border-top py-3">
                                                <p class="mb-1"><strong class="text-muted">Cost</strong></p>
                                                <h4 class="mb-0">108</h4>
                                                <p class="small text-muted mb-0"><span>37.7% Last week</span></p>
                                            </div>
                                            <!-- .col -->
                                            <div class="col-md-6 border-top py-3">
                                                <p class="mb-1"><strong class="text-muted">Revenue</strong></p>
                                                <h4 class="mb-0">1168</h4>
                                                <p class="small text-muted mb-0"><span>-18.9% Last week</span></p>
                                            </div>
                                            <!-- .col -->
                                        </div>
                                    </div>
                                    <!-- .card-body -->
                                </div>
                                <!-- .card -->
                            </div>
                            <!-- .col -->
                            <div class="col-md-12 col-lg-4">
                                <div class="card shadow eq-card mb-4">
                                    <div class="card-body">
                                        <div class="chart-widget mb-2">
                                            <div id="radialbar"></div>
                                        </div>
                                        <div class="row items-align-center">
                                            <div class="col-4 text-center">
                                                <p class="text-muted mb-1">Cost</p>
                                                <h6 class="mb-1">$1,823</h6>
                                                <p class="text-muted mb-0">+12%</p>
                                            </div>
                                            <div class="col-4 text-center">
                                                <p class="text-muted mb-1">Revenue</p>
                                                <h6 class="mb-1">$6,830</h6>
                                                <p class="text-muted mb-0">+8%</p>
                                            </div>
                                            <div class="col-4 text-center">
                                                <p class="text-muted mb-1">Earning</p>
                                                <h6 class="mb-1">$4,830</h6>
                                                <p class="text-muted mb-0">+8%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- .card-body -->
                                </div>
                                <!-- .card -->
                            </div>
                            <!-- .col -->
                            <div class="col-md-12 col-lg-4">
                                <div class="card shadow eq-card mb-4">
                                    <div class="card-body">
                                        <div class="d-flex mt-3 mb-4">
                                            <div class="flex-fill pt-2">
                                                <p class="mb-0 text-muted">Total</p>
                                                <h4 class="mb-0">108</h4>
                                                <span class="small text-muted">+37.7%</span>
                                            </div>
                                            <div class="flex-fill chart-box mt-n2">
                                                <div id="barChartWidget"></div>
                                            </div>
                                        </div>
                                        <!-- .d-flex -->
                                        <div class="row border-top">
                                            <div class="col-md-6 pt-4">
                                                <h6 class="mb-0">108 <span class="small text-muted">+37.7%</span></h6>
                                                <p class="mb-0 text-muted">Cost</p>
                                            </div>
                                            <div class="col-md-6 pt-4">
                                                <h6 class="mb-0">1168 <span class="small text-muted">-18.9%</span></h6>
                                                <p class="mb-0 text-muted">Revenue</p>
                                            </div>
                                        </div>
                                        <!-- .row -->
                                    </div>
                                    <!-- .card-body -->
                                </div>
                                <!-- .card -->
                            </div>
                            <!-- .col-md -->
                        </div>
                        <!-- .row -->
                        <div class="row">
                            <!-- Recent Activity -->
                            <div class="col-md-12 col-lg-4 mb-4">
                                <div class="card timeline shadow">
                                    <div class="card-header">
                                        <strong class="card-title">Recent Activity</strong>
                                        <a class="float-right small text-muted" href="#!">View all</a>
                                    </div>
                                    <div class="card-body" data-simplebar style="height:355px; overflow-y: auto; overflow-x: hidden;">
                                        <h6 class="text-uppercase text-muted mb-4">Today</h6>
                                        <div class="pb-3 timeline-item item-primary">
                                            <div class="pl-5">
                                                <div class="mb-1"><strong>@Brown Asher</strong><span class="text-muted small mx-2">Just create new layout Index, form, table</span><strong>Tiny Admin</strong></div>
                                                <p class="small text-muted">Creative Design <span class="badge badge-light">1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="pb-3 timeline-item item-warning">
                                            <div class="pl-5">
                                                <div class="mb-3"><strong>@Hester Nissim</strong><span class="text-muted small mx-2">has upload new files to</span><strong>Tiny Admin</strong></div>
                                                <div class="row mb-3">
                                                    <div class="col"><img src="assets/products/p1.jpg" alt="..." class="img-fluid rounded"></div>
                                                    <div class="col"><img src="assets/products/p2.jpg" alt="..." class="img-fluid rounded"></div>
                                                    <div class="col"><img src="assets/products/p3.jpg" alt="..." class="img-fluid rounded"></div>
                                                    <div class="col"><img src="assets/products/p4.jpg" alt="..." class="img-fluid rounded"></div>
                                                </div>
                                                <p class="small text-muted">Front-End Development <span class="badge badge-light">1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="pb-3 timeline-item item-success">
                                            <div class="pl-5">
                                                <div class="mb-3"><strong>@Kelley Sonya</strong><span class="text-muted small mx-2">has commented on</span><strong>Advanced table</strong></div>
                                                <div class="card d-inline-flex mb-2">
                                                    <div class="card-body bg-light py-2 px-3 small rounded"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer dignissim nulla eu quam cursus placerat. Vivamus non odio ullamcorper, lacinia ante nec, blandit leo. </div>
                                                </div>
                                                <p class="small text-muted">Back-End Development <span class="badge badge-light">1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <h6 class="text-uppercase text-muted mb-4">Yesterday</h6>
                                        <div class="pb-3 timeline-item item-warning">
                                            <div class="pl-5">
                                                <div class="mb-3"><strong>@Fletcher Everett</strong><span class="text-muted small mx-2">created new group for</span><strong>Tiny Admin</strong></div>
                                                <ul class="avatars-list mb-3">
                                                    <li>
                                                        <a href="#!" class="avatar avatar-sm">
                                                            <img alt="..." class="avatar-img rounded-circle" src="assets/avatars/face-1.jpg">
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#!" class="avatar avatar-sm">
                                                            <img alt="..." class="avatar-img rounded-circle" src="assets/avatars/face-4.jpg">
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#!" class="avatar avatar-sm">
                                                            <img alt="..." class="avatar-img rounded-circle" src="assets/avatars/face-3.jpg">
                                                        </a>
                                                    </li>
                                                </ul>
                                                <p class="small text-muted">Front-End Development <span class="badge badge-light">1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="pb-3 timeline-item item-success">
                                            <div class="pl-5">
                                                <div class="mb-3"><strong>@Bertha Ball</strong><span class="text-muted small mx-2">has commented on</span><strong>Advanced table</strong></div>
                                                <div class="card d-inline-flex mb-2">
                                                    <div class="card-body bg-light py-2 px-3"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer dignissim nulla eu quam cursus placerat. Vivamus non odio ullamcorper, lacinia ante nec, blandit leo. </div>
                                                </div>
                                                <p class="small text-muted">Back-End Development <span class="badge badge-light">1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="pb-3 timeline-item item-danger">
                                            <div class="pl-5">
                                                <div class="mb-3"><strong>@Lillith Joseph</strong><span class="text-muted small mx-2">has upload new files to</span><strong>Tiny Admin</strong></div>
                                                <div class="row mb-3">
                                                    <div class="col"><img src="assets/products/p4.jpg" alt="..." class="img-fluid rounded"></div>
                                                    <div class="col"><img src="assets/products/p1.jpg" alt="..." class="img-fluid rounded"></div>
                                                    <div class="col"><img src="assets/products/p2.jpg" alt="..." class="img-fluid rounded"></div>
                                                </div>
                                                <p class="small text-muted">Front-End Development <span class="badge badge-light">1h ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- / .card-body -->
                                </div>
                                <!-- / .card -->
                            </div>
                            <!-- / .col-md-6 -->
                            <!-- Striped rows -->
                            <div class="col-md-12 col-lg-8">
                                <div class="card shadow">
                                    <div class="card-header">
                                        <strong class="card-title">Recent Data</strong>
                                        <a class="float-right small text-muted" href="#!">View all</a>
                                    </div>
                                    <div class="card-body my-n2">
                                        <table class="table table-striped  table-hover table-borderless mb-4">
                                            <thead class="thead-white d-none">
                                                <tr>
                                                    <th colspan="2">Data</th>
                                                    <th>Center</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-muted text-center w-25">
                                                        <span class="fe fe-database fe-24"></span><br /> 3218 </td>
                                                    <th scope="row" class="w-50"> Nunc Lectus Incorporated<br />
                                                        <small class="text-muted">Barcelona, Spain</small>
                                                    </th>
                                                    <td class="text-muted w-25">
                                                        <div class="progress mt-3 mb-1" style="height:4px">
                                                            <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        <small>May 23, 2020</small>
                                                    </td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button class="btn btn-sm dropdown-toggle more-vertical" type="button" id="dr2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dr2">
                                                                <a class="dropdown-item" href="#">Edit</a>
                                                                <a class="dropdown-item" href="#">Remove</a>
                                                                <a class="dropdown-item" href="#">Assign</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-muted text-center w-25">
                                                        <span class="fe fe-cpu fe-24"></span><br /> 2651 </td>
                                                    <th scope="row" class="w-50"> Nisi Aenean Eget Limited<br />
                                                        <small class="text-muted">Sydney, Australia</small>
                                                    </th>
                                                    <td class="text-muted w-25">
                                                        <div class="progress mt-3 mb-1" style="height:4px">
                                                            <div class="progress-bar bg-warning" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        <small>Nov 4, 2019</small>
                                                    </td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button class="btn btn-sm dropdown-toggle more-vertical" type="button" id="dr2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dr2">
                                                                <a class="dropdown-item" href="#">Edit</a>
                                                                <a class="dropdown-item" href="#">Remove</a>
                                                                <a class="dropdown-item" href="#">Assign</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-muted text-center w-25">
                                                        <span class="fe fe-globe fe-24"></span><br /> 2651 </td>
                                                    <th scope="row" class="w-50"> Pellentesque Associates<br />
                                                        <small class="text-muted">Amsterdam, Netherlands</small>
                                                    </th>
                                                    <td class="text-muted w-25">
                                                        <div class="progress mt-3 mb-1" style="height:4px">
                                                            <div class="progress-bar bg-success" role="progressbar" style="width: 8%" aria-valuenow="8" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        <small>Mar 27, 2020</small>
                                                    </td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button class="btn btn-sm dropdown-toggle more-vertical" type="button" id="dr2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dr2">
                                                                <a class="dropdown-item" href="#">Edit</a>
                                                                <a class="dropdown-item" href="#">Remove</a>
                                                                <a class="dropdown-item" href="#">Assign</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-muted text-center w-25">
                                                        <span class="fe fe-database fe-24"></span><br /> 2757 </td>
                                                    <th scope="row" class="w-50"> Augue Incorporated<br />
                                                        <small class="text-muted">Paris, France</small>
                                                    </th>
                                                    <td class="text-muted w-25">
                                                        <div class="progress mt-3 mb-1" style="height:4px">
                                                            <div class="progress-bar bg-warning" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        <small>Jan 13, 2020</small>
                                                    </td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button class="btn btn-sm dropdown-toggle more-vertical" type="button" id="dr2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dr2">
                                                                <a class="dropdown-item" href="#">Edit</a>
                                                                <a class="dropdown-item" href="#">Remove</a>
                                                                <a class="dropdown-item" href="#">Assign</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-muted text-center w-25">
                                                        <span class="fe fe-cpu fe-24"></span><br /> 2816 </td>
                                                    <th scope="row" class="w-50"> Enim Limited<br />
                                                        <small class="text-muted">London, United Kingdom</small>
                                                    </th>
                                                    <td class="text-muted w-25">
                                                        <div class="progress mt-3 mb-1" style="height:4px">
                                                            <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        <small>Jan 01, 2020</small>
                                                    </td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <button class="btn btn-sm dropdown-toggle more-vertical" type="button" id="dr2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dr2">
                                                                <a class="dropdown-item" href="#">Edit</a>
                                                                <a class="dropdown-item" href="#">Remove</a>
                                                                <a class="dropdown-item" href="#">Assign</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- Log -->
                                <div class="card shadow">
                                    <div class="card-header">
                                        <strong class="card-title">Logging</strong>
                                        <a class="float-right small text-muted" href="#!">View all</a>
                                    </div>
                                    <div class="card-body">
                                        <div class="list-group list-group-flush my-n3">
                                            <div class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="col-auto">
                                                        <span class="circle circle-sm bg-warning"><i class="fe fe-shield-off fe-16 text-white"></i></span>
                                                    </div>
                                                    <div class="col">
                                                        <small><strong>11:00 April 16, 2020</strong></small>
                                                        <div class="mb-2 text-muted small">Lorem ipsum dolor sit amet, <strong>consectetur adipiscing</strong> elit. Integer dignissim nulla eu quam cursus placerat. Vivamus non odio ullamcorper, lacinia ante nec, blandit leo. </div>
                                                        <span class="badge badge-pill badge-warning">Security</span>
                                                    </div>
                                                    <div class="col-auto pr-0">
                                                        <small class="fe fe-more-vertical fe-16 text-muted"></small>
                                                    </div>
                                                </div>
                                                <!-- / .row -->
                                            </div>
                                            <!-- / .list-group-item -->
                                            <div class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="col-auto">
                                                        <span class="circle circle-sm bg-success"><i class="fe fe-database fe-16 text-white"></i></span>
                                                    </div>
                                                    <div class="col">
                                                        <small><strong>17:00 April 15, 2020</strong></small>
                                                        <div class="mb-2 text-muted small">Proin porta vel erat suscipit luctus. Cras rhoncus felis sed magna commodo, in <a href="#!">pretium</a> mauris faucibus. Cras rhoncus felis sed magna commodo, in pretium mauris faucibus.</div>
                                                        <span class="badge badge-pill badge-success">System Update</span>
                                                    </div>
                                                    <div class="col-auto pr-0">
                                                        <small class="fe fe-more-vertical fe-16 text-muted"></small>
                                                    </div>
                                                </div>
                                                <!-- / .row -->
                                            </div>
                                            <!-- / .list-group-item -->
                                            <div class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="col-auto">
                                                        <span class="circle circle-sm bg-secondary"><i class="fe fe-user-plus fe-16 text-white"></i></span>
                                                    </div>
                                                    <div class="col">
                                                        <small><strong>17:00 April 10, 2020</strong></small>
                                                        <div class="mb-2 text-muted small"> Morbi id arcu convallis, eleifend justo tristique, tincidunt nisl. Morbi euismod fermentum quam, at fringilla elit posuere a. <strong>Aliquam</strong> accumsan mi venenatis risus fermentum, at sagittis
                                                            velit fermentum.</div>
                                                        <span class="badge badge-pill badge-secondary">Users</span>
                                                    </div>
                                                    <div class="col-auto pr-0">
                                                        <small class="fe fe-more-vertical fe-16 text-muted"></small>
                                                    </div>
                                                </div>
                                                <!-- / .row -->
                                            </div>
                                            <!-- / .list-group-item -->
                                        </div>
                                        <!-- / .list-group -->
                                    </div>
                                    <!-- / .card-body -->
                                </div>
                                <!-- / .card -->
                            </div>
                            <!-- Striped rows -->

                        </div>
                        <!-- .row-->
                    </div>
                    <!-- .col-12 -->
                </div>
                <!-- .row -->
            </div>
            <!-- .container-fluid -->
            <div class="modal fade modal-notif modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="defaultModalLabel">Notifications</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body">
                            <div class="list-group list-group-flush my-n3">
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-box fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Package has uploaded successfull</strong></small>
                                            <div class="my-0 text-muted small">Package is zipped and uploaded</div>
                                            <small class="badge badge-pill badge-light text-muted">1m ago</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-download fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Widgets are updated successfull</strong></small>
                                            <div class="my-0 text-muted small">Just create new layout Index, form, table</div>
                                            <small class="badge badge-pill badge-light text-muted">2m ago</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-inbox fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Notifications have been sent</strong></small>
                                            <div class="my-0 text-muted small">Fusce dapibus, tellus ac cursus commodo</div>
                                            <small class="badge badge-pill badge-light text-muted">30m ago</small>
                                        </div>
                                    </div>
                                    <!-- / .row -->
                                </div>
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-link fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Link was attached to menu</strong></small>
                                            <div class="my-0 text-muted small">New layout has been attached to the menu</div>
                                            <small class="badge badge-pill badge-light text-muted">1h ago</small>
                                        </div>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                            <!-- / .list-group -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal">Clear All</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade modal-shortcut modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="defaultModalLabel">Shortcuts</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body px-5">
                            <div class="row align-items-center">
                                <div class="col-6 text-center">
                                    <div class="squircle bg-success justify-content-center">
                                        <i class="fe fe-cpu fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Control area</p>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-activity fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Activity</p>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-droplet fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Droplet</p>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-upload-cloud fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Upload</p>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-users fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Users</p>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-settings fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Settings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Composite from './layouts/Composite.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
    name: 'LoadPage',
  components: {
        Composite
    },

  data() {
    return {
      user: null,
      requesting: false,
      form: {
        nom: null,
        prenom: null,
        postnom: null,
        telephone: null,
        telephone_format: null,
        email: null,
        sexe: "M",
        password: null,
      },
      step: 1,
      checkForm: false,
      lottieloading: false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  methods: {
    async RegisterUser() {
      if (this.Online()) {
        this.requesting = true;
        if (this.form.nom != null && this.form.prenom != null && this.form.sexe != null && this.form.email != null && this.form.telephone_format != null && this.form.password != null) {

          if (this.getValidNumber(this.form.telephone_format) && this.IsEmail(this.form.email)) {
            if (this.form.nom.trim().length >= 4 && this.form.nom.trim().length <= 16 && this.form.prenom.trim().length >= 4 && this.form.prenom.trim().length <= 16) {
              this.form.telephone = this.getNumberFormat(this.form.telephone_format);
              this.form.nom = this.gb_capitalisingFormat(this.form.nom.trim());
              this.form.prenom = this.gb_capitalisingFormat(this.form.prenom.trim());

              // var postRequest = {
              //   ""
              // }

              await axios.post("https://www.inscription.schoolbac.com", this.form).then(({ data }) => {
                this.requesting = false;
                if (data.error == "false") {
                  this.user = data;
                  this.toast.success("Votre compte vient d'etre crée avec success !!!");
                  var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                  setTimeout(() => {
                    let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.telephone;

                    axios.get(urlgetsms).then(() => { });
                    this.$store.state.user_auth = data;
                    this.$store.state.user_store = data;
                    this.$store.state.user_store_password = this.form.password;
                    this.refreshForm();
                    this.$router.push({ name: 'Confirm' });
                  }, 4500);

                } else {
                  this.toast.error(data.message, {
                    timeout: 6000
                  });
                }
              }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                  timeout: 2000
                });
              });

            } else {
              if (this.form.nom.trim().length < 4 || this.form.prenom.trim().length < 4) {
                this.toast.info("Le nom ou le prenom ne doit pas etre inférieur à 4 caractères !!!", {
                  timeout: 6000
                });
              } else {
                // this.messageErrors = "";
                this.toast.info("Le nom ou le prenom ne doit pas dépasser 16 caractères !!!", {
                  timeout: 6000
                });
              }
            }
          }

        } else {
          this.toast.error("Veuillez completer les champs manquants !!!", {
            timeout: 6000
          });
        }
      }
    },

    refreshForm() {
      this.form.nom = null,
        this.form.prenom = null,
        this.form.sexe = "M",
        this.form.telephone = null,
        this.form.password = null,
        this.form.email = null,
        this.messageErrors = null
    },

    access_step_two() {
      if (this.form.prenom != null && this.form.prenom.trim() != "" && this.form.nom != null && this.form.nom.trim() != "") {
        this.lottieloading = true;
        setTimeout(() => {
          this.step = 2;
          this.lottieloading = false
        }, 3000);
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    to_access_step_three() {
      if (this.form.prenom != null && this.form.prenom.trim() != "" && this.form.nom != null && this.form.nom.trim() != "") {
        this.lottieloading = true;
        setTimeout(() => {
          this.step = 3;
        }, 4000);
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.sexe = typesexe;
    },

    change_type_input() {
      if (this.type_input_password == false) {
        this.type_input_password = true;
      } else {
        this.type_input_password = false;
      }
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
