<template>
    <div class="wrapper">
    <composite v-if="this.$store.state.user_auth != null"> </composite>
        <main role="main" class="main-content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="h3 mb-0 page-title">Tickets</h2>
                        <lottie-player class="text-center"
                                            :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                            background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                        <div class="row align-items-center my-4 d-none">
                            <div class="col-md-6">
                                <div id="chart-box">
                                    <div id="donutChartWidget"></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row align-items-center my-2">
                                    <div class="col">
                                        <small class="text-uppercase small">En attente</small>
                                        <div class=" my-0 text-muted small ">+2%</div>
                                    </div>
                                    <div class="col-auto ">
                                        <strong>262</strong>
                                    </div>
                                    <div class="col-3 ">
                                        <div class="progress " style="height: 4px; ">
                                            <div class="progress-bar bg-warning " role="progressbar " style="width: 20% " aria-valuenow="20 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center my-2 ">
                                    <div class="col ">
                                        <small class="text-uppercase">Validé</small><br/>
                                        <span class="my-0 text-muted small ">+85%</span>
                                    </div>
                                    <div class="col-auto ">
                                        <strong class="my-0 ">1200</strong>
                                    </div>
                                    <div class="col-3 ">
                                        <div class="progress " style="height: 4px; ">
                                            <div class="progress-bar bg-success " role="progressbar " style="width: 85% " aria-valuenow="85 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center my-2 d-none ">
                                    <div class="col ">
                                        <strong>Processing</strong><br/>
                                        <span class="my-0 text-muted small ">+60%</span>
                                    </div>
                                    <div class="col-auto ">
                                        <strong>80</strong>
                                    </div>
                                    <div class="col-3 ">
                                        <div class="progress " style="height: 4px; ">
                                            <div class="progress-bar bg-primary " role="progressbar " style="width: 75% " aria-valuenow="75 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center my-2 ">
                                    <div class="col ">
                                        <small class="text-uppercase">Echec</small>
                                        <div class="my-0 text-muted small ">+6%</div>
                                    </div>
                                    <div class="col-auto ">
                                        <strong>26</strong>
                                    </div>
                                    <div class="col-3 ">
                                        <div class="progress " style="height: 4px; ">
                                            <div class="progress-bar bg-danger " role="progressbar " style="width: 30% " aria-valuenow="0 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- .col-md-12 -->
                        </div>
                        <!-- .row -->
                        <div class="row items-align-center my-4 d-none d-lg-flex ">
                            <div class="col-md ">
                                <ul class="nav nav-pills justify-content-start ">
                                    <li class="nav-item ">
                                        <a class="nav-link active bg-transparent pr-2 pl-0 text-primary " href="# ">TOUT <span class="badge badge-pill bg-primary text-white ml-2 ">{{ this.allTickets.length }}</span></a>
                                    </li>
                                    <li class="nav-item ">
                                        <a class="nav-link text-white px-2  text-uppercase small" href="# ">Attente <span class="badge badge-pill bg-warning border text-white ml-2 ">{{ this.tickets.length }}</span></a>
                                    </li>
                                    <li class="nav-item ">
                                        <a class="nav-link text-white px-2  text-uppercase small" href="# ">Validé <span class="badge badge-pill bg-success border text-white ml-2 ">{{ this.allTickets.filter((demand)=>demand.rdv !=null).length }}</span></a>
                                    </li>
                                    <li class="nav-item ">
                                        <a class="nav-link text-muted px-2  text-uppercase small" href=" # ">Echec <span class="badge badge-pill bg-white border text-muted ml-2 ">{{ this.allTickets.filter((demand) => demand.status_id !=1).length }}</span></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-auto ml-auto text-right ">
                                <span class="small bg-white border py-1 px-2 rounded mr-2 ">
                    <a href="# " class="text-muted "><i class="fe fe-x mx-1 "></i></a>
                    <span class="text-muted ">Status : <strong>Pending</strong></span>
                                </span>
                                <span class="small bg-white border py-1 px-2 rounded mr-2 ">
                    <a href="# " class="text-muted "><i class="fe fe-x mx-1 "></i></a>
                    <span class="text-muted ">April 14, 2020 - May 13, 2020</span>
                                </span>
                                <button type="button " class="btn " data-toggle="modal " data-target=".modal-slide "><span class="fe fe-filter fe-16 text-muted "></span></button>
                                <button type="button " class="btn "><span class="fe fe-refresh-ccw fe-16 text-muted "></span></button>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-6">
                                <div class="w-lg-50 ">
                                    <form class="">
                                        <input v-model="this.formResearch.search" class="form-control form-control-md bg-white rounded-pill pl-5" type="search" placeholder="code ticket, nom du membre ou description du sujet" aria-label="Search">
                                        <p class="help-text mt-2 text-muted">
                                            <small class="text-white border border-bottom text-muted"> <i class="fe fe-info"></i> {{ this.getfilter_tickets.length }} {{ this.getfilter_tickets.length > 1 ? ' tickets trouvés'  : ' ticket trouvé'}}</small>
                                        </p>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button type="button" class="btn btn-primary float-right" data-toggle="modal" data-target=".modal-full" @click="this.modal_create_rdv = true"><span class="fe fe-calendar fe-12 mr-2"></span>Nouveau rendez-vous </button>
                            </div>

                            <div class="col-md-9">
                                <!-- table -->
                                <table class="table table-borderless table-striped ">
                                    <thead>
                                        <tr class="text-uppercase">
                                            <th>Sujet</th>
                                            <th>Rapide Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(ticket, index) in getfilter_tickets"  :key="index">
                                            <td scope="col d-flex"  style="cursor:pointer;" data-toggle="modal" data-target=".modal-right" @click="one_demandRdv(ticket.id)">
                                                <span>No. {{ index + 1 }} </span>
                                                <div>
                                                    <span class="px-2 ml-4">
                                                        <!-- {{ ticket.demand_describe }} <br/>  -->
                                                        <small  style="font-size:12px;">REF. #{{ ticket.demand_coding }}  </small> 
                                                    </span><br/>
                                                        <span class="text-muted small text-uppercase my-2 mt-4 small">
                                                            <i class="fe fe-user p-1 mr-1 border bg-white small" style="border-radius: 50%;"></i>
                                                                {{ (ticket.identity.identity_firstname != null && ticket.identity.identity_firstname.trim() != "" ? ticket.identity.identity_firstname : "") + "  " + (ticket.identity.identity_middlename != null && ticket.identity.identity_middlename.trim() != "" ? ticket.identity.identity_middlename : "") + "  " + (ticket.identity.identity_lastname != null && ticket.identity.identity_lastname.trim() != "" ? ticket.identity.identity_lastname : "") }}</span>
                                                </div>
                                                </td>
                                            <!-- <td>
                                                <span class="small text-muted ">Attente</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-warning " role="progressbar " style="width: 25% " ></div>
                                                </div>
                                            </td> -->
                                            <!-- <td class="text-muted small ">{{ this.bg_format_date(ticket.createdAt, 'MM,DD YYYY') }}</td> -->
                                            <td class="text-muted small">
                                                    <span class="btn bg-text-phila text-white small text-uppercase" style="font-size:11.5px;" @click.prevent="assigner_rdv(ticket.id)">Assigner</span>
                                            </td>
                                            <!-- <td>
                                                <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="text-muted sr-only">Action</span>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="./support-ticket-detail.html ">Editer</a>
                                                    <a class="dropdown-item " data-toggle="modal" data-target=".modal-right" href="# ">Supprimer</a>
                                                    <a class="dropdown-item " href="# ">Assigner</a>
                                                </div>
                                            </td> -->
                                        </tr>
                                        <!-- <tr>
                                            <td class="text-muted small ">2053</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">lorem eu metus. In lorem. Donec elementum, lorem ut</th>
                                            <td>
                                                <span class="small text-muted ">Completed</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-success " role="progressbar " style="width: 100% " aria-valuenow="100 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 2, 2020</td>
                                            <td>
                                                <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="text-muted sr-only">Action</span>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item" href="./support-ticket-detail.html ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2569</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">sagittis. Duis gravida. Praesent eu</th>
                                            <td>
                                                <span class="small text-muted ">Pending</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-success " role="progressbar " style="width: 0% " aria-valuenow="0 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 9, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2192</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">mattis semper, dui lectus rutrum urna, nec luctus</th>
                                            <td>
                                                <span class="small text-muted ">Processing</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-primary " role="progressbar " style="width: 50% " aria-valuenow="50 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">Apr 27, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2028</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">at arcu. Vestibulum ante ipsum</th>
                                            <td>
                                                <span class="small text-muted ">Assigned</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-warning " role="progressbar " style="width: 20% " aria-valuenow="20 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 4, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2673</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">dui nec urna suscipit nonummy. Fusce fermentum fermentum</th>
                                            <td>
                                                <span class="small text-muted ">Processing</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-primary " role="progressbar " style="width: 50% " aria-valuenow="50 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 3, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2698</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <td>habitant morbi tristique senectus et</td>
                                            <td>
                                                <span class="small text-muted ">Completed</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-success " role="progressbar " style="width: 100% " aria-valuenow="100 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 8, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2382</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <td>arcu. Vestibulum ut eros non enim</td>
                                            <td>
                                                <span class="small text-muted ">Assigned</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-warning " role="progressbar " style="width: 20% " aria-valuenow="20 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">Apr 30, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2582</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">ipsum sodales purus, in molestie tortor nibh</th>
                                            <td>
                                                <span class="small text-muted ">Completed</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-success " role="progressbar " style="width: 100% " aria-valuenow="100 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 6, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted small ">2811</td>
                                            <td class="text-muted small ">BASILUA Ntemo</td>
                                            <th scope="col ">non, bibendum sed, est. Nunc laoreet lectus quis</th>
                                            <td>
                                                <span class="small text-muted ">Processing</span>
                                                <div class="progress mt-2 " style="height: 3px; ">
                                                    <div class="progress-bar bg-primary " role="progressbar " style="width: 50% " aria-valuenow="50 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                </div>
                                            </td>
                                            <td class="text-muted small ">May 14, 2020</td>
                                            <td><button class="btn btn-sm dropdown-toggle more-horizontal " type="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">
                            <span class="text-muted sr-only ">Action</span>
                            </button>
                                                <div class="dropdown-menu dropdown-menu-right ">
                                                    <a class="dropdown-item " href="# ">Edit</a>
                                                    <a class="dropdown-item " href="# ">Remove</a>
                                                    <a class="dropdown-item " href="# ">Assign</a>
                                                </div>
                                            </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                                <nav aria-label="Table Paging " class="my-3 ">
                                    <ul class="pagination justify-content-end mb-0 ">
                                        <li class="page-item "><a class="page-link " href="# ">Previous</a></li>
                                        <li class="page-item active "><a class="page-link " href="# ">1</a></li>
                                        <li class="page-item "><a class="page-link " href="# ">2</a></li>
                                        <li class="page-item "><a class="page-link " href="# ">3</a></li>
                                        <li class="page-item "><a class="page-link " href="# ">Next</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <!-- .col-12 -->
                </div>
                <!-- .row -->
            </div>
            <!-- .container-fluid -->

            <div v-if="this.OneTicket != null" class="modal fade modal-right modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="defaultModalLabel">Modal title</h5>
                            <button type="button" class="close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div class="modal-body">
                            <span class="color-text-phila small text-uppercase my-2 mt-4 small">
                                <i class="fe fe-user p-1 mr-1 border bg-white small" style="border-radius: 50%;"></i>
                                    {{ (OneTicket.identity.identity_firstname != null && OneTicket.identity.identity_firstname.trim() != "" ? OneTicket.identity.identity_firstname : "") + "  " + (OneTicket.identity.identity_middlename != null && OneTicket.identity.identity_middlename.trim() != "" ? OneTicket.identity.identity_middlename : "") + "  " + (OneTicket.identity.identity_lastname != null && OneTicket.identity.identity_lastname.trim() != "" ? OneTicket.identity.identity_lastname : "") }}, <span class="text-lowercase text-white"> {{this.bg_format_date(OneTicket.createdAt,"YYYYMMDDHHSS")  }} </span>
                            </span>
                            <p class="text-justify my-3">
                                {{ OneTicket.demand_describe }}  
                                    <br>
                            </p>
                            <span class="mt-4" style="font-size:12px;">REF. #{{ OneTicket.demand_coding }}  </span> 
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn mb-2 btn-success text-uppercase small"  @click.prevent="assigner_rdv(OneTicket.id,1)">Assigner</button>
                            <button type="button" class="btn mb-2 btn-primary bg-text-phila px-4 text-uppercase small">Modifier</button>
                            <button type="button" id="closeModal" class="btn mb-2 btn-secondary  text-uppercase small" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade modal-notif modal-slide " tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true ">
                <div class="modal-dialog modal-sm " role="document ">
                    <div class="modal-content ">
                        <div class="modal-header ">
                            <h5 class="modal-title " id="defaultModalLabel ">Notifications</h5>
                            <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
                    <span aria-hidden="true ">&times;</span>
                </button>
                        </div>
                        <div class="modal-body ">
                            <div class="list-group list-group-flush my-n3 ">
                                <div class="list-group-item bg-transparent ">
                                    <div class="row align-items-center ">
                                        <div class="col-auto ">
                                            <span class="fe fe-box fe-24 "></span>
                                        </div>
                                        <div class="col ">
                                            <small><strong>Package has uploaded successfull</strong></small>
                                            <div class="my-0 text-muted small ">Package is zipped and uploaded</div>
                                            <small class="badge badge-pill badge-light text-muted ">1m ago</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item bg-transparent ">
                                    <div class="row align-items-center ">
                                        <div class="col-auto ">
                                            <span class="fe fe-download fe-24 "></span>
                                        </div>
                                        <div class="col ">
                                            <small><strong>Widgets are updated successfull</strong></small>
                                            <div class="my-0 text-muted small ">Just create new layout Index, form, table</div>
                                            <small class="badge badge-pill badge-light text-muted ">2m ago</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item bg-transparent ">
                                    <div class="row align-items-center ">
                                        <div class="col-auto ">
                                            <span class="fe fe-inbox fe-24 "></span>
                                        </div>
                                        <div class="col ">
                                            <small><strong>Notifications have been sent</strong></small>
                                            <div class="my-0 text-muted small ">Fusce dapibus, tellus ac cursus commodo</div>
                                            <small class="badge badge-pill badge-light text-muted ">30m ago</small>
                                        </div>
                                    </div>
                                    <!-- / .row -->
                                </div>
                                <div class="list-group-item bg-transparent ">
                                    <div class="row align-items-center ">
                                        <div class="col-auto ">
                                            <span class="fe fe-link fe-24 "></span>
                                        </div>
                                        <div class="col ">
                                            <small><strong>Link was attached to menu</strong></small>
                                            <div class="my-0 text-muted small ">New layout has been attached to the menu</div>
                                            <small class="badge badge-pill badge-light text-muted ">1h ago</small>
                                        </div>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                            <!-- / .list-group -->
                        </div>
                        <div class="modal-footer ">
                            <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Clear All</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade modal-shortcut modal-slide " tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true ">
                <div class="modal-dialog " role="document ">
                    <div class="modal-content ">
                        <div class="modal-header ">
                            <h5 class="modal-title " id="defaultModalLabel ">Shortcuts</h5>
                            <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
                    <span aria-hidden="true ">&times;</span>
                </button>
                        </div>
                        <div class="modal-body px-5 ">
                            <div class="row align-items-center ">
                                <div class="col-6 text-center ">
                                    <div class="squircle bg-success justify-content-center ">
                                        <i class="fe fe-cpu fe-32 align-self-center text-white "></i>
                                    </div>
                                    <p>Control area</p>
                                </div>
                                <div class="col-6 text-center ">
                                    <div class="squircle bg-primary justify-content-center ">
                                        <i class="fe fe-activity fe-32 align-self-center text-white "></i>
                                    </div>
                                    <p>Activity</p>
                                </div>
                            </div>
                            <div class="row align-items-center ">
                                <div class="col-6 text-center ">
                                    <div class="squircle bg-primary justify-content-center ">
                                        <i class="fe fe-droplet fe-32 align-self-center text-white "></i>
                                    </div>
                                    <p>Droplet</p>
                                </div>
                                <div class="col-6 text-center ">
                                    <div class="squircle bg-primary justify-content-center ">
                                        <i class="fe fe-upload-cloud fe-32 align-self-center text-white "></i>
                                    </div>
                                    <p>Upload</p>
                                </div>
                            </div>
                            <div class="row align-items-center ">
                                <div class="col-6 text-center ">
                                    <div class="squircle bg-primary justify-content-center ">
                                        <i class="fe fe-users fe-32 align-self-center text-white "></i>
                                    </div>
                                    <p>Users</p>
                                </div>
                                <div class="col-6 text-center ">
                                    <div class="squircle bg-primary justify-content-center ">
                                        <i class="fe fe-settings fe-32 align-self-center text-white "></i>
                                    </div>
                                    <p>Settings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-full"  tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <!-- <div :class="'modal fade modal-full' + this.modal_create_rdv ? ' show' : ''" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" v-if="this.modal_create_rdv"> -->
                <button aria-label="" type="button" class="close px-2" data-dismiss="modal" aria-hidden="true" >
                    <span aria-hidden="true">×</span>
                </button>
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <div class=" row mb-3">
                                <h2 class="my-3">Fiche de rendez-vous</h2>

                                <form  class="col-12  text-left" v-if="this.step == 1">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>Prenom <span class="text-danger">*</span></label>
                                            <input v-model="this.form.identity_firstname" required="required" type="text"
                                            :class="'form-control p_input  ' + (this.checkForm && (this.form.identity_firstname == null || this.form.identity_firstname.trim() == '') ? ' border-danger' : ' ')"
                                            placeholder="Prénom">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Nom <span class="text-danger">*</span></label>
                                            <input v-model="this.form.identity_middlename" type="text"
                                            :class="'form-control p_input  ' + (this.checkForm && (this.form.identity_middlename == null || this.form.identity_middlename.trim() == '') ? ' border-danger' : ' ')"
                                            required="required" placeholder="Nom">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Postnom</label>
                                            <input v-model="this.form.identity_lastname" type="text" class="form-control p_input" placeholder="Postnom">
                                        </div>
                                    </div>
                                    <div class="form-grow">
                                        <div class="form-group col-md-12">
                                            <label>Sexe <span class="text-danger">*</span></label>
                                            <div class="form-row d-flex">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                <input v-model="this.form.identity_sex" type="radio" class="form-check-input" name="optionsRadios"  value="H">
                                                Homme </label>
                                            </div>
                                            <div class="form-check mx-3">
                                                <label class="form-check-label">
                                                <input v-model="this.form.identity_sex" type="radio" class="form-check-input" name="optionsRadios"  value="F">Femme
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                    <label>Tranche Age <span class="text-danger">*</span></label>
                                    <select v-model="this.form.tranche_age_id" class="form-control p_input">
                                        <option v-for="(tranche_age, index) in trancheAges"  :key="index" :value="tranche_age.id">{{ tranche_age.tranche_designation }}</option>
                                        <!-- <option>36 - 40 Ans</option> -->
                                    </select>
                                    </div>
                                    <div class="text-center align-items-center">
                                    <button v-if="!this.lottieloading" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="access_step_two">Continuer <i class="mdi mdi-arrow-right pt-4"></i></button>
                                    <lottie-player v-if="this.lottieloading" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>

                                <form class="col-12  text-left" v-if="this.step == 2">
                                    <div class="form-row">
                                    <div class="col-12 text-left mb-4">
                                        <span class="display-5  text-white small"><i class="fe fe-arrow-right mr-2"> </i>Etape 2</span>
                                    </div>
                                    </div>
                                    <div class="form-group">
                                    <label>Téléphone <span class="text-danger">*</span></label>
                                    <input type="text" v-model="this.form.identity_phone_number_one" class="form-control p_input">
                                    </div>
                                    <div class="form-group">
                                    <label>Email (Facultatif)</label>
                                    <input v-model="this.form.identity_email" type="email" class="form-control p_input">
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleTextarea1">Sujet <span class="text-danger">*</span></label>
                                        <select v-if="this.subjectRdvs.length > 0" v-model="this.form.subject_id" class="form-control p_input">
                                                <option v-for="(subject, index) in subjectRdvs" :key="index" :value="subject.id"
                                                @click.prevent="selectOneSubject(subject.id)">{{ subject.subject_title }}</option>
                                        </select>
                                    </div>

                                    <!-- <div class="form-group">
                                        <label for="exampleTextarea1">Description <span class="text-danger">*</span></label>
                                        <textarea v-model="this.form.demand_describe" class="form-control" id="exampleTextarea1"
                                            placeholder="Description de la demande de rendez-vous" rows="4"></textarea>
                                    </div> -->
                                    <div class="text-center">
                                        <button v-if="!this.lottieloading" type="submit" class="btn btn-primary btn-block enter-btn"
                                            @click.prevent="to_access_step_three">Soumettre</button>
                                        <lottie-player v-if="this.lottieloading && this.step == 2" class="text-center"
                                            :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                            background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>
                            </div>
                                <button aria-label="" type="button" class="btn btn-secondary  enter-btn"  data-dismiss="modal" aria-hidden="true"  @click="close_modal"><span class="border-bottom border-white">Terminé</span></button>
                            <div v-if="this.step == 1 || this.step == 2" class="row justify-content-center">
                                <div class="col-12 text-right">
                                <button type="button" :class="'btn btn-rounded btn-icon ' + (this.step == 1 ? ' bg-text-phila' : '')" @click.prevent="step_position(1)"> 1
                                </button>
                                <button type="button" :class="'btn btn-rounded btn-icon ' + (this.step == 2 ? ' bg-text-phila' : '')"> 2
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- main -->
    </div>
</template>

<script>

import Composite from './layouts/Composite.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
    name: 'LoadPage',
    components: {
        Composite
    },

  data() {
    return {
        user: null,
       OneTicket: null,
        tickets: [],
        allTickets: [],
        demandeRdv: null,
        trancheAges: [],
        subjectRdvs: [],
      OneSubject: null,
        requesting: false,
        form: {
            identity_middlename: null,
            identity_firstname: null,
            identity_lastname: null,
            identity_phone_number_one: null,
            telephone_format: null,
            tranche_age_id: null,
            identity_email: null,
            subject_id: null,
            demand_describe: null,
            identity_sex: "M",
        },
        formResearch: {
            search: ""
        },
        step: 1,
        modal_create_rdv: false,
        checkForm: false,
        lottieloading: false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

    mounted() {
        this.gb_loadReFreshUser();
        
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.status.id == 1) {
            this.all_tickets();
            this.all_tranches_ages();
            this.all_subjectRdvs();
            setInterval(() => {
                this.all_tickets();
                this.all_tranches_ages();
                this.all_subjectRdvs();
            }, 60000);
        }
    },
  methods: {

        async all_tickets() {
            await axios.get(this.$store.state.app_url_dev + "demand_rdvs").then(({ data }) => {
                if (data.status == 1) {
                    this.tickets = data.data;
                    // this.tickets = data.data.data;
                    this.allTickets = data.all_tickets;
                    // this.allTickets = data.data.all_tickets;
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });
      },

        one_demandRdv(ticket_id) {
            if (this.$store.state.user_auth != null && ticket_id != null && ticket_id.toString().trim() != "" && this.allTickets.length > 0) {
                var ticket = this.allTickets.find((tick) => tick.id == ticket_id);
                if (ticket != null) {
                    this.OneTicket = ticket;
                } else {
                    this.OneTicket = null;
                }
            }
        },

        async all_tranches_ages() {
            await axios.get(this.$store.state.app_url_dev + "tranche_ages").then(({ data }) => {
                if (data.status == 1) {
                    this.trancheAges = data.data;
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });

      },

          async all_subjectRdvs() {
            await axios.get(this.$store.state.app_url_dev + "subjects").then(({ data }) => {
                if (data.status == 1) {
                    this.subjectRdvs = data.data;
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.subjectRdvs = [];
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                  timeout: 2000
                });
            });
        },

            selectOneSubject(subject_id) {
            if (subject_id != null && subject_id.toString().trim() != "" && this.subjectRdvs.length > 0) {
                var selectSubject = this.subjectRdvs.find((subject) => subject.id == subject_id);
                if (selectSubject != null) {
                    this.OneSubject = selectSubject;
                    this.form.demand_describe = selectSubject.subject_describe
                } else {
                    this.OneSubject = null;
                }
            } else {
                this.OneSubject = null;
            }
        },

        async assigner_rdv(demand_id, block=null) {

            if (demand_id.toString().trim() != "" && demand_id.toString().trim() != null) {
                var formRdv = {
                    demand_id,
                    "rdv_note":null
                }

                await axios.post(this.$store.state.app_url_dev + "st_rdv", formRdv).then(({ data }) => {
                    if (data.status == 1) {
                        this.toast.success("Rendez-vous réservé avec succès !!!", {
                                timeout: 2000
                        });

                               var message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr " : "Mme ") + (this.form.identity_firstname.trim()) + ".\n Votre réservation est prise pour le " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date.substring(0, 10) + ", dans " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_designation + "(" + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_localisation_details + "), à " + data.data.horaire_room_pastoral_time.time_cible.time_cible_start.substring(0, 5);


                        // this.user = data;
                        // this.toast.success("Votre compte vient d'etre crée avec success !!!");
                        // var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                        setTimeout(() => {
                            let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);

                            axios.get(urlgetsms).then(() => { });
                            this.$store.state.user_auth = data;
                            this.$store.state.user_store = data;
                            // this.refreshForm();
                            // this.$router.push({ name: 'Confirm' });
                            // this.refreshForm();
                        }, 4500);

                        setTimeout(() => {
                            this.all_tickets();
                            if (block == 1) {
                                const ess = document.getElementById("closeModal");
                                // ess.click();
                                console.log(ess);
                                ess.addEventListener('click', function(e) {console.log(e); });
                            } 
                        }, 2002);


                        
        
                    } else {
                        this.toast.error(data.message, {
                            timeout: 6000
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                    this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                        timeout: 2000
                    });
                });
            } else {
                this.toast.info("Veuillez sélectionner un ticket  !!!", {
                    timeout: 2000
                });
            }
            },
            
        async RegisterUser() {
        if (this.Online()) {
            this.requesting = true;
            if (this.form.nom != null && this.form.prenom != null && this.form.sexe != null && this.form.email != null && this.form.telephone_format != null && this.form.password != null) {

            if (this.getValidNumber(this.form.telephone_format) && this.IsEmail(this.form.email)) {
                if (this.form.nom.trim().length >= 4 && this.form.nom.trim().length <= 16 && this.form.prenom.trim().length >= 4 && this.form.prenom.trim().length <= 16) {
                this.form.telephone = this.getNumberFormat(this.form.telephone_format);
                this.form.nom = this.gb_capitalisingFormat(this.form.nom.trim());
                this.form.prenom = this.gb_capitalisingFormat(this.form.prenom.trim());

                // var postRequest = {
                //   ""
                // }

                await axios.post("https://www.inscription.schoolbac.com", this.form).then(({ data }) => {
                    this.requesting = false;
                    if (data.error == "false") {
                    this.user = data;
                    this.toast.success("Votre compte vient d'etre crée avec success !!!");
                    var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                    setTimeout(() => {
                        let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.telephone;

                        axios.get(urlgetsms).then(() => { });
                        this.$store.state.user_auth = data;
                        this.$store.state.user_store = data;
                        this.$store.state.user_store_password = this.form.password;
                        this.refreshForm();
                        this.$router.push({ name: 'Confirm' });
                    }, 4500);

                    } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                    }
                }).catch(function (error) {
                    console.log(error);
                    this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                    });
                });

                } else {
                if (this.form.nom.trim().length < 4 || this.form.prenom.trim().length < 4) {
                    this.toast.info("Le nom ou le prenom ne doit pas etre inférieur à 4 caractères !!!", {
                    timeout: 6000
                    });
                } else {
                    // this.messageErrors = "";
                    this.toast.info("Le nom ou le prenom ne doit pas dépasser 16 caractères !!!", {
                    timeout: 6000
                    });
                }
                }
            }

            } else {
            this.toast.error("Veuillez completer les champs manquants !!!", {
                timeout: 6000
            });
            }
        }
        },

        refreshForm() {
            this.form.identity_middlename = null,
            this.form.identity_firstname = null,
            this.form.identity_lastname = null,
            this.form.identity_sex = "M",
            this.form.identity_phone_number_one = null,
            this.form.telephone_format = null,
            this.form.identity_email = null,
            this.form.tranche_age_id = null,
            this.form.subject_id = null,
            this.form.demand_describe = null,
            this.messageErrors = null,
            setTimeout(() => {
                this.step = 1;
            }, 7000);
        },

        changesexe(typesexe) {
        if (this.isSexe) {
            this.isSexe = false;
        } else {
            this.isSexe = true;
        }
        this.form.sexe = typesexe;
        },

        change_type_input() {
        if (this.type_input_password == false) {
            this.type_input_password = true;
        } else {
            this.type_input_password = false;
        }
        },

        IsEmail(email) {
        var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
        if (!regex.test(email)) {
            this.toast.error("Adresse email invalide", {
            timeout: 5000
            });
            return false;
        } else {
            return true;
        }
        },

        // verifi numero e telephone
        getValidNumber(value) {
        if (value.length == 10 || value.length == 12 || value.length == 13) {

            if (value.length == 10) {
            if (value.substring(0, 1) == '0') {
                if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
                value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
                value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
                return true;
                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }

            } else {
                this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
                });
                return false;
            }

            } else if (value.length == 12) {

            if (value.substring(0, 3) == '243') {

                if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
                value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
                value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
                return true;
                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }

            } else {
                this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
                });
                return false;
            }
            } else if (value.length == 13) {

            if (value.substring(0, 1) == '+') {

                if (value.substring(1, 4) == '243') {
                if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                    value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                    value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }

            } else {
                this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
                });
                return false;
            }
            }

        } else {
            this.toast.error("Numéro de téléphone invalide", {
            timeout: 5000
            });
            return false;
        }
        return false;
        },

        getNumberFormat(value) {
        if (value.length > 0) {
            if (value.length == 13 && value.substring(0, 1) == '+') {
            return value;
            } else if (value.length == 12) {
            return '+' + value;
            } else if (value.length == 10) {
            return '+243' + value.substring(1, value.length);
            } else if (value.length == 9) {
                    return '+243' + value.trim();
                }
        }
      },
        
        getNumberFormatSms(value) {
            if (value.length > 0) {
                if (value.length == 13 && value.substring(0, 1) == '+') {
                    return value.substring(1, value.length);
                } else if (value.length == 12) {
                    return value;
                } else if (value.length == 10) {
                    return '243' + value.substring(1, value.length);
                } else if (value.length == 9) {
                    return '+243' + value;
                }
            }
        },

      // demand
         access_step_two() {
            if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
                this.lottieloading = true;
                // if (this.Online()) {
                setTimeout(() => {
                    this.step = 2;
                    this.lottieloading = false
                }, 1500);
                // } else {
                //     setTimeout(() => {
                //     this.lottieloading = false
                //   }, 2500);
                // } 
            } else {
                this.checkForm = true;
                if (this.form.identity_middlename == null || this.form.identity_middlename.trim() == "") {
                    this.toast.error("Veuillez compléter le nom", {
                        timeout: 5000
                    });
                } else {
                    this.toast.error("Veuillez compléter les champs manquants", {
                        timeout: 5000
                    });
                }
            }
        },

        to_access_step_three() {
            // if (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "" && this.form.demand_describe != null && this.form.demand_describe.trim() != "") {
            if (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "") {
                this.lottieloading = true;
                // if (this.Online()) {
                this.RegisterDemandUser();
                // } else {
                //     setTimeout(() => {
                //     this.lottieloading = false
                //   }, 2500);
                // } 
            } else {
                this.checkForm = true;
                this.toast.error("Veuillez compléter les champs manquants", {
                    timeout: 5000
                });
            }
        },

      step_position(position) {
          if (position == 1) {
              if (this.form.identity_phone_number_one.trim() !="" && this.form.identity_phone_number_one !=null) {
                      this.step = 1;
                }
            }
        },

        async RegisterDemandUser() {
            // if (this.Online()) {
            this.requesting = true;
            if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {

                // if (this.getValidNumber(this.form.identity_phone_number_one) ) {
                if (this.form.identity_middlename.trim().length >= 3 && this.form.identity_middlename.trim().length <= 16 && this.form.identity_firstname.trim().length >= 3 && this.form.identity_firstname.trim().length <= 16) {
                    this.form.telephone_format = this.getNumberFormat(this.form.identity_phone_number_one);
                    this.form.identity_middlename = this.gb_capitalisingFormat(this.form.identity_middlename.trim());
                    this.form.identity_firstname = this.gb_capitalisingFormat(this.form.identity_firstname.trim());
                    var ObjAdd = {
                        "user_point_created": "CREATED_FRONT_DESK",
                        "user_parent_id": this.$store.state.user_auth !=null ? this.$store.state.user_auth.id : null
                    }
                    this.form = {...this.form, ...ObjAdd }

                    await axios.post(this.$store.state.app_url_dev + "st_demand_rdv_client", this.form).then(({ data }) => {
                        if (data.status == 1) {
                            this.modal_create_rdv = false;
                            // this.close_modal();
                            // $('.close').click();

                            // this.toast.info("Rendez-vous réservé avec succès !!!", {
                            //     timeout: 2000
                            // });

                            setTimeout(() => {
                                this.lottieloading = false;

                                setTimeout(() => {
                                    if (data.data != null && data.data.id != null && data.data.id.toString().trim() != "") {
                                        this.assigner_rdv(data.data.id)
                                    }
                                 }, 1700);


                                //     var message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr " : "Mme ") + (this.form.identity_firstname.trim()) + ".\n Votre réservation est prise pour le " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date.substring(0, 10) + ", dans " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_designation + "(" + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_localisation_details + "), à " + data.data.horaire_room_pastoral_time.time_cible.time_cible_start.substring(0, 5);


                                // // this.user = data;
                                // // this.toast.success("Votre compte vient d'etre crée avec success !!!");
                                // // var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                                // setTimeout(() => {
                                //     let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila Cité d'exaucement &telephone=" + this.form.identity_phone_number_one;

                                //     axios.get(urlgetsms).then(() => { });
                                //     this.$store.state.user_auth = data;
                                //     this.$store.state.user_store = data;
                                //     this.refreshForm();
                                //     this.$router.push({ name: 'Confirm' });
                                //     this.refreshForm();
                                // }, 4500);


                            }, 3000);


                            // var message = "Bonjour" + (this.form.identity_sex.trim() == "M" ? "Mr  " : "Mme ") + (this.form.identity_firstname.trim()) + "\n Votre réservation vient d'etre faites avec succès. Vous recevez très bientot, l'horaire de votre rendez-vous.";



                            // var message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr " : "Mme ") + (this.form.identity_firstname.trim()) + ".\n Votre réservation est prise pour le " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date.substring(0, 10) + ", dans " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_designation + "(" + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_localisation_details + "), à " + data.data.horaire_room_pastoral_time.time_cible.time_cible_start.substring(0, 5);


                            // // this.user = data;
                            // // this.toast.success("Votre compte vient d'etre crée avec success !!!");
                            // // var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                            // setTimeout(() => {
                            //     let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila Cité d'exaucement &telephone=" + this.form.identity_phone_number_one;

                            //     axios.get(urlgetsms).then(() => { });
                            //     this.$store.state.user_auth = data;
                            //     this.$store.state.user_store = data;
                            //     // this.refreshForm();
                            //     // this.$router.push({ name: 'Confirm' });
                            // }, 4500);

                        } else {
                            this.toast.error(data.message, {
                                timeout: 6000
                            });

                            setTimeout(() => {
                                this.refreshForm();
                                this.step = 1;
                                this.lottieloading = false;
                            }, 6001);
                        }
                    }).catch(function (error) {
                        console.log(error);
                        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                            timeout: 2000
                        });
                    });


                    //sms
                    // await axios.post("https://www.inscription.schoolbac.com", this.form).then(({ data }) => {
                    //   this.requesting = false;
                    //   if (data.error == "false") {
                    //     this.user = data;
                    //     this.toast.success("Votre compte vient d'etre crée avec success !!!");
                    //     var message = "Bonjour " + this.form.identity_middlename + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                    //     setTimeout(() => {
                    //       let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.telephone_format;

                    //       axios.get(urlgetsms).then(() => { });
                    //       this.$store.state.user_auth = data;
                    //       this.$store.state.user_store = data;
                    //       this.refreshForm();
                    //       this.$router.push({ name: 'Confirm' });
                    //     }, 4500);

                    //   } else {
                    //     this.toast.error(data.message, {
                    //       timeout: 6000
                    //     });
                    //   }
                    // }).catch(function (error) {
                    //   console.log(error);
                    //   this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    //     timeout: 2000
                    //   });
                    // });





                } else {
                    if (this.form.identity_middlename.trim().length < 3 || this.form.identity_firstname.trim().length < 3) {
                        this.toast.info("Le nom ou le prenom ne doit pas etre inférieur à 3 caractères !!!", {
                            timeout: 6000
                        });
                    } else {
                        // this.messageErrors = "";
                        this.toast.info("Le nom ou le prenom ne doit pas dépasser 16 caractères !!!", {
                            timeout: 6000
                        });
                    }
                }
                // }

            } else {
                this.toast.error("Veuillez completer les champs manquants !!!", {
                    timeout: 6000
                });
            }
            // }
        },

      close_modal() {
          console.log("close modal");
          this.step == 1;
          this.refreshForm();
        },
    },
  computed: {
        getfilter_tickets() {
            return this.tickets.filter((ticket) => {
                return  ticket.demand_coding
                        .toLowerCase()
                        .includes(this.formResearch.search.toLowerCase()) ||   ticket.identity.identity_firstname
                        .toLowerCase()
                        .includes(this.formResearch.search.toLowerCase()) || ticket.identity.identity_middlename
                            .toLowerCase()
                            .includes(this.formResearch.search.toLowerCase()) || ticket.identity.identity_phone_number_one
                                .toLowerCase()
                                .includes(this.formResearch.search.toLowerCase())  || ticket.demand_describe
                                    .toLowerCase()
                                    .includes(this.formResearch.search.toLowerCase());
            });
        },
    },
}
</script>
