<template>
  <div class="wrapper vh-100">
    <div class="row align-items-center h-100">
      <form  @submit.prevent="authUser" class="col-lg-3 col-md-4 col-10 mx-auto text-center">
        <a class="navbar-brand mx-auto mt-2 d-flex-fill text-center" href="./index.html">
          <img src="assets/images/cropped-Phila-1-1-192x192.png" width="20%" alt="">
          <span class="color-text-phila small">Phila Cité d'exaucement </span>
        </a>
        <p class="card-description color-text-phila">
          <span class="h5 text-white text-uppercase">Front Desk </span>
        </p>
        <div class="form-group mt-5">
          <label for="inputEmail" class="sr-only">Téléphone</label>
          <input type="text" id="inputEmail" class="form-control form-control-lg" placeholder="Téléphone" required=""
            autofocus="" v-model="this.form.pseudo">
        </div>
        <div class="form-group">
          <label for="inputPassword" class="sr-only">Mot de passe</label>
          <input type="password" id="inputPassword" class="form-control form-control-lg" placeholder="Mot de passe"
            required="" v-model="this.form.password">
        </div>
        <div class="checkbox mb-3">
            <label>
            </label>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <button v-if="!this.lottieloading" class="btn btn-lg btn-primary btn-block bg-text-phila" type="submit">Connexion</button>
            <lottie-player v-if="this.lottieloading" class="text-center"
              :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
              background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
          </div>
        </div>
        <p class="mt-5 mb-3 text-muted">© 2024</p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'LoadPage',

  data() {
    return {
      user: null,
      requesting: false,
      form: {
        pseudo: null,
        password: null,
      },
      step: 1,
      checkForm: false,
      lottieloading: false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  methods: {
      async authUser() {
      // if (this.Online()) {
      if (this.form.pseudo != null && this.form.pseudo.trim() != "" && this.form.password != null && this.form.password != "") {
        // if (this.getValidNumber(this.form.pseudo.trim())) {
        this.lottieloading = true;
        var pseudo = this.gb_getNumberFormat(this.form.pseudo.trim());

        var postData = {
          "user_pseudo": pseudo,
          "user_password": this.form.password
        };
        await axios.post(this.$store.state.app_url_dev + "login", postData).then(({
          data
        }) => {
          if (data.status == 1) {

            if (data.data.status_id == 1) {
              // this.SendSms();
              this.user = data;
              // this.$store.state.userNumberNotClient = null;
              this.$store.state.user_auth = data.data;
              this.$store.commit('AUTHENTIFICATE_USER', data.data);
              localStorage.setItem('AUTHENTIFICATE_USER', JSON.stringify(data.data));

              setTimeout(() => {
                this.lottieloading = false;
                if (data.data !=null && data.data.profiler !=null && data.data.profiler.profiler_designation =="PASTEUR") {
                  this.$router.push({ name: 'PlanningsPastor' });
                } else  if (data.data != null && data.data.profiler != null && data.data.profiler.profiler_designation == "FRONT-DESK") {
                  this.$router.push({ name: 'news-member' });
                  // this.$router.push({ name: 'rdvs-v1' });
                  // this.$router.push({ name: 'rdvs' });
                }else  if (data.data != null && data.data.profiler != null && (data.data.profiler.profiler_designation == "ADMIN" || data.data.profiler.profiler_designation == "GESTIONNAIRE")) {
                  this.$router.push({ name: 'Settings' });
                  // this.$router.push({ name: 'rdvs-v1' });
                  // this.$router.push({ name: 'rdvs' });
                }
              }, 3500);
  
            } else {
              if (data.data.status_id == 2) {
                this.toast.info("Votre compte est désactivé \n Veuillez contacter l'administrateur !!! ");
                this.refreshAfterSubmit();
              } else {
                this.toast.info("Accès au compte réfusé \n Veuillez contacter l'administrateur !!!");
                this.refreshAfterSubmit();
              }
            }
          } else if (data.status == 0) {
            
            this.toast.info("Numéro de téléphone ou mot de passe incorrect !!!");
            this.refreshAfterSubmit();
          } else {
            this.toast.info("Une erreur est survenue, veuillez réessayer  !!!");
            this.refreshAfterSubmit();
          }
        }).catch(function (error) {
          //this.refreshAfterSubmit();
          console.log(error);
          // this.toast.info(error);
          // this.toast.info("Une erreur est survenue, veuillez réessayer." + error);
        });
        // } else {
        //   this.toastMessage("error", "Le numéro de téléphone est incorrect  !!!");
        // }
      } else {
        this.toast.error("Entrer votre numéro de téléphone  !!!");
      }
      // }
    },

    refreshForm() {
      this.form.nom = null,
        this.form.prenom = null,
        this.form.sexe = "M",
        this.form.telephone = null,
        this.form.password = null,
        this.form.pseudo = null,
        this.form.email = null,
        this.messageErrors = null
    },

    refreshAfterSubmit() {
      setTimeout(() => {
        this.lottieloading = false;
        this.refreshForm();
      }, 2800);
    },

    change_type_input() {
      if (this.type_input_password == false) {
        this.type_input_password = true;
      } else {
        this.type_input_password = false;
      }
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
