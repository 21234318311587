<template>
    <div class="wrapper">
        <composite v-if="this.$store.state.user_auth != null" class="d-none"> </composite>
        <main role="main" class="main-content">
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="row w-100 m-0 justify-content-around">
                    <!-- <div class="content-wrapper full-page-wrapper d-md-flex justify-content-center align-items-center auth login-bg"> -->
                        
                        <div class="card col-lg-5 col-12">
                            <div class="card-description color-text-phila text-xs-center px-4 pt-2 mt-4">
                                <img src="assets/images/cropped-Phila-1-1-32x32.png" alt="" srcset=""/>
                                Phila-Cité d'Exaucement
                            </div>
                            <div class="card-body">
                                <div class="pb-2">
                                    <h1 class="display-4" v-if="this.step == 1">Fiche d'enregistrement</h1>
                                    <h1 class="display-4 mb-3" v-if="this.step == 3 && this.form.state_envol == 1">Connecting Card</h1>
                                </div>
                                <form v-if="this.step == 0" class="my-4 d-none">
                                    <div class="form-group">
                                        <label>Téléphone <span class="text-danger">*</span></label>
                                        <input type="text" v-model="this.form.identity_phone_number_one" class="form-control p_input">
                                    </div>
                                    <div class="form-group">
                                        <label>Email (Facultatif)</label>
                                        <input v-model="this.form.identity_email" type="email" class="form-control p_input">
                                    </div>
                    
                                    <div class="text-center align-items-center">
                                        <button v-if="!this.lottieloading" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="access_step_two">Continuer <i class="mdi mdi-arrow-right pt-4"></i></button>
                                        <lottie-player v-if="this.lottieloading" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>
                
                                <form v-if="this.step == 0" class="my-4 justify-content-left d-none">
                                    <div class="form-group col-md-10">
                                        <label>Téléphone</label>
                                        <input type="text" v-model="this.form.identity_phone_number_one" class="form-control p_input">
                                    </div>
                                    <div class="form-group col-md-10">
                                        <label>Email</label>
                                        <input v-model="this.form.identity_email" type="email" class="form-control p_input">
                                    </div>
                    
                                    <div class="form-grow col-md-12 ml-md-2 pl-md-3 mt-4">
                                        <div class="form-group">
                                            <div class="row d-flex">
                                                <span class="p-1 mr-1" style="background-color: rgb(213, 130, 6);"> </span><label class="mt-1">Nature de la relation avec Phila-Cité d'Exaucement <span class="text-danger">*</span></label>
                                            </div>
                                            <div class="text-left" style="margin-left: 5px;">
                                                <p class="text-sm small color-text-phila"> Veuillez sélectionner une option</p>
                                            </div>
                                            <div class="form-row" style="display: block !important;">
                                                <div class="form-check form_check_select mx-2">
                                                <label class="form_select_options text-white small">
                                                    <input type="radio"  name="directionUser" value="1" class="form-check-input"
                                                        style="opacity: 1 !important;" @click="togglestaterdv(1)">
                                                Premier contact avec l'église</label>
                                                </div>
                                            
                                                <div class="form-check form_check_select mx-2">
                                                <label class="form_select_options text-white">
                                                    <input type="radio"  name="directionUser" value="3" class="form-check-input"
                                                        style="opacity: 1 !important;" @click="togglestaterdv(3)">
                                                        <!-- style="opacity: 1 !important;" @click.prevent="show =!show"> -->
                                                    Donner ma vie à Jésus-Christ</label>
                                                </div>
                        
                                                <div class="form-check form_check_select mx-2">
                                                <label class="form_select_options text-white small">
                                                    <input type="radio"  name="directionUser" value="2" class="form-check-input"
                                                        style="opacity: 1 !important;" @click="togglestaterdv(2)">
                                                    Rencontre un serviteur de Dieu</label>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div class="text-center">
                                        <button v-if="!this.lottieloading && this.step == 0" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="verifElementsStep">Continuer</button>
                                        <lottie-player v-if="this.lottieloading && this.step == 0" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                        <small v-if="this.lottieloading && this.step == 0">Veuillez patientez...</small>
                                    </div>
                                </form>
                
                                <form v-if="this.step == 1">
                                    <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label>Prénom <span class="text-danger">*</span></label>
                                        <input v-model="this.form.identity_firstname" required="required" type="text"
                                        :class="'form-control p_input  ' + (this.checkForm && (this.form.identity_firstname == null || this.form.identity_firstname.trim() == '') ? ' border-danger' : ' ')"
                                        placeholder="Prénom">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Nom <span class="text-danger">*</span></label>
                                        <input v-model="this.form.identity_middlename" type="text"
                                        :class="'form-control p_input  ' + (this.checkForm && (this.form.identity_middlename == null || this.form.identity_middlename.trim() == '') ? ' border-danger' : ' ')"
                                        required="required" placeholder="Nom">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Post-nom</label>
                                        <input v-model="this.form.identity_lastname" type="text" class="form-control p_input"
                                        placeholder="Post-nom">
                                    </div>
                                    </div>
                                    <div class="form-row" style="margin-bottom: -15px !important;">
                                    <div class="form-group col-md-12">
                                        <label>Sexe <span class="text-danger">*</span></label>
                                        <div class="form-row d-flex">
                                        <!-- <div class="form-check">
                                            <label class="form-check-label">
                                            <input v-model="this.form.identity_sex" type="radio" class="form-check-input"
                                                name="optionsRadios" value="M">
                                            Homme </label>
                                        </div> -->

                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options  text-white">
                                            <input v-model="this.form.identity_sex" type="radio" name="optionsRadios"  class="form-check-input"
                                                style="opacity: 1 !important;" value="M">
                                                Homme
                                            </label>
                                        </div>
                                        <!-- <div class="form-check mx-3">
                                            <label class="form-check-label">
                                            <input v-model="this.form.identity_sex" type="radio" class="form-check-input"
                                                name="optionsRadios" value="F">Femme
                                            </label>
                                        </div> -->

                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options  text-white">
                                            <input v-model="this.form.identity_sex" type="radio" name="optionsRadios"  class="form-check-input"
                                                style="opacity: 1 !important;" value="F">
                                                Femme
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="form-group">
                                    <label>Date de naissance <span class="text-danger">*</span></label>
                                    <input type="date" v-model="this.form.birth_date"
                                    :class="'form-control p_input  ' + (this.checkForm && (this.form.birth_date == null || this.form.birth_date.trim() == '') ? ' border-danger' : ' ')">
                                    </div>


                                    <div v-if="this.trancheAges.length > 0" class="form-group d-none">
                                    <label>Tranche Age <span class="text-danger">*</span></label>
                                    <select v-model="this.form.tranche_age_id" class="form-control p_input">
                                        <option v-for="(tranche_age, index) in trancheAges" :key="index" :value="tranche_age.id">{{
                                        tranche_age.tranche_designation }}</option>
                                        <!-- <option>36 - 40 Ans</option> -->
                                    </select>
                                    </div>

                                    <div class="text-center">
                                    <!-- <button v-if="!this.lottieloading && this.form.state_envol == 3" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="to_store_contact">Enregistrer</button> -->

                                    <button v-if="!this.lottieloading && this.step == 1" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="access_step_two">Continuer</button>

                                    <lottie-player v-if="this.lottieloading && this.step == 1" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>

                
                                <!-- <form v-if="this.step == 2"> -->
                                <form v-if="this.step == 2">
                                    <div class="form-row">
                                    <div class="col-12 text-left mb-2">
                                        <span class="display-6 card-description  small">Bien-{{ this.form.identity_sex.trim() == "M" ? "aimé" : "aimée" }} </span> <br/>
                                        <!-- <span class="display-6 card-description  small">Bien-{{ this.form.identity_sex.trim() == "M" ? "aimé frère" : "aimée soeur" }} </span> <br/> -->
                                        <!-- <span class="display-6 card-description  small">Très {{ this.form.identity_sex.trim() == "M" ? "cher  frère" : "chère soeur" }} </span> <br/> -->
                                        <span class="display-5  text-white text-capitalize">{{ (this.form.identity_firstname ??
                                        this.form.identity_firstname) + ' ' + (this.form.identity_middlename ??
                                            this.form.identity_middlename)
                                        }}</span>
                                        <p class="card-description">
                                        <!-- <img src="./assets/images/faces-clipart/pic-2.png" alt="image" class="mr-2" /> -->
                                        <span>Nous sommes heureux de vous savoir parmi nous</span>
                                        </p>
                                    </div>
                                    </div>
                                    <div class="form-group">
                                    <label>Téléphone <span class="text-danger">*</span></label>
                                    <input type="text" v-model="this.form.identity_phone_number_one" 
                                    :class="'form-control p_input  ' + (this.checkForm && (this.form.identity_phone_number_one == null || this.form.identity_phone_number_one.trim() == '') ? ' border-danger' : ' ')">
                                    </div>
                                    <div class="form-group">
                                    <label>Contact (Facultatif)</label>
                                    <input type="text" v-model="this.form.contact" class="form-control p_input">
                                    </div>
                                    <div class="form-group">
                                    <label>Email (Facultatif)</label>
                                    <input v-model="this.form.identity_email" type="email" class="form-control p_input">
                                    </div>

                                    <div class="form-grow">
                                    <div class="form-group col-md-12">
                                        <div class="row d-flex">
                                        <span class="p-1 mr-1" style="background-color: rgb(213, 130, 6);"> </span><label class="mt-1">Nature de la relation avec Phila-Cité d'Exaucement</label>
                                        </div>
                                        <div class="text-left" style="margin-left: 5px;">
                                        <p class="text-sm small color-text-phila"> Veuillez sélectionner une option</p>
                                        </div>
                                        <div class="form-row" style="display: block !important;">
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white small">
                                            <input type="radio"  name="directionUser" value="1" class="form-check-input"
                                                style="opacity: 1 !important;" @click="togglestaterdv(1)">
                                            Premier contact avec l'église</label>
                                        </div>
                                        
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio"  name="directionUser" value="3" class="form-check-input"
                                                style="opacity: 1 !important;" @click="togglestaterdv(3)">
                                                <!-- style="opacity: 1 !important;" @click.prevent="show =!show"> -->
                                            Donner ma vie à Jésus-Christ</label>
                                        </div>

                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white small">
                                            <input type="radio"  name="directionUser" value="2" class="form-check-input"
                                                style="opacity: 1 !important;" @click="togglestaterdv(2)">
                                            Rencontre un serviteur de Dieu</label>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    </div>

                                    <div class="form-group d-none" v-if="this.form.state_envol ==2">
                                    <label for="exampleTextarea1">Objet <span class="text-danger">*</span></label>
                                    <select v-if="this.subjectRdvs.length > 0" v-model="this.form.subject_id" class="form-control p_input">
                                        <option v-for="(subject, index) in subjectRdvs" :key="index" :value="subject.id"
                                        @click.prevent="selectOneSubject(subject.id)">{{ subject.subject_title }}</option>
                                    </select>
                                    <textarea v-model="this.form.demand_describe" class="form-control d-none" id="exampleTextarea1"
                                        placeholder="Description de la demande de rendez-vous" rows="4"></textarea>
                                    </div>
                                    <div class="text-center">
                                    <button v-if="!this.lottieloading && this.form.state_envol == 3" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="to_store_contact">Enregistrer</button>

                                    <button v-if="!this.lottieloading && (this.form.state_envol ==1 || this.form.state_envol ==2 )" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="to_access_step_three">Continuer</button>
                                    <lottie-player v-if="this.lottieloading && this.step == 2" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>
                
                                <!-- /// News version new venu -->
                                <form v-if="(this.step == 3 && this.form.state_envol == 1 && this.form.state_rdv != 1)">
                                    <div class="form-group col-md-12">
                                    <label>Etat civil <span class="text-danger">*</span></label>
                                    <div class="form-row d-flex">
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white small">
                                            <input type="radio" v-model="this.form.marital_status" value="Marié(e)" class="form-check-input"
                                                style="opacity: 1 !important;" name="radioMarital"  @click="toggleStatusMarital(0)">
                                        Marié(e)</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white small">
                                            <input type="radio" v-model="this.form.marital_status" value="Célibataire" class="form-check-input"
                                                style="opacity: 1 !important;" name="radioMarital"  @click="toggleStatusMarital(0)">
                                            Célibataire</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white">
                                            <input type="radio" class="form-check-input" name="radioMarital"
                                                style="opacity: 1 !important;" @click="toggleStatusMarital(1)">
                                            Autre</label>
                                        </div>
                                        <div v-if="this.form.other_marital_status" class="form-group col-md-12">
                                            <input v-model="this.form.marital_status" type="text"
                                            class="small form-control p_input"
                                            placeholder="Spéciez(Autre)">
                                        </div>
                                    </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                    <label>Occupation <span class="text-danger">*</span></label>
                                    <div class="form-row d-flex">
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white small">
                                            <input type="radio" name="radioJob" class="form-check-input"
                                                style="opacity: 1 !important;" v-model="this.form.profession" value="Etudiant" @click="toggleStatusProfession(0)">
                                        Etudiant</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white small">
                                            <input type="radio" name="radioJob" class="form-check-input"
                                                style="opacity: 1 !important;" v-model="this.form.profession" value="Elève" @click="toggleStatusProfession(0)">
                                            Elève</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white">
                                            <input type="radio" name="radioJob" class="form-check-input"
                                                style="opacity: 1 !important;" v-model="this.form.profession" value="Employé(e)" @click="toggleStatusProfession(0)">
                                            Employé(e)</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                        <label class="form_select_options text-white">
                                            <input type="radio" name="radioJob" class="form-check-input"
                                                style="opacity: 1 !important;" @click="toggleStatusProfession(1)">
                                            Autre</label>
                                        </div>
                                        <div v-if="this.form.other_profession_status" class="form-group col-md-12">
                                            <input type="text" v-model="this.form.profession"
                                            class="small form-control p_input"
                                            placeholder="Spéciez(Autre)">
                                        </div>
                                    </div>
                                    </div>

                                    <div class="form-grow">
                                    <div class="form-group col-md-12">
                                        <div class="row d-flex">
                                        <span class="p-1 mr-1" style="background-color: rgb(213, 130, 6);"> </span><label class="mt-1">Comment avez-vous entendu parler de nous ? <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="form-row d-flex">
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" value="Affiches" class="form-check-input"
                                                style="opacity: 1 !important;" @click="toggleStatusKnowledge(0)" v-model="this.form.details_knowledge">
                                            Affiches</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" value="Télévision" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusKnowledge(0)" v-model="this.form.details_knowledge">
                                            Télévision</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusKnowledge(0)" v-model="this.form.details_knowledge">
                                            Réseaux sociaux</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" value="Ami(e)" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusKnowledge(0)" v-model="this.form.details_knowledge">
                                            Ami(e)</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" value="Famille" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusKnowledge(0)" v-model="this.form.details_knowledge">
                                            Famille</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" value="Invitation" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusKnowledge(0)" v-model="this.form.details_knowledge">
                                            Invitation</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioKnowledge" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusKnowledge(1)">
                                            Autre</label>
                                        </div>

                                        <div v-if="this.form.other_knowledge_status" class="form-group col-md-12">
                                            <input  required="required" type="text" v-model="this.form.details_knowledge"
                                            :class="'small form-control p_input  ' + (this.checkForm && (this.form.identity_firstname == null || this.form.identity_firstname.trim() == '') ? ' border-danger' : ' ')"
                                            placeholder="Spéciez (Autre) ">
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="form-grow">
                                    <div class="form-group col-md-12">
                                        <div class="row d-flex">
                                        <span class="p-1 mr-1" style="background-color: rgb(213, 130, 6);"> </span><label class="mt-1">Pensez-vous revenir prochainement ? <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="form-row d-flex">
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioPray"  class="form-check-input"
                                                style="opacity: 1 !important;" @click="toggleStatusPray(0)">
                                            Oui</label>
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="radio" name="radioPray" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="toggleStatusPray(1)">
                                            Non, j'ai déjà une égise d'attache</label>
                                        </div>

                                        <div v-if="this.form.other_pray_status" class="form-group col-md-12">
                                            <!-- <label class=" small">Nom de l'église <span class="text-danger">*</span></label> -->
                                            <input  required="required" type="text"
                                            class="small form-control p_input"
                                            placeholder="Nom de votre église"  v-model="this.form.details_pray_name">
                                        </div>
                                        <!-- <div class="form-check  mx-2">
                                            <label class="form-check-label form_select_options text-white">
                                            <input type="checkbox" class="form-check-input"
                                                style="opacity: 1 !important;">
                                            Autre</label>
                                        </div> -->
                                        </div>
                                    </div>
                                    </div>

                                    <div class="form-grow">
                                    <div class="form-group col-md-12">
                                        <div class="row d-flex">
                                        <span class="p-1 mr-1" style="background-color: rgb(213, 130, 6);"> </span><label class="mt-1">J'aimerais</label>
                                        </div>
                                        <div class="form-row d-flex">
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white small">
                                            <input type="checkbox" name="radiowish" value="Devenir membre de Phila-Cité d'Exaucement" class="form-check-input"
                                                style="opacity: 1 !important;" @click="wishMethodArray('Devenir membre de Phila-Cité d\'Exaucement')">
                                            Devenir membre de Phila-Cité d'Exaucement</label>
                                            <!-- <input type="checkbox" class="form-check-input"
                                                style="opacity: 1 !important;">
                                            Devenir membre de Phila-Cité d'Exaucement</label> -->
                                        </div>
                                        <div v-if="this.form.details_pray_state ==0 || this.form.details_pray_state ==null" class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white small">
                                            <input type="checkbox" name="radiowish" value="Me faire baptiser" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="wishMethodArray('Me faire baptiser')">
                                            Me faire baptiser</label>
                                            <!-- <input type="radio" name="radiowish" value="Me faire baptiser" class="form-check-input"
                                                style="opacity: 1 !important;" v-model="this.form.wish">
                                            Me faire baptiser</label> -->
                                        </div>
                                        <div class="form-check form_check_select mx-2">
                                            <label class="form_select_options text-white">
                                            <input type="checkbox" name="radiowish" value="Rencontrer un pasteur" class="form-check-input"
                                                style="opacity: 1 !important;"  @click="wishMethodArray('Rencontrer un pasteur')">
                                            Rencontrer un pasteur</label>
                                            <!-- <input type="radio" name="radiowish" value="Rencontrer un pasteur" class="form-check-input"
                                                style="opacity: 1 !important;" v-model="this.form.wish">
                                            Rencontrer un pasteur</label> -->
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                                    <!-- <transition name="slide-fade"> -->
                                    <div class="form-grow">
                                    <div class="form-group col-md-12">
                                        <div class="row d-flex">
                                        <i class="mdi mdi-hand-left"></i>
                                        <span class="small color-text-phila p-1 ml-2">Pour finir</span>
                                        </div>
                                        <div class="row d-flex">
                                        <span class="p-1 mr-1" style="background-color: rgb(213, 130, 6);"></span><label class="mt-1">Besoin de prière</label>
                                        </div>
                                        <div class="form-row mt-2">
                                        <small> Si oui, écrivez votre sujet de prière ici</small>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <textarea rows="5"
                                        class="small form-control p_input"
                                        type="text"
                                        placeholder="Sujet de prière" v-model="this.form.need_pray">
                                        </textarea>
                                    </div>
                                    </div>
                                    <!-- </transition> -->
                    
                                    <div class="text-center align-items-center">
                                    <button v-if="!this.lottieloading && this.wishArray.includes('Rencontrer un pasteur') ==false" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="to_store_contact">Enregistrer <i class="mdi mdi-arrow-right pt-4"></i></button>
                                    <button v-if="!this.lottieloading && this.wishArray.includes('Rencontrer un pasteur') ==true" type="btn" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="step_rdv">Continuer <i class="mdi mdi-arrow-right pt-4"></i></button>
                                    <lottie-player v-if="this.lottieloading" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>
                                <!-- /// News version -->
                
                                <!-- suite demande rdv -->
                                <form v-if="(this.step == 3 && this.form.state_envol == 2) || (this.step == 3 && this.form.state_envol == 1 && this.form.state_rdv == 1)">
                                    <div class="form-row">
                                    <div class="col-12 text-left mb-2">
                                        <!-- <span class="display-6 card-description  small">Très {{ this.form.identity_sex.trim() == "M" ? "cher  frère" : "chère soeur" }} </span> <br/> -->
                                        <span class="display-6 card-description  small">Bien-{{ this.form.identity_sex.trim() == "M" ? "aimé frère" : "aimée soeur" }} </span> <br/>
                                        <span class="display-5  text-white text-capitalize">{{ (this.form.identity_firstname ??
                                        this.form.identity_firstname) + ' ' + (this.form.identity_middlename ??
                                            this.form.identity_middlename)
                                        }}</span>
                                        <p class="card-description">
                                        <span>Encore une dernière étape et votre
                                            rendez-vous sera effectué.</span>
                                        </p>
                                    </div>
                                    </div>
                                    <div class="form-row d-none">
                                    <div class="col-12 text-left mb-2">
                                        <span  class="display-5  text-white">Proposer votre disponibilité</span>
                                        <p class="card-description">
                                        <span>Définissez-nous votre disponibilité.</span>
                                        </p>
                                    </div>
                                    </div>

                                    <div  class="form-group">
                                    <!-- <div v-if="this.dateDispos.length > 0" class="form-group"> -->
                                    <!-- <label>Date <span class="text-danger">*</span></label> -->

                                    <div class="schedule">
                                        <div class="container">
                                        <p class="t-1">Réservez votre rendez-vous selon les horaires ci-dessous  {{ focused }}</p>
                                        <p class="t-2">Période allant du 11 Juin au 21 Juin 2024</p>
                                        <div class="content" style="height:250px; overflow-y: auto;">
                                            <div class="item">
                                            <div class="i-head">
                                                <h3 class="t-1">Mardi</h3>
                                                <p class="t-3">11 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-10:00"  @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-11:00"  @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-12:00"  @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-13:00"  @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-14:00"  @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-11-15:00"  @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            <div class="item">
                                            <div class="i-head">
                                                <h3 class="t-1">Mercredi</h3>
                                                <p class="t-3">12 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-10:00" @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-11:00" @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-12:00" @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-13:00" @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-14:00" @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-12-15:00" @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            <div class="item">
                                            <div class="i-head">
                                                <h3 class="t-1">Vendredi</h3>
                                                <p class="t-3">14 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-10:00" @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-11:00" @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-12:00" @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-13:00" @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-14:00" @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-14-15:00" @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            <div class="item d-none">
                                            <div class="i-head">
                                                <h3 class="t-1">Samedi</h3>
                                                <p class="t-3">08 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-10:00" @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-11:00" @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-12:00" @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-13:00" @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-14:00" @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-08-15:00" @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            <div class="item">
                                            <div class="i-head">
                                                <h3 class="t-1">Mardi</h3>
                                                <p class="t-3">18 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-10:00" @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-11:00" @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-12:00" @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-13:00" @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-14:00" @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-18-15:00" @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            <div class="item">
                                            <div class="i-head">
                                                <h3 class="t-1">Mercredi</h3>
                                                <p class="t-3">19 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-10:00" @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-11:00" @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-12:00" @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-13:00" @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-14:00" @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-19-15:00" @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            <div class="item">
                                            <div class="i-head">
                                                <h3 class="t-1">Vendredi</h3>
                                                <p class="t-3">21 Juin 2024</p>
                                            </div>
                                            <div class="i-body">
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-09:00" @click.prevent="dateh">09:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-10:00" @click.prevent="dateh">10:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-11:00" @click.prevent="dateh">11:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-12:00" @click.prevent="dateh">12:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-13:00" @click.prevent="dateh">13:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-14:00" @click.prevent="dateh">14:00</button>
                                                <button class="button m-1" :style="'background-color:'+basebackground+' !important;'" id="2024-06-21-15:00" @click.prevent="dateh">15:00</button>
                                            </div>
                                            </div>
                                            
                                        </div>
                                        </div>
                                    </div>

                                    </div>


                                    <div v-if="this.dateDispos.length > 0" class="form-group d-none">
                                    <label>Date <span class="text-danger">*</span></label>
                                        <input v-if="this.dateDispos.length > 0"  type="date" class="form-control p_input"  :min="this.dateDispos[0] .horaire_date" :max="this.dateDispos[this.dateDispos.length-1] .horaire_date"  v-model="this.form.dateselectrdv" @change.prevent="verifDispo">
                                    </div>

                                    <div v-if="this.horaireTimesDispoDate.length > 0 && this.TimesCibles.length > 0" class="form-group d-none">
                                    <label>Heure disponible <span class="text-danger">*</span></label>
                                    <select  class="form-control p_input">
                                        <option value="">Choisir une tranche d'heure</option>
                                        <option v-for="(times, index) in TimesCibles" :key="index" :value="times.id" @click.prevent="verifDispoTimeCibleDate(times.id)">De {{
                                        times.time_cible_start.toString().substring(0,5)}} à  {{
                                        times.time_cible_end.toString().substring(0,5) }}</option>
                                    </select>
                                    </div>
                                    <p v-if="this.form.dateselectrdv !=null && this.form.dateselectrdv!='' && this.horaireTimesDispoDate.length == 0" class="card-description  d-none">
                                    <span>Désolé. Nous n'avons plus de dsponibilité pour cette date.</span>
                                    </p>
                                    <!-- 
                                    <div v-if="this.horaireTimesDispoDate.length > 0" class="form-group">
                                    <label>Heure disponible <span class="text-danger">*</span></label>
                                    <select v-model="this.form.timerdvselect" class="form-control p_input">
                                        <option v-for="(times, index) in horaireTimesDispoDate" :key="index" :value="times.id">{{
                                        index+ 1 }} : {{
                                        times.time_cible.time_cible_start.toString().substring(0,5)}} -  {{
                                        times.time_cible.time_cible_end.toString().substring(0,5) }}</option>
                                    </select>
                                    </div> -->

                                    <div class="text-center align-items-center">
                                    <button v-if="!this.lottieloading && this.form.date_probably_rdv !=null && this.form.time_probably_rdv !=null" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="to_store_contact">Soumettre <i class="mdi mdi-arrow-right pt-4"></i></button>

                                    <button v-if="!this.lottieloading && this.form.dateselectrdv !=null && this.form.dateselectrdv!=''" type="submit" class="btn btn-primary btn-block enter-btn"
                                        @click.prevent="to_access_step_four">Soumettre <i class="mdi mdi-arrow-right pt-4"></i></button>
                                    <lottie-player v-if="this.lottieloading" class="text-center"
                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                        background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                    </div>
                                </form>
                
                                <div v-if="this.step == 1 || this.step == 2 || this.step == 3" class="row justify-content-center my-2">
                                <div class="col-12 text-right">
                                    <button type="button" :class="'btn btn-rounded btn-icon ' + (this.step == 1 ? ' bg-text-phila' : '')" @click.prevent="step_position(1)"> 1
                                    </button>
                                    <button type="button" :class="'btn btn-rounded btn-icon ' + (this.step == 2 ? ' bg-text-phila' : '')" @click.prevent="step_position(2)"> 2
                                    </button>
                                    <button type="button" v-if="this.form.state_envol == 1 || this.form.state_envol == 2" :class="'btn btn-rounded btn-icon ' + (this.step == 3 ? ' bg-text-phila' : '')" @click.prevent="step_position(3)"> 
                                    3
                                    </button>
                                </div>
                                </div>
                
                                <div v-if="this.step == 4" class="row justify-content-center">
                                <div class="col-12 text-center mb-4">
                                    <span v-if="this.returnResult !=3" class="display-5  text-white text-capitalize">Félicitation !!!</span>
                
                                    <lottie-player class="text-center mt-3"
                                    :src="this.$store.state.app_url_img_localhost + (this.returnResult !=3 ? '/assets/lotties/success/success2.json':'/assets/lotties/loading/load1.json')"
                                    background="transparent" speed="1" style="width: 100%; height: 140px;" loop autoplay></lottie-player>
                                    
                                    <span v-if="this.returnResult !=3 " class="display-6 card-description  small">Très {{ this.form.identity_sex.trim() == "M" ? "cher  frère" : "chère soeur" }} </span> <br/>
                                    <span v-if="this.returnResult !=3" class="display-5  text-white text-capitalize">{{ (this.form.identity_firstname ??
                                    this.form.identity_firstname) + ' ' + (this.form.identity_middlename ?? this.form.identity_middlename)
                                    }}</span>
                
                                    <p class="card-description text-white" v-if="this.returnResult !=3 && this.returnResult !=13">
                                    <span v-if="this.form.state_envol ==1 ||this.form.state_envol ==3">Votre fiche vient d'etre enregistré avec succès.</span><br/>
                                    <span  v-if="this.form.state_envol ==2">Votre rendez-vous vient d'etre pris avec succès.</span><br/>
                                    <span>Vous recevrez une confirmation sous peu.</span> 
                                    </p>
                                    <p class="card-description text-danger" v-if="this.returnResult==3 && this.returnResult !=13">
                                    <span>L'utilisateur existe déjà.</span><br/>
                                    </p>
                
                                    <p class="card-description text-success mt-3" v-if="this.returnResult ==13">
                                    <span>L'utilisateur est rajouté dans la rubrique <br/>
                                        "Donner ma vie à Jésus-Christ"</span>
                                    </p>
                                    <p class="card-description text-white">
                                    <span>Merci.</span>
                                    </p>
                                </div>
                                </div>
                
                            </div>
                        </div>
                        <div v-if="this.lottieloading" class="col-lg-4 col-12 my-5">
                            <lottie-player class="text-center"
                            :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load1.json'"
                            background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                        </div>
                        <div class="col-lg-4 col-12 my-5">
                            <div v-if="!this.lottieloading" class="row justify-content-center align-items-center">
                                <div class="col-12 text-center my-5"></div>
                                <div class="col-6 text-center  my-5">
                                    <a href="/news-member" style="background: rgb(8, 57, 41); padding: 60px; border-radius: 50%; font-size: 1.4rem;margin-top: -5px;">
                                        <span class="text-success">
                                            {{ this.nouveaux.filter((ident) => {return ident.infos !=null || ident.for_the.toString().includes(3);}).length }}
                                        </span>
                                    </a>
                                    <br>
                                    <small class="text-xs" style="font-size: 0.9rem;">
                                        {{ this.nouveaux.filter((ident) => {return ident.infos !=null || ident.for_the.toString().includes(3);}).length > 1 ? "NVX" : "NV" }}
                                    </small>
                                </div>
                                <div class="col-6 text-center  my-5">
                                    <a href="/rdvs-v1" style="background: rgb(8, 57, 41); padding: 60px; border-radius: 50%; font-size: 1.4rem;margin-top: -5px;">
                                        <span class="text-success px-1">
                                            {{ this.tickets.filter((rdv) => rdv.date_probably_rdv != null).length }}
                                        </span>
                                    </a>
                                    <br>
                                    <small class="text-xs" style="font-size: 0.9rem;">RDVS</small>
                                </div>
                            </div>
                            <div class="row justify-content-center align-items-center">
                                <div class="col-lg-6 text-center  my-5">
                                    <a href="/news-member" class="btn btn-dark btn-block enter-btn">
                                        Fermer
                                    </a>
                                </div>
                            </div>
                        </div>
                       
                    <!-- </div> -->
                    </div>
                </div>
            </div>
        </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";
  
  // import { VueDatePicker } from '@mathieustan/vue-datepicker';
  // import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
  
  export default {
    // components : {
    //   VueDatePicker,
    // },
    name: 'LoadPage',
    data() {
      return {
        user: null,
        show:false,
        demandeRdv: null,
        nouveaux: [],
        tickets: [],
        trancheAges: [],
        subjectRdvs: [],
        dateDispos: [],
        horaireTimes: [],
        horaireTimesDispoDate: [],
        horaireTimesDispoDateSelect: null,
        TimesCibles: [],
        wishArray: [],
        OneSubject: null,
        requesting: false,
        reque: new Date(),
        basebackground:"#ffffffde",
        form: {
          identity_middlename: null,
          identity_firstname: null,
          identity_lastname: null,
          identity_phone_test: null,
          identity_phone_number_one: null,
          telephone_format: null,
          tranche_age_id: 1,
          // tranche_age_id: null,
          subject_id: 1,
          // subject_id: null,
          identity_email: null,
          demand_describe: "Description Test",
          identity_sex: "M",
          dateselectrdv: null,
          timerdvselect: null,
          date_probably_rdv: false,
          time_probably_rdv: false,
          state_envol: 0,
          
          // add property
          marital_status: null,
          profession: null,
          contact: null, 
          birth_date: null, 
          for_the: null,
          state_rdv: null,
          details_knowledge: null,
          details_pray_state: null,
          details_pray_name: null,
          wish: null,
          need_pray: null,
          new_person_church: null,
          other_marital_status:false,
          other_profession_status:false,
          other_knowledge_status:false,
          other_pray_status:false,
  
        },
        step: 1,
        stepRdvTest: 0,
        returnResult: null,
        checkForm: false,
        lottieloading: false
      };
    },
    setup() {
      const toast = useToast();
      return { toast }
    },
    mounted() {
        require('../../public/assets/css/style2.css');
        this.lottieloading = true;
        this.all_tranches_ages();
        this.all_subjectRdvs();
        this.all_calendrier_rdvs();
        this.all_nouveauxs();
        this.all_tickets();
     
        setInterval(() => {
            this.all_tranches_ages();
            this.all_subjectRdvs();
            this.all_calendrier_rdvs();
            this.all_nouveauxs();
            this.all_tickets();
        }, 120000);
  
    },

    beforeUnmount() {
        // const cssPath = '../../assets/css/style2.css';
        const styleLink = document.createElement('link');
        styleLink.rel = 'stylesheet';
        styleLink.href = '../../assets/css/style2.css';

        // Ajoutez l'élément <link> à l'en-tête du document
        document.head.appendChild(styleLink);

        // Supprimez l'élément <link> après un certain délai (par exemple, 5 secondes)
        setTimeout(() => {
        document.head.removeChild(styleLink);
        console.log('Feuille de style CSS supprimée avec succès sssssss');
        },1); // 5000 millisecondes = 5 secondes
    },
    methods: {
        async all_nouveauxs() {
            await axios.get(this.$store.state.app_url_dev + "new_member").then(({ data }) => {
                if (data.status == 1) {
                    this.nouveaux = data.data;
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });
        },

        async all_tickets() {
            await axios.get(this.$store.state.app_url_dev + "demand_rdvs_m1").then(({ data }) => {
                if (data.status == 1) {
                    this.tickets = data.data;
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
                this.lottieloading = false;
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
                this.lottieloading = false;
            });
        },

        dateh(event){
            var btt  = document.getElementsByClassName('button');
    
            for (let index = 0; index < btt.length; index++) {
            btt[index].style['background-color'] = '#ffffffde';
            }
            event.target.style.backgroundColor ="#d58206";
            this.form.date_probably_rdv =event.target.id.substring(0,10).toString();
            this.form.time_probably_rdv= event.target.id.substring(11,16);
        },
    
        toggleStatusMarital(val){
            if(val == 0){
            this.form.other_marital_status = false;
            }else{
            this.form.other_marital_status = true;
            this.form.marital_status  = null;
            }
        },
      
        step_rdv(){
            this.lottieloading  = true;

            if (this.form.marital_status != null && this.form.marital_status.trim() != "" 
                && this.form.profession != null && this.form.profession.trim() != "" 
                && this.form.details_knowledge != null && this.form.details_knowledge.trim() != "" 
                && this.form.details_pray_state != null && this.form.details_pray_state.toString().trim() != ""){
                    setTimeout(() => {
                    if(this.wishArray.includes("Rencontrer un pasteur")){
                        this.stepRdvTest = 1;
                        this.step=3;
                        this.form.state_rdv = 1;
                    }
                    this.lottieloading  = false;
                    }, 3000);
            }else{
                this.testStep3();
            }

        },

        testStep3(){
            if ((this.form.marital_status == null || this.form.marital_status.trim() == "")
                && (this.form.profession == null || this.form.profession.trim() == "") &&
                (this.form.details_knowledge == null || this.form.details_knowledge.trim() == "") &&
                (this.form.details_pray_state == null || this.form.details_pray_state.trim() == "")) {
                    this.toast.error("Veuillez compléter les champs manquants", {
                    timeout: 5000
                });
            }else if (this.form.profession == null || this.form.profession.trim() == "") {
                this.toast.error("Veuillez compléter votre occupation", {
                    timeout: 5000
                });
            }else if (this.form.marital_status == null || this.form.marital_status.trim() == "") {
                this.toast.error("Veuillez compléter l'état civil", {
                timeout: 5000
            });
            }else if (this.form.details_knowledge == null || this.form.details_knowledge.trim() == "") {
                this.toast.error("Veuillez compléter le canal de connaisance de Phila", {
                timeout: 5000
            });
            }else if (this.form.details_pray_state == null || this.form.details_pray_state.trim() == "") {
                this.toast.error("Veuillez compléter l'info sur l'église", {
                timeout: 5000
            });
            }else {
                this.toast.error("Veuillez compléter les champs manquants", {
                    timeout: 5000
                });
            }
        },
    
        toggleStatusProfession(val){
            if(val == 0){
            this.form.other_profession_status = false;
            }else{
            this.form.other_profession_status = true;
            this.form.profession  = null;
            }
        },
    
        toggleStatusKnowledge(val){
            if(val == 0){
            this.form.other_knowledge_status = false;
            }else{
            this.form.other_knowledge_status = true;
            this.form.details_knowledge  = null;
            }
        },
    
        toggleStatusPray(val){
            if(val == 0){
            this.form.other_pray_status = false;
            this.form.details_pray_state  = 0;  // sans eglise d'attache
            }else{
            this.form.other_pray_status = true;
            this.form.details_pray_state  = 1; // avec eglise d'attache
            this.form.details_pray_name  = null; 
            }
        },
    
        togglestaterdv(value){
            this.form.state_envol  = value;
        },
    
        async all_tranches_ages() {
            await axios.get(this.$store.state.app_url_dev + "tranche_ages").then(({ data }) => {
            if (data.status == 1) {
                this.trancheAges = data.data;
            } else {
                this.toast.error(data.message, {
                timeout: 6000
                });
            }
            }).catch(function (error) {
            console.log(error);
            // this.trancheAges = [];
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
        },
  
        async  all_calendrier_rdvs() {
        await  axios.get(this.$store.state.app_url_dev + "horaire_times_free").then(({ data }) => {
            if (data.status == 1) {
                this.horaireTimes = data.data;
                this.dateDispos = data.jrsDatesUnique;
                this.all_timecibles();
            } else {
                this.toast.error(data.message, {
                timeout: 6000
                });
            }
            }).catch(function (error) {
            console.log(error);
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
        },
  
        async all_timecibles() {
            await axios.get(this.$store.state.app_url_dev + "time_cibles").then(({ data }) => {
            if (data.status == 1) {
                this.TimesCibles = data.data;
            } else {
                this.toast.error(data.message, {
                timeout: 6000
                });
            }
            }).catch(function (error) {
            console.log(error);
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
        },
    
        async all_subjectRdvs() {
            await axios.get(this.$store.state.app_url_dev + "subjects").then(({ data }) => {
            if (data.status == 1) {
                this.subjectRdvs = data.data;
            } else {
                this.toast.error(data.message, {
                timeout: 6000
                });
            }
            }).catch(function (error) {
            console.log(error);
            // this.subjectRdvs = [];
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
        },
    
        selectOneSubject(subject_id) {
            if (subject_id != null && subject_id.toString().trim() != "" && this.subjectRdvs.length > 0) {
            var selectSubject = this.subjectRdvs.find((subject) => subject.id == subject_id);
            if (selectSubject != null) {
                this.OneSubject = selectSubject;
                this.form.demand_describe = selectSubject.subject_describe
            } else {
                this.OneSubject = null;
            }
            } else {
            this.OneSubject = null;
            }
        },
    
        access_step_two() {
            if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" 
                && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" 
                && this.form.identity_sex != null && this.form.identity_sex.trim() != "" 
                && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != ""
                && this.form.birth_date != null && this.form.birth_date.toString().trim() != "") {
                // && this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "") {
                this.lottieloading = true;
                // if (this.Online()) {
                setTimeout(() => {
                this.step = 2;
                this.lottieloading = false
                }, 3000);
                // } else {
                //     setTimeout(() => {
                //     this.lottieloading = false
                //   }, 2500);
                // } 
            } else {
                this.checkForm = true;
                if ((this.form.identity_middlename == null || this.form.identity_middlename.trim() == "")
                && (this.form.identity_firstname == null || this.form.identity_firstname.trim() == "") &&
                (this.form.birth_date == null || this.form.birth_date.trim() == "")) {
                    this.toast.error("Veuillez compléter les champs manquants", {
                    timeout: 5000
                });
                }else if (this.form.identity_firstname == null || this.form.identity_firstname.trim() == "") {
                this.toast.error("Veuillez compléter le prénom", {
                    timeout: 5000
                });
                }else if (this.form.identity_middlename == null || this.form.identity_middlename.trim() == "") {
                    this.toast.error("Veuillez compléter le nom", {
                    timeout: 5000
                });
                }else if (this.form.birth_date == null || this.form.birth_date.trim() == "") {
                    this.toast.error("Veuillez compléter la date de naissance", {
                    timeout: 5000
                });
                }else {
                this.toast.error("Veuillez compléter les champs manquants", {
                    timeout: 5000
                });
                }
            }
        },

        to_access_step_three() {
        // if (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "") {
        if (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "" && this.form.state_envol != null && this.form.state_envol.toString().trim() != "") {
            this.lottieloading = true;
            // if (this.Online()) {
            setTimeout(() => {
            this.step = 3;
            this.lottieloading = false
            }, 3000);
            // } else {
            //     setTimeout(() => {
            //     this.lottieloading = false
            //   }, 2500);
            // } 
        } else {
            this.checkForm = true;

            if(this.form.identity_phone_number_one == null || this.form.identity_phone_number_one.toString().trim() == ""){
            this.toast.error("Veuillez compléter le numéro de téléphone", {
                timeout: 5000
            });
            }else if(this.form.state_envol == null || this.form.state_envol.toString().trim() == ""){
            this.toast.error("Veuillez selectionner la nature dsouhaité avec Phila-Cité d'exaucement", {
                timeout: 5000
            });
            }

            // this.toast.error("Veuillez compléter les champs manquants", {
            //   timeout: 5000
            // });
        }
        },

        to_access_step_four() {
        // if (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "" && this.form.demand_describe != null && this.form.demand_describe.trim() != "") {
        if (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "") {
            this.lottieloading = true;
            // if (this.Online()) {
            this.RegisterDemandUser();
            // } else {
            //     setTimeout(() => {
            //     this.lottieloading = false
            //   }, 2500);
            // } 
        } else {
            this.checkForm = true;
            this.toast.error("Veuillez compléter les champs manquants", {
            timeout: 5000
            });
        }
        },
    
        step_position(value) {
            if (value == 1) {
            if (this.form.identity_firstname.trim() != "" && this.form.identity_firstname != null) {
                this.step = 1;
            }
            } else if (value == 2) {
            if (this.form.identity_firstname.trim() != "" && this.form.identity_firstname != null && this.form.tranche_age_id != "" && this.form.tranche_age_id != null  && this.form.identity_middlename.trim() != "" && this.form.identity_middlename != null) {
                this.step = 2;
            }
            }else if (value == 3) {
            if (this.form.identity_phone_number_one.trim() != "" && this.form.identity_phone_number_one != null && this.form.subject_id != "" && this.form.subject_id != null) {
                this.step = 3;
            }
            }
        },
    
        wishMethodArray(value) {
            if(this.wishArray.length == 0) {
                this.wishArray.push(value);
                this.form.wish = value;
            }else if(this.wishArray.length > 0){
            var dataExist = this.wishArray.includes(value);
    
            if(!dataExist){
                this.wishArray.push(value);
                this.form.wish = this.wishArray.join(';');
            }else{
                this.wishArray = this.wishArray.filter((data) => data !== value);
                this.form.wish = this.wishArray.join(';');
            }
            }
        },
  
  
        verifDispo() {
            this.horaireTimesDispoDate = [];
            if (this.form.dateselectrdv !=null && this.form.dateselectrdv !="" && this.horaireTimes.length > 0 ) {
            var search = this.form.dateselectrdv;
                this.horaireTimesDispoDate = this.horaireTimes.filter(function (date) {
                return date.horaire_room_pastoral.horaire.horaire_date == search;
                })
            console.log("tjrs test 1");
            console.log(this.horaireTimesDispoDate);
            }
        },
    
        verifDispoTimeCibleDate(timer_id) {
            if (timer_id !=null && timer_id !="" && this.horaireTimesDispoDate.length > 0) {
            var selectData = this.horaireTimesDispoDate.filter(function (tim) {
                return tim.time_cible.id == timer_id;
            });
    
            if (selectData.length > 0) {
                var DataH =  this.shuffleVueArray(selectData);
                if (DataH !=null) {
                this.form.timerdvselect = DataH.id;
                }
            }
            }
        },
    
        shuffleVueArray(array) {
            const j = Math.floor(Math.random(1, array.length - 1));
            return  array[j];
        },
    
        shuffleVueArray_2(array) {
        var i = array.length;
            const j = Math.floor(Math.random() * (i + 1));
        return array[j];
        },
    
    
        async RegisterDemandUser() {
            // if (this.Online()) {
            this.requesting = true;
            if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
    
            // if (this.getValidNumber(this.form.identity_phone_number_one) ) {
            if (this.form.identity_middlename.trim().length >= 3 && this.form.identity_middlename.trim().length <= 16 && this.form.identity_firstname.trim().length >= 3 && this.form.identity_firstname.trim().length <= 16) {
                this.form.identity_phone_number_one = this.getNumberFormat(this.form.identity_phone_number_one);
                this.form.telephone_format = this.getNumberFormat(this.form.identity_phone_number_one);
                this.form.identity_middlename = this.gb_capitalisingFormat(this.form.identity_middlename.trim());
                this.form.identity_firstname = this.gb_capitalisingFormat(this.form.identity_firstname.trim());
    
                await axios.post(this.$store.state.app_url_dev + "st_demand_rdv_client", this.form).then(({ data }) => {
                if (data.status == 1) {
    
                    setTimeout(() => {
                    this.step = 4;
                    this.lottieloading = false;
                    // this.refreshForm();
                    }, 4000);
                    // a remettre
                    setTimeout(() => {
    
                    var formRdv = {
                        demand_id: data.data.id,
                        timerdvselect: this.form.timerdvselect,
                        "rdv_note": null
                    }
    
                    var message = "";
    
                    // axios.post(this.$store.state.app_url_dev + "st_rdv", formRdv).then(({ data }) => {
                    axios.post(this.$store.state.app_url_dev + "st_rdv_v2", formRdv).then(({ data }) => {
                        if (data.status == 1) {
                        var test22 = data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date;
                        var test = new Date(data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date).getDay();
    
                        // this.toast.success("Rendez-vous réservé avec succès !!!", {
                        //   timeout: 2000
                        // });
                        // console.log(data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date);
                        // message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr " : "Mme ") + (this.form.identity_firstname.trim()) + ".\nVotre réservation est pris pour " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date.substring(0, 10) + ", exactement " + this.bg_format_date(data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date, "MM,DD YYYY") + "; dans " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_designation + "(" + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_localisation_details + "), à " + data.data.horaire_room_pastoral_time.time_cible.time_cible_start.substring(0, 5);
                        message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "M. " : "Mme ") + (this.form.identity_firstname.trim()) + ".\n Votre réservation est prise pour le " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.horaire.horaire_date.substring(0, 10) + ", dans " + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_designation + "(" + data.data.horaire_room_pastoral_time.horaire_room_pastoral.room.room_localisation_details + "), à " + data.data.horaire_room_pastoral_time.time_cible.time_cible_start.substring(0, 5);
                        console.log(message + "----- " + test22 + "----- " + test);
    
                        let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);
                        axios.get(urlgetsms).then(() => { });
    
                        } else {
                        message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr  " : "Mme ") + (this.form.identity_firstname.trim()) + "\n .Réservation faite avec succès. L'horaire vous sera renseigné très bientot.";
    
                        let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);
                        axios.get(urlgetsms).then(() => { });
    
                        }
                    }).catch(function (error) {
                        console.log(error);
                        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                        timeout: 2000
                        });
                    });
    
                
    
                    this.$store.state.user_auth = data;
                    this.$store.state.user_store = data;
                    setTimeout(() => {
                        this.refreshForm();
                        this.step = 1;
                        this.lottieloading = false;
                        window.location.reload();
                        // window.location.href="https://ce.church";
                    }, 15001);
                    }, 4500);
    
    
                } else {
                    this.toast.error(data.message, {
                    timeout: 6000
                    });
    
                    setTimeout(() => {
                    this.refreshForm();
                    this.step = 1;
                    this.lottieloading = false;
                    }, 6001);
                }
                }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
                });
    
    
                //sms
                // await axios.post("https://www.inscription.schoolbac.com", this.form).then(({ data }) => {
                //   this.requesting = false;
                //   if (data.error == "false") {
                //     this.user = data;
                //     this.toast.success("Votre compte vient d'etre crée avec success !!!");
                //     var message = "Bonjour " + this.form.identity_middlename + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                //     setTimeout(() => {
                //       let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.telephone_format;
    
                //       axios.get(urlgetsms).then(() => { });
                //       this.$store.state.user_auth = data;
                //       this.$store.state.user_store = data;
                //       this.refreshForm();
                //       this.$router.push({ name: 'Confirm' });
                //     }, 4500);
    
                //   } else {
                //     this.toast.error(data.message, {
                //       timeout: 6000
                //     });
                //   }
                // }).catch(function (error) {
                //   console.log(error);
                //   this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                //     timeout: 2000
                //   });
                // });
    
    
    
    
    
            } else {
                if (this.form.identity_middlename.trim().length < 3 || this.form.identity_firstname.trim().length < 3) {
                this.toast.info("Le nom ou le prénom ne doit pas etre inférieur à 3 caractères !!!", {
                    timeout: 6000
                });
                } else {
                // this.messageErrors = "";
                this.toast.info("Le nom ou le prénom ne doit pas dépasser 16 caractères !!!", {
                    timeout: 6000
                });
                }
            }
            // }
    
            } else {
            this.toast.error("Veuillez completer les champs manquants !!!", {
                timeout: 6000
            });
            }
            // }
        },
    
    
        async verifElementsStep() {
            // // if (this.Online()) {
            this.requesting = true;
            
            if (this.form.state_envol != null && this.form.state_envol != "" && ((this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "") || (this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != ""))) {
            this.lottieloading = true;
            
            this.form.identity_phone_number_one = this.getNumberFormat9(this.form.identity_phone_number_one);
    
            await axios.post(this.$store.state.app_url_dev + "vf_number_email", this.form).then(({ data }) => {
            if (data.status == 1) {
    
                if(data.data.length == 0) {
                setTimeout(() => {
                    this.lottieloading = false;
                    this.step = 1;
                }, 3001);
                }else if(data.data.length > 0){
                setTimeout(() => {
                    if(this.form.state_envol == 1 || this.form.state_envol == 3){
    
                    this.toast.info("L'utilisateur existe déjà !!!", {
                        timeout: 8000
                    });
    
                    setTimeout(() => {
                        // window.location.href="https://ce.church";
                        window.location.reload();
                    }, 8005);
                    }else if(this.form.state_envol == 2){
                    
                    this.step = 3;
                    this.lottieloading = false;
    
                    this.form.identity_middlename  = data.data[0].identity_middlename;
                    this.form.identity_firstname  = data.data[0].identity_firstname;
                    this.form.identity_lastname  = data.data[0].identity_lastname;
                    this.form.identity_phone_number_one  = data.data[0].identity_phone_number_one;
                    this.form.tranche_age_id  = data.data[0].tranche_age_id;
                    this.form.identity_email  = data.data[0].identity_email;
                    this.form.identity_sex  = data.data[0].identity_sex;
                    }
                }, 4001);
                }
    
            } else {
                this.toast.error(data.message, {
                timeout: 6000
                });
                
            }
            }).catch(function (error) {
            console.log(error);
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
    
            } else {
            this.toast.error("Veuillez completer les champs manquants !!!", {
                timeout: 6000
            });
            }
            // // }
        },
    
        to_store_contact() {
            // // if (this.Online()) {
            this.requesting = true;
            if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.identity_phone_number_one != null && this.form.identity_phone_number_one.toString().trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
            
            if (this.form.identity_middlename.trim().length >= 3 && this.form.identity_middlename.trim().length <= 16 && this.form.identity_firstname.trim().length >= 3 && this.form.identity_firstname.trim().length <= 16) {
                this.form.identity_phone_number_one = this.getNumberFormat(this.form.identity_phone_number_one);
                this.form.telephone_format = this.getNumberFormat(this.form.identity_phone_number_one);
                this.form.identity_middlename = this.gb_capitalisingFormat(this.form.identity_middlename.trim());
                this.form.identity_firstname = this.gb_capitalisingFormat(this.form.identity_firstname.trim());
                this.form.contact = this.form.contact !=null && this.form.contact.toString().trim() !="" ? this.getNumberFormat(this.form.contact.trim()): null;
                
                this.form.identity_phone_test = this.getNumberFormat9(this.form.identity_phone_number_one);
    
                if(this.form.state_envol == 3){
                this.form.for_the  = 3;
                this.storeContactSpecifique(this.form);
                }else if(this.form.state_envol == 1){
    
                if(this.form.marital_status !=null && this.form.marital_status.toString().trim() !=""
                    && this.form.profession !=null && this.form.profession.toString().trim() !=""
                    && this.form.details_knowledge !=null && this.form.details_knowledge.toString().trim() !=""
                    && this.form.details_pray_state !=null
                    && this.form.wish !=null && this.form.wish.toString().trim() !=""){
                    this.form.for_the  = 1;
                    this.storeContactSpecifique(this.form);
                }else{
                    this.toast.info("Veuillez compléter les champs manquants !!!", {
                    timeout: 6000
                    });
                }
                }else if(this.form.state_envol == 2){
    
                if(this.form.subject_id !=null && this.form.subject_id.toString().trim() !=""
                    && this.form.time_probably_rdv !=null && this.form.time_probably_rdv.toString().trim() !=""
                    && this.form.date_probably_rdv !=null && this.form.date_probably_rdv.toString().trim() !=""){
                    this.form.for_the  = 2;
                    this.form.state_rdv  = 1;
                    this.storeContactSpecifique(this.form);
                }
                }
    
    
                // Last axios action
    
                // await axios.post(this.$store.state.app_url_dev + "st_demand_rdv_client_v1", this.form).then(({ data }) => {
                //   if (data.status == 1) {
    
                //     this.toast.success("Enregistremt éffectué avec succès  !!!", {
                //       timeout: 5000
                //     });
                //     this.step = 4;
    
                //     var message = "";
                //     if(this.form.state_envol == 2){
                //       message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "M. " : "Mme ") + (this.form.identity_firstname.trim()) + ".\n Votre réservation est prise avec succès" ;
        
                //       let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);
                //       axios.get(urlgetsms).then(() => { });
                //     }else{
                //       message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "M. " : "Mme ") + (this.form.identity_firstname.trim()) + ".\n Votre fiche est enregistrée avec succès ";
        
                //       let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);
                //       axios.get(urlgetsms).then(() => { });
                //     }
    
                //     // message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr  " : "Mme ") + (this.form.identity_firstname.trim()) + "\n .Réservation faite avec succès. L'horaire vous sera renseigné très bientot.";
    
                //     // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);
                //     // axios.get(urlgetsms).then(() => { });
    
                //     setTimeout(() => {
                //       this.lottieloading = false;
                //       this.refreshForm();
                //       this.step = 1;
                //       this.lottieloading = false;
                //       window.location.reload(1000);
                //     }, 6000);
    
                //   } else {
                //     this.toast.error(data.message, {
                //       timeout: 6000
                //     });
    
                //     setTimeout(() => {
                //       this.refreshForm();
                //       this.step = 1;
                //       this.lottieloading = false;
                //     }, 6001);
                //   }
                // }).catch(function (error) {
                //   console.log(error);
                //   this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                //     timeout: 2000
                //   });
                // });
    
                // Last axios action
    
            } else {
                if (this.form.identity_middlename.trim().length < 3 || this.form.identity_firstname.trim().length < 3) {
                this.toast.info("Le nom ou le prénom ne doit pas etre inférieur à 3 caractères !!!", {
                    timeout: 6000
                });
                } else {
                // this.messageErrors = "";
                this.toast.info("Le nom ou le prénom ne doit pas dépasser 16 caractères !!!", {
                    timeout: 6000
                });
                }
            }
    
            } else {
            this.toast.error("Veuillez completer les champs manquants !!!", {
                timeout: 6000
            });
            }
            // // }
        },
    
        async storeContactSpecifique(requetData) {
            await axios.post(this.$store.state.app_url_dev + "st_demand_rdv_client_v1", requetData).then(({ data }) => {
            this.returnResult  = data.status;
            if (data.status == 1) {
    
                if(this.form.state_envol == 2){
                requetData.demand_id  = data.demand.id;
                this.storeRdv(requetData);
                }
    
                this.toast.success("Enregistremt éffectué avec succès  !!!", {
                timeout: 8000
                });
                this.step = 4;
    
    
                var message = "";
                if(requetData.state_envol == 2){
                message = "Bonjour " + (requetData.identity_sex.trim() == "M" ? "frère " : "soeur ") + (requetData.identity_firstname.trim()) + ".\n Votre réservation est prise avec succès" ;
    
                let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(requetData.identity_phone_number_one);
                axios.get(urlgetsms).then(() => { });
                }else{
                message = "Bonjour " + (requetData.identity_sex.trim() == "M" ? "frère " : "soeur ") + (requetData.identity_firstname.trim()) + ".\n Votre fiche est enregistrée avec succès ";
    
                let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(requetData.identity_phone_number_one);
                axios.get(urlgetsms).then(() => { });
                }
    
                // message = "Bonjour " + (this.form.identity_sex.trim() == "M" ? "Mr  " : "Mme ") + (this.form.identity_firstname.trim()) + "\n .Réservation faite avec succès. L'horaire vous sera renseigné très bientot.";
    
                // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.identity_phone_number_one);
                // axios.get(urlgetsms).then(() => { });
    
                setTimeout(() => {
                this.lottieloading = false;
                window.location.reload();
                // window.location.href="https://ce.church";
                this.lottieloading = false;
                this.refreshForm();
                }, 9000);
    
            } else {
                this.step = 4;
                if(data.status !=13){
                this.toast.error(data.message, {
                    timeout: 6000
                });
                }else{
                this.toast.success("Enregistremt éffectué avec succès  !!!", {
                    timeout: 8000
                });
                }
    
                setTimeout(() => {
                window.location.reload();
                // window.location.href="https://ce.church";
                this.lottieloading = false;
                this.refreshForm();
                }, 6001);
            }
            }).catch(function (error) {
            console.log(error);
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
        },
  
        refreshForm() {
            this.form.identity_middlename = null,
            this.form.identity_firstname = null,
            this.form.identity_lastname = null,
            this.form.subject_id = null,
            this.form.identity_sex = "M",
            this.form.identity_phone_number_one = null,
            this.form.telephone_format = null,
            this.form.identity_email = null,
            this.form.date_probably_rdv = null,
            this.form.time_probably_rdv = false,
            this.form.state_envol = 0,
            this.messageErrors = null,
            this.form.identity_phone_test =null;
    
    
            this.dateselectrdv = null,
            this.timerdvselect = null,
            this.date_probably_rdv = false,
            this.time_probably_rdv = false,
            this.marital_status = null,
            this.profession = null,
            this.for_the = null,
            this.state_rdv = null,
            this.details_knowledge = null,
            this.details_pray_state = null,
            this.details_pray_name = null,
            this.wish = null,
            this.need_pray = null,
            this.new_person_church = null,
            this.other_marital_status = false,
            this.other_profession_status = false,
            this.other_knowledge_status = false,
            this.other_pray_status = false,
    
            setTimeout(() => {
            this.step = 1;
            }, 7000);
        },
    
        async storeRdv(requetData) {
            await axios.post(this.$store.state.app_url_dev + "st_rdv", requetData).then(({ data }) => {
            if (data.status == 1) {
                console.log("reussi");
    
                setTimeout(() => {
                // window.location.reload(1000);
                window.location.reload();
                // window.location.href="https://ce.church";
                this.lottieloading = false;
                }, 6001);
            } else {
                setTimeout(() => {
                // window.location.reload(1000);
                window.location.reload();
                // window.location.href="https://ce.church";
                this.lottieloading = false;
                }, 6001);
            }
            }).catch(function (error) {
            console.log(error);
            // this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            //   timeout: 2000
            // });
            });
        },
    
    
        changesexe(typesexe) {
            if (this.isSexe) {
            this.isSexe = false;
            } else {
            this.isSexe = true;
            }
            this.form.identity_sex = typesexe;
        },
    
        IsEmail(email) {
            var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
            if (!regex.test(email)) {
            this.toast.error("Adresse email invalide", {
                timeout: 5000
            });
            return false;
            } else {
            return true;
            }
        },
    
        // verifi numero e telephone
        getValidNumber(value) {
            if (value.length == 10 || value.length == 12 || value.length == 13) {
    
            if (value.length == 10) {
                if (value.substring(0, 1) == '0') {
                if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
                    value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
                    value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }
    
                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
    
            } else if (value.length == 12) {
    
                if (value.substring(0, 3) == '243') {
    
                if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
                    value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
                    value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }
    
                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
            } else if (value.length == 13) {
    
                if (value.substring(0, 1) == '+') {
    
                if (value.substring(1, 4) == '243') {
                    if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                    value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                    value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                    return true;
                    } else {
                    this.toast.error("Numéro de téléphone invalide", {
                        timeout: 5000
                    });
                    return false;
                    }
    
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }
    
                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
            }
    
            } else {
            this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
            });
            return false;
            }
            return false;
        },
    
        getNumberFormat(value) {
            if (value.length > 0) {
            if (value.length == 13 && value.substring(0, 1) == '+') {
                return value;
            } else if (value.length == 12) {
                return '+' + value;
            } else if (value.length == 10) {
                return '+243' + value.substring(1, value.length);
            }else if (value.length == 9) {
                return '+243' + value;
            }
            }
        },
    
        getNumberFormat9(value) {
            if (value.length > 0) {
            return value.substring(value.length -9, value.length);
            }
        },
    
    
        getNumberFormatSms(value) {
            if (value.length > 0) {
            if (value.length == 13 && value.substring(0, 1) == '+') {
                return value.substring(1, value.length);
            } else if (value.length == 12) {
                return value;
            } else if (value.length == 10) {
                return '243' + value.substring(1, value.length);
            } else if (value.length == 9) {
                return '+243' + value;
            }
            }
        },
        
    }
  }
  </script>

<style>
    .btn-py2{
        padding: 0.5rem !important;
    }
</style>

  