import moment from 'moment';
import "moment/locale/fr";
import axios from 'axios';

export default {
    methods: {
        Online() {
            if (navigator.onLine) {
                return true;
            } else {
                this.toast.info("Vous n'etes pas connecté   !!!", {
                    timeout: 3000
                });
                return false;
            }
        },

        gb_loadReFreshUser() {
            if (this.$store.state.user_auth == null) {
                this.$store.state.user_auth = JSON.parse(localStorage.getItem("AUTHENTIFICATE_USER"));
                console.log(this.$store.state.user_auth);
                if (this.$store.state.user_auth != null) {
                    if (this.$store.state.user_auth.status.id != 1) {
                        this.toast.error("Vous n'etes pas autorisé à acceder a ce contenu ou à cette page !!!", {
                            timeout: 3000
                        });

                        setTimeout(() => {
                            this.$router.push({ name: 'StartPage' });
                        }, 3001);

                    }
                } else {
                    this.toast.error("Vous n'etes pas autorisé à acceder a ce contenu ou à cette page !!!", {
                        timeout: 3000
                    });

                    setTimeout(() => {
                        this.$router.push({ name: 'StartPage' });
                    }, 3001);
                }
            }
        },

        bg_format_Minutes(value) {
            if (value != null && value != "") {
                var date = moment(new Date(value))
                if (date) {
                    return parseInt(date.diff(new Date(), 'minutes'));
                }
            }
        },

        bg_format_date(value, format) {
            moment.locale("fr");
            if (value != null && value != "") {
                var date = moment(new Date(value))
                if (date) {
                    return moment(date, format).fromNow();
                    // return moment(value, "YYYYMMDDHHSS").fromNow();
                }
            }
        },

        bg_simple_date_format(value, format) {
            moment.locale("fr");
            if (value != null && value != "") {
                var date = moment(new Date(value))
                if (date) {
                    return moment(String(value)).format(format);
                }
            }
        },

        bg_Online() {
            if (navigator.onLine) {
                return true;
            } else {
                this.toast.info("Vous n'etes pas connecté   !!!", {
                    timeout: 3000
                });
                return false;
            }
        },

        bg_messagesError() {
            this.toast.info("Une Erreur est survenue, veuillez réessayer  !!!", {
                timeout: 4000
            });
        },

        bg_verif_extension_img(value) {
            var fich = value.split(".");
            var ext = fich[fich.length - 1].toLowerCase();
            if (ext != 'jpeg' && ext != 'jpg' && ext != 'png' && ext != 'gif' && ext != 'bmp' && ext != 'webp') {
                this.toast.info("Le format du fichier n'est pas autorisé. Veuillez selectionné un format image valide (jpeg, jpg, png,bmp)  !!!", {
                    timeout: 4000
                });
                return false;
            } else {
                return true;
            }
        },

        bg_verif_size_img(value) {
            if (value <= 7168000) {
                return true;
            } else {
                this.toast.info("La Taille de l'image est trop importante, le fichier doit avoir une taille inférieure ou égale à 7 Mo  !!!", {
                    timeout: 4000
                });
                return false;
            }
        },

        gb_deconnexionUser() {
            this.$store.dispatch('deconnexion');
            this.$store.state.user_auth = null;
            localStorage.setItem('AUTHENTIFICATE_USER', null);
            this.$router.push({ name: 'LoadPage' });
        },

        gb_modalMajTitre(valtitre, valtitrePaceholder, Datavalue = null, valIcon = null, valaction = null, infoupdate = null) {
            if (valtitre != null && valtitre.trim() != "") {
                this.$store.state.titleOptionMaj = valtitre;
                this.$store.state.titleOptionMajPlaceholder = valtitrePaceholder;
                this.$store.state.titleOptionMajValue = Datavalue;
                this.$store.state.titleOptionMajIcon = valIcon;
                this.$store.state.titleOptionMajAction = valaction;
                this.$store.state.MajParamDataUpdate = infoupdate;
                this.$emit('openModal');
            }
        },

        gb_modalMajVisibility(state) {
            if (state != null && state.trim() != "") {
                this.$store.state.visibilityElement = state;
                this.$emit('openModal');
            }
        },

        gb_loadstateElement(id, state, levelParent) {
            this.$store.state.visibilityElement = state;
            this.$store.state.visibilityElementId = id;
            this.$store.state.visibilityElementParent = levelParent;
        },


        gb_modalOnePublish(publish) {
            this.$store.state.OnePubishView = null;
            if (publish != null) {
                this.$store.state.OnePubishView = publish;
                this.$emit('openModal');
            }
        },

        gb_modalOnePublishVsPicture(publish) {
            this.$store.state.OnePubishView = null;
            if (publish != null) {
                this.$store.state.OnePubishView = publish;
                this.$store.state.OnePubishView.type = "image";
                this.$store.state.OnePubishView.contenu = publish.targetpicture;
                this.$emit('openModal');
            }
        },

        gb_capitalisingFormat(value) {
            return value.charAt(0).toUpperCase() +
                value.slice(1).toLowerCase();
        },

        async gb_loadBlockTypeInfosUser(infosDatas) {
            if (this.bg_Online) {

                var AllsInfosBlock = new Object();
                var results = new Object();
                var arrayss = [];

                if (infosDatas != null) {
                    // Object
                    var objtitleprim = new Object();
                    objtitleprim.statut = false;
                    objtitleprim.message = infosDatas.message;
                    objtitleprim.title = infosDatas.titre_principal;
                    objtitleprim.title_index = infosDatas.index_titre;
                    objtitleprim.title_second = [];
                    AllsInfosBlock = objtitleprim;

                    // Request
                    var postDataSecondary = new Object();
                    postDataSecondary.mailUser = this.$store.state.user_auth.mailUser;
                    postDataSecondary.codingUser = this.$store.state.user_auth.codingUser;
                    postDataSecondary.index_titre = infosDatas.index_titre;

                    if (infosDatas.index_titre != null && infosDatas.index_titre.trim() != "" && AllsInfosBlock != null) {

                        await axios.post("https://www.title.secondary.schoolbac.com", postDataSecondary)
                            .then(({ data }) => {
                                var dataSecondTitle = data;

                                if (dataSecondTitle.length > 0) {

                                    for (let g = 0; g < dataSecondTitle.length; g++) {
                                        // Object
                                        var objtitlesecond = new Object();
                                        objtitlesecond.statut = false;
                                        objtitlesecond.message = dataSecondTitle[g].message;
                                        objtitlesecond.titlesecond = dataSecondTitle[g].titre_secondaire;
                                        objtitlesecond.soustitle = dataSecondTitle[g].sous_titre;
                                        objtitlesecond.second_index_statut = dataSecondTitle[g].index_statut;
                                        objtitlesecond.elements = [];
                                        AllsInfosBlock.title_second.push(objtitlesecond);

                                        // Request
                                        var postObjBlockElem = new Object();
                                        postObjBlockElem.mailUser = this.$store.state.user_auth.mailUser;
                                        postObjBlockElem.codingUser = this.$store.state.user_auth.codingUser;
                                        postObjBlockElem.index_statut = dataSecondTitle[g].index_statut;

                                        if (dataSecondTitle[g].index_statut != null && dataSecondTitle[g].index_statut.trim() != "") {
                                            axios.post("https://www.element.ci.schoolbac.com", postObjBlockElem)
                                                .then(({ data }) => {
                                                    var dataE = data;
                                                    AllsInfosBlock.title_second[g].elements = dataE;
                                                })
                                                .catch((error) => {
                                                    this.errors = error;
                                                    this.bg_messagesError();
                                                    results.status = 500;
                                                });
                                        }

                                    }
                                    results.data = AllsInfosBlock;
                                    results.status = 200;
                                    this.$store.state.DatasInfoslockUser = results
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                this.bg_messagesError();
                                results.status = 500;
                                this.$store.state.DatasInfoslockUser = results
                            });
                    }

                    console.log(results.status === 200);
                    console.log(results.status == 200);
                    console.log(results.status);
                    if (results.status === 200 || results.status === 500) {
                        arrayss.push(results);
                        return results;
                    } else {
                        return results;
                    }
                }
                // return AllsInfosBlock;
            }
        },

        bg_isObjectEmpty(value) {
            // return (
            //     Object.prototype.toString.call(value) === '[object Object]' &&
            //     JSON.stringify(value) === '{}'
            // );
            return (
                value == null || // Check for null or undefined
                value.length === 0 || // Check for empty String (Bonus check for empty Array)
                (typeof value === 'object' && Object.keys(value).length === 0) // Check for empty Object or Array
            );
        },

        gb_notifications(status, messsage) {
            if (status == 1) {
                this.toast.success(messsage, {
                    timeout: 4000
                });
            } else if (status == 0) {
                this.toast.error(messsage, {
                    timeout: 4000
                });
            } else if (status == 2) {
                this.toast.info(messsage, {
                    timeout: 4000
                });
            }
        },

        bg_isEmail(email) {
            var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
            if (!regex.test(email)) {
                this.gb_notifications(0, "Adresse email invalide");
                return false;
            } else {
                return true;
            }
        },

        gb_shuffle(dataArray) {
            if (dataArray.length > 0) {
                let numbers = [...dataArray];
                let first,
                    second,
                    temp,
                    count = numbers.length;
                for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = numbers[first];
                    numbers[first] = numbers[second];
                    numbers[second] = temp;
                }
                return numbers;

            } else {
                var defList = [];
                return defList;
            }
        },

        gb_viewUserOrOtherUser(route) {
            // viewOtherUser
            if (route.path != null && (route.path == '/about-user' || route.path == '/about-user-education' || route.path == '/about-user-places' || route.path == '/about-user-infos' || route.path == '/videos-user' || route.path == '/friends-user' || route.path == '/pictures-user' || route.path == '/posters-user' || route.path == '/all-view-friends-demands' || route.path == '/all-view-friends-suggestions' || route.path == '/all-view-friends-birthday') && this.$store.state.user_auth != null && this.$store.state.user_auth_other == null && route.params.friend == null) {
                return false;
            } else {
                return true;
            }
        },

        gb_objectRequestTheBase() {
            if (!this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth != null) {
                return {
                    "mailUser": this.$store.state.user_auth.mailUser,
                    "codingUser": this.$store.state.user_auth.codingUser
                };

            } else if (this.gb_viewUserOrOtherUser(this.$route) && this.$store.state.user_auth_other != null && this.$store.state.user_auth_other.codingUserCamarade != null && this.$store.state.user_auth_other.codingUserCamarade.trim() != '' && this.$store.state.params == this.$route.params.friend) {

                return {
                    "mailUser": this.$store.state.user_auth_other.mailCamarade.trim(),
                    "codingUser": this.$store.state.user_auth_other.codingUserCamarade.trim()
                };
            }
        },
        
        gb_getNumberFormat(value) {
            if (value.length > 0) {
                if (value.length == 13 && value.substring(0, 1) == '+') {
                    return value;
                } else if (value.length == 12) {
                    return '+' + value;
                } else if (value.length == 10) {
                    return '+243' + value.substring(1, value.length);
                }else if (value.length == 9) {
                    return '+243' + value;
                }
            }
        },



        // 28/02/2023
        // Exemple generique tableau non redonnant
        // this.dataListImagePieceAddCreate = [...new Map(this.dataListImagePieceAddCreate.map(item => [item["picture"], item])).values()];
        // End 28/02/2023
    }
};