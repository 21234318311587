<template>
       <div class="wrapper">
            <composite v-if="this.$store.state.user_auth != null"> </composite>
            <main role="main" class="main-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <h2 class="h3 mb-0 page-title">Rendez-vous {{this.form.typeresult ==1 ? "traité":"en attente de traitement" }}</h2>
                            <div class="row align-items-center my-4 d-none">
                                <div class="col-md-6">
                                    <div id="chart-box">
                                        <div id="donutChartWidget"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row align-items-center my-2">
                                        <div class="col">
                                            <small class="text-uppercase small">En attente</small>
                                            <div class=" my-0 text-muted small ">+2%</div>
                                        </div>
                                        <div class="col-auto ">
                                            <strong>262</strong>
                                        </div>
                                        <div class="col-3 ">
                                            <div class="progress " style="height: 4px; ">
                                                <div class="progress-bar bg-warning " role="progressbar " style="width: 20% " aria-valuenow="20 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center my-2 ">
                                        <div class="col ">
                                            <small class="text-uppercase">Validé</small><br/>
                                            <span class="my-0 text-muted small ">+85%</span>
                                        </div>
                                        <div class="col-auto ">
                                            <strong class="my-0 ">1200</strong>
                                        </div>
                                        <div class="col-3 ">
                                            <div class="progress " style="height: 4px; ">
                                                <div class="progress-bar bg-success " role="progressbar " style="width: 85% " aria-valuenow="85 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center my-2 d-none ">
                                        <div class="col ">
                                            <strong>Processing</strong><br/>
                                            <span class="my-0 text-muted small ">+60%</span>
                                        </div>
                                        <div class="col-auto ">
                                            <strong>80</strong>
                                        </div>
                                        <div class="col-3 ">
                                            <div class="progress " style="height: 4px; ">
                                                <div class="progress-bar bg-primary " role="progressbar " style="width: 75% " aria-valuenow="75 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center my-2 ">
                                        <div class="col ">
                                            <small class="text-uppercase">Echec</small>
                                            <div class="my-0 text-muted small ">+6%</div>
                                        </div>
                                        <div class="col-auto ">
                                            <strong>26</strong>
                                        </div>
                                        <div class="col-3 ">
                                            <div class="progress " style="height: 4px; ">
                                                <div class="progress-bar bg-danger " role="progressbar " style="width: 30% " aria-valuenow="0 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col-md-12 -->
                            </div>
                            <!-- .row -->
                            <div class="row items-align-center my-4 d-none d-lg-flex ">
                                <div class="col-md ">
                                    <ul class="nav nav-pills justify-content-start ">
                                        <li class="nav-item ">
                                            <a class="nav-link active bg-transparent pr-2 pl-0 text-primary " href="# ">TOUT <span class="badge badge-pill bg-primary text-white ml-2 ">{{ this.allTickets.length }}</span></a>
                                        </li>
                                        <li class="nav-item ">
                                            <a class="nav-link text-white px-2  text-uppercase small" href="# " @click.prevent="toggleData(0)">Attente <span class="badge badge-pill bg-warning border text-white ml-2 ">{{ this.allTickets.filter((rdv) => rdv.rdv_describe == null).length  }}</span></a>
                                        </li>
                                        <li class="nav-item ">
                                            <a class="nav-link text-white px-2  text-uppercase small" href="# " @click.prevent="toggleData(1)">Traité <span class="badge badge-pill bg-success border text-white ml-2 " @click.prevent="toggleData(1)">{{ this.allTickets.filter((rdv)=> rdv.rdv_describe != null).length }}</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="col-md-auto ml-auto text-right ">
                                    <span class="small bg-white border py-1 px-2 rounded mr-2 ">
                        <a href="# " class="text-muted "><i class="fe fe-x mx-1 "></i></a>
                        <span class="text-muted ">Status : <strong>Pending</strong></span>
                                    </span>
                                    <span class="small bg-white border py-1 px-2 rounded mr-2 ">
                        <a href="# " class="text-muted "><i class="fe fe-x mx-1 "></i></a>
                        <span class="text-muted ">April 14, 2020 - May 13, 2020</span>
                                    </span>
                                    <button type="button " class="btn " data-toggle="modal " data-target=".modal-slide "><span class="fe fe-filter fe-16 text-muted "></span></button>
                                    <button type="button " class="btn "><span class="fe fe-refresh-ccw fe-16 text-muted "></span></button>
                                </div> -->
                            </div>
                            <div class="row ">
                                <div class="col-8">
                                    <div class="w-50">
                                        <form class="">
                                            <input v-model="this.formResearch.search" class="form-control form-control-md bg-white rounded-pill pl-5" type="search" placeholder="code ticket, nom du membre ou description du sujet" aria-label="Search">
                                            <p class="help-text mt-2 text-muted">
                                                <small class="text-white border border-bottom text-muted"> <i class="fe fe-info"></i> {{ this.getfilter_tickets.length }} {{ this.getfilter_tickets.length > 1 ? ' tickets trouvés'  : ' ticket trouvé'}}</small>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-md-10 ">
                                    <!-- table -->
                                    <table class="table table-borderless table-striped ">
                                        <thead>
                                            <tr class="text-uppercase">
                                                <!-- <th>Référence</th> -->
                                                <th>Sujet  {{this.form.typeresult ==1 ? "Traité":"En attente de traitement" }} </th>
                                                <!-- <th>Temps</th> -->
                                                <th v-if="this.form.typeresult ==0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="this.form.typeresult ==0">
                                            <tr v-for="(ticket, index) in getfilter_tickets.filter((tick)=>tick.rdv_describe == null)"  :key="index">
                                                <!-- <td class="text-muted small"  style="cursor:pointer;" data-toggle="modal" data-target=".modal-right">{{ ticket.demand_rdv.demand_coding }}</td> -->
                                                <td scope="col "  style="cursor:pointer;" data-toggle="modal" data-target=".modal-right" @click="one_rdv(ticket.id)">
                                                    <p>
                                                        {{ ticket.demand_rdv.demand_describe }} 
                                                        <!-- <small class="p-1" style="font-size:11px;">Ref. #{{ ticket.demand_rdv.demand_coding }}  </small>  -->
                                                    </p>
                                                        <span class="text-muted small text-uppercase my-2 mt-4 small font-weight-bold">
                                                            <i class="fe fe-user p-1 mr-1 border bg-white small" style="border-radius: 50%;"></i>
                                                              {{ (ticket.demand_rdv.identity.identity_firstname != null && ticket.demand_rdv.identity.identity_firstname.trim() != "" ? ticket.demand_rdv.identity.identity_firstname : "") + "  " + (ticket.demand_rdv.identity.identity_middlename != null && ticket.demand_rdv.identity.identity_middlename.trim() != "" ? ticket.demand_rdv.identity.identity_middlename : "") + "  " + (ticket.demand_rdv.identity.identity_lastname != null && ticket.demand_rdv.identity.identity_lastname.trim() != "" ? ticket.demand_rdv.identity.identity_lastname : "") }}</span> 
                                                              <br>
                                                        <i class="fe fe-time p-1 mr-2"></i>
                                                         <small> {{ this.gb_capitalisingFormat(this.bg_format_date(ticket.horaire_room_pastoral_time?.horaire_room_pastoral.horaire?.horaire_date,'DD-MM-YYYY')) }}  ({{ticket.horaire_room_pastoral_time?.horaire_room_pastoral.horaire?.horaire_date }}) , </small>
                                                            <i class="fe fe-clock p-1 mr-1"></i>
                                                         <small> {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_start }}  -   {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_end }}</small>
                                                    </td>
                                                    <!-- <td>
                                                        <i class="fe fe-clock"></i>
                                                        {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_start }}  -   {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_end }}
                                                        <span class="small text-muted ">Attente</span>
                                                    </td> -->
                                                    <!-- <td class="text-muted small ">{{ this.bg_format_date(ticket.createdAt, 'MM,DD YYYY') }}</td> -->
                                                    <td>
                                                        <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span class="text-muted sr-only">Action</span>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right ">
                                                            <a class="dropdown-item " href="./support-ticket-detail.html ">Détails</a>
                                                            <a class="dropdown-item " data-toggle="modal" data-target=".modal-right" href="# ">Ajournée</a>
                                                            <a class="dropdown-item " href="# ">Note</a>
                                                        </div>
                                                    </td>
                                            </tr>
                                            <!-- <tr>
                                                <td class="text-muted small ">2053</td>
                                                <td class="text-muted small ">BASILUA Ntemo</td>
                                                <th scope="col ">lorem eu metus. In lorem. Donec elementum, lorem ut</th>
                                                <td>
                                                    <span class="small text-muted ">Completed</span>
                                                    <div class="progress mt-2 " style="height: 3px; ">
                                                        <div class="progress-bar bg-success " role="progressbar " style="width: 100% " aria-valuenow="100 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                    </div>
                                                </td>
                                                <td class="text-muted small ">May 2, 2020</td>
                                                <td>
                                                    <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                      <span class="text-muted sr-only">Action</span>
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-right ">
                                                        <a class="dropdown-item" href="./support-ticket-detail.html ">Edit</a>
                                                        <a class="dropdown-item " href="# ">Remove</a>
                                                        <a class="dropdown-item " href="# ">Assign</a>
                                                    </div>
                                                </td>
                                            </tr>-->
                                        </tbody>
                                        <tbody v-if="this.form.typeresult ==1">
                                            <tr v-for="(ticket, index) in getfilter_tickets.filter((tick)=>tick.rdv_describe !=null)"  :key="index">
                                                <!-- <td class="text-muted small"  style="cursor:pointer;" data-toggle="modal" data-target=".modal-right">{{ ticket.demand_rdv.demand_coding }}</td> -->
                                                <td scope="col "  style="cursor:pointer;" data-toggle="modal" data-target=".modal-right" @click="one_rdv(ticket.id)">
                                                    <p class="d-none">
                                                        {{ ticket.demand_rdv.demand_describe }} 
                                                        <!-- <small class="p-1" style="font-size:11px;">Ref. #{{ ticket.demand_rdv.demand_coding }}  </small>  -->
                                                    </p>
                                                        <span class="text-muted small text-uppercase my-2 mt-4 small font-weight-bold">
                                                            <i class="fe fe-user p-1 mr-1 border bg-white small" style="border-radius: 50%;"></i>
                                                              {{ (ticket.demand_rdv.identity.identity_firstname != null && ticket.demand_rdv.identity.identity_firstname.trim() != "" ? ticket.demand_rdv.identity.identity_firstname : "") + "  " + (ticket.demand_rdv.identity.identity_middlename != null && ticket.demand_rdv.identity.identity_middlename.trim() != "" ? ticket.demand_rdv.identity.identity_middlename : "") + "  " + (ticket.demand_rdv.identity.identity_lastname != null && ticket.demand_rdv.identity.identity_lastname.trim() != "" ? ticket.demand_rdv.identity.identity_lastname : "") }}</span> 
                                                              <br>
                                                        <i class="fe fe-time p-1 mr-2"></i>
                                                         <small> {{ this.gb_capitalisingFormat(this.bg_format_date(ticket.horaire_room_pastoral_time?.horaire_room_pastoral.horaire?.horaire_date,'DD-MM-YYYY')) }}  ({{ticket.horaire_room_pastoral_time?.horaire_room_pastoral.horaire?.horaire_date }}) , </small>
                                                            <i class="fe fe-clock p-1 mr-1"></i>
                                                         <small> {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_start }}  -   {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_end }}</small>

                                                        <br/><i class="fe fe-clock text-success p-1 mr-1"></i>
                                                        Traité <small> {{ this.gb_capitalisingFormat(this.bg_format_date(ticket.updated_at,'DD-MM-YYYY')) }}  ({{ticket.updated_at.substring(0,10)}}) </small>

                                                    </td>
                                                    <!-- <td>
                                                        <i class="fe fe-clock"></i>
                                                        {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_start }}  -   {{ ticket.horaire_room_pastoral_time.time_cible.time_cible_end }}
                                                        <span class="small text-muted ">Attente</span>
                                                    </td> -->
                                                    <!-- <td class="text-muted small ">{{ this.bg_format_date(ticket.createdAt, 'MM,DD YYYY') }}</td> -->
                                                    
                                            </tr>
                                            <!-- <tr>
                                                <td class="text-muted small ">2053</td>
                                                <td class="text-muted small ">BASILUA Ntemo</td>
                                                <th scope="col ">lorem eu metus. In lorem. Donec elementum, lorem ut</th>
                                                <td>
                                                    <span class="small text-muted ">Completed</span>
                                                    <div class="progress mt-2 " style="height: 3px; ">
                                                        <div class="progress-bar bg-success " role="progressbar " style="width: 100% " aria-valuenow="100 " aria-valuemin="0 " aria-valuemax="100 "></div>
                                                    </div>
                                                </td>
                                                <td class="text-muted small ">May 2, 2020</td>
                                                <td>
                                                    <button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                      <span class="text-muted sr-only">Action</span>
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-right ">
                                                        <a class="dropdown-item" href="./support-ticket-detail.html ">Edit</a>
                                                        <a class="dropdown-item " href="# ">Remove</a>
                                                        <a class="dropdown-item " href="# ">Assign</a>
                                                    </div>
                                                </td>
                                            </tr>-->
                                        </tbody>
                                    </table>
                                    <nav aria-label="Table Paging " class="my-3 d-none ">
                                        <ul class="pagination justify-content-end mb-0 ">
                                            <li class="page-item "><a class="page-link " href="# ">Previous</a></li>
                                            <li class="page-item active "><a class="page-link " href="# ">1</a></li>
                                            <li class="page-item "><a class="page-link " href="# ">2</a></li>
                                            <li class="page-item "><a class="page-link " href="# ">3</a></li>
                                            <li class="page-item "><a class="page-link " href="# ">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>
                                <!-- .col -->
                            </div>
                            <!-- .row -->
                        </div>
                        <!-- .col-12 -->
                    </div>
                    <!-- .row -->
                </div>
                <!-- .container-fluid -->

                <div v-if="this.OneTicket !=null" class="modal fade modal-right modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                    <!-- <div class="modal-dialog modal-sm" role="document"> -->
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header d-none">
                                <h5 class="modal-title" id="defaultModalLabel">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                            </div>
                            <div class="modal-body">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                            <span class="color-text-phila small text-uppercase my-2 mt-4 small">
                                                <i class="fe fe-user p-1 mr-1 border bg-white small" style="border-radius: 50%;"></i>
                                                    {{ (OneTicket.demand_rdv.identity.identity_firstname != null && OneTicket.demand_rdv.identity.identity_firstname.trim() != "" ? OneTicket.demand_rdv.identity.identity_firstname : "") + "  " + (OneTicket.demand_rdv.identity.identity_middlename != null && OneTicket.demand_rdv.identity.identity_middlename.trim() != "" ? OneTicket.demand_rdv.identity.identity_middlename : "") + "  " + (OneTicket.demand_rdv.identity.identity_lastname != null && OneTicket.demand_rdv.identity.identity_lastname.trim() != "" ? OneTicket.demand_rdv.identity.identity_lastname : "") }}
                                            </span>,
                                            <i class="fe fe-clock p-1"></i>
                                            <small> {{ OneTicket.horaire_room_pastoral_time.time_cible.time_cible_start.substring(0,5) }}  -   {{ OneTicket.horaire_room_pastoral_time.time_cible.time_cible_end.substring(0, 5) }}</small>
                                            <p class="text-justify my-3" v-if="this.form.typeresult ==0">
                                                    {{ OneTicket.demand_rdv.demand_describe }}  
                                                    <br>
                                            </p>
                                            <span class="mt-4 d-none" style="font-size:12px;">REF. #{{ OneTicket.demand_rdv.demand_coding }}  </span> 
                                    </div>
                                </div>
                                <div class="row mt-5 justify-content-center">
                                    <div class="col-12" v-if="this.form.typeresult ==0">
                                        <div  v-if="!this.rdv_submit" class="form-group">
                                            <label for="inputEmail" class="sr-only">Note</label>
                                            <textarea  cols="30" rows="12" class="form-control form-control-lg" placeholder="Entrer une note" required="" autofocus="" v-model="this.form.rdv_describe"></textarea>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <lottie-player v-if="this.lottieloading" class="text-center"
                                                            :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                                            background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="this.form.typeresult ==1">
                                        <div class="form-group">
                                            <p class="text-justify"> {{ OneTicket.rdv_describe }}</p>
                                             <br/><i class="fe fe-clock text-success p-1 mr-1"></i>
                                                        Traité <small> {{ this.gb_capitalisingFormat(this.bg_format_date(OneTicket.updated_at,'DD-MM-YYYY')) }}  ({{OneTicket.updated_at.substring(0,10)}})</small>
                                        </div>
                                    </div>
                                    <div class="col-12 text-right">
                                        <button v-if="!this.rdv_submit && this.form.typeresult ==0" type="button" class="btn mb-2 btn-primary bg-text-phila px-4" @click.prevent="traitement_rdv">Traité</button>
                                        <button v-if="this.rdv_submit && this.form.typeresult ==0" type="button" class="btn mb-2 btn-primary  px-4 mx-2" data-toggle="modal" data-target=".calendrier-entretien" @click="solliciteEntretien">Emettre  une entretien</button>
                                        <!-- <button type="button" class="btn mb-2 btn-primary  px-4 mx-2" @click.prevent="sollicite_rdv_entretien">Emettre  une entretien</button> -->
                                        <button type="button" class="btn mb-2 btn-secondary mx-2" data-dismiss="modal" id="traiteFermer">Fermer</button>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade modal-notif modal-slide " tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true ">
                    <div class="modal-dialog modal-sm " role="document ">
                        <div class="modal-content ">
                            <div class="modal-header ">
                                <h5 class="modal-title " id="defaultModalLabel ">Notifications</h5>
                                <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
                      <span aria-hidden="true ">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body ">
                                <div class="list-group list-group-flush my-n3 ">
                                    <div class="list-group-item bg-transparent ">
                                        <div class="row align-items-center ">
                                            <div class="col-auto ">
                                                <span class="fe fe-box fe-24 "></span>
                                            </div>
                                            <div class="col ">
                                                <small><strong>Package has uploaded successfull</strong></small>
                                                <div class="my-0 text-muted small ">Package is zipped and uploaded</div>
                                                <small class="badge badge-pill badge-light text-muted ">1m ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item bg-transparent ">
                                        <div class="row align-items-center ">
                                            <div class="col-auto ">
                                                <span class="fe fe-download fe-24 "></span>
                                            </div>
                                            <div class="col ">
                                                <small><strong>Widgets are updated successfull</strong></small>
                                                <div class="my-0 text-muted small ">Just create new layout Index, form, table</div>
                                                <small class="badge badge-pill badge-light text-muted ">2m ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item bg-transparent ">
                                        <div class="row align-items-center ">
                                            <div class="col-auto ">
                                                <span class="fe fe-inbox fe-24 "></span>
                                            </div>
                                            <div class="col ">
                                                <small><strong>Notifications have been sent</strong></small>
                                                <div class="my-0 text-muted small ">Fusce dapibus, tellus ac cursus commodo</div>
                                                <small class="badge badge-pill badge-light text-muted ">30m ago</small>
                                            </div>
                                        </div>
                                        <!-- / .row -->
                                    </div>
                                    <div class="list-group-item bg-transparent ">
                                        <div class="row align-items-center ">
                                            <div class="col-auto ">
                                                <span class="fe fe-link fe-24 "></span>
                                            </div>
                                            <div class="col ">
                                                <small><strong>Link was attached to menu</strong></small>
                                                <div class="my-0 text-muted small ">New layout has been attached to the menu</div>
                                                <small class="badge badge-pill badge-light text-muted ">1h ago</small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- / .row -->
                                </div>
                                <!-- / .list-group -->
                            </div>
                            <!-- <div class="modal-footer ">
                                <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Clear All</button>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="modal fade modal-notif calendrier-entretien modal-slide " tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true ">
                    <div class="modal-dialog modal-md " role="document ">
                        <div class="modal-content ">
                            <div class="modal-header ">
                                <h5 class="modal-title " id="defaultModalLabel ">Notifications</h5>
                                <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
                      <span aria-hidden="true ">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body ">
                                <div class="list-group list-group-flush my-n3 ">
                                    <div class="list-group-item bg-transparent ">
                                        <div class="row align-items-center ">
                                            <div class="col-auto ">
                                                <h5 class="color-text-phila text-uppercase">
                                                     Programmer un entretien</h5>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="align-items-center">
                                                    <span class="fe fe-calendar fe-24  mr-2 small"></span>
                                                    <span class="text-whitefont-weight-bold">Mon calendrier.</span>
                                                </div>

                                                <lottie-player v-if="this.calendarFreesHorsRdvs.length == 0" class="text-center"
                                                            :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'"
                                                            background="transparent" speed="1" style="width: 100%; height: 90px;" loop autoplay></lottie-player>

                                                 <div class="accordion accordion-boxed" id="accordion2">
                                                        <select v-if="this.calendarFreesHorsRdvs.length > 0"  class="form-control p_input my-2">
                                                            <option v-for="(dat, index) in calendarFreesHorsRdvs"  :key="index" :value="dat.horaire_coding" @click.prevent="selectHoraireFreeTimeCible(dat.horaire_coding)">{{ dat.horaire_date.toString().substring(0,10) }}</option>
                                                        </select>

                                                        <select v-if="this.calendarFreesHorsRdvs.length > 0 && this.freeTimesCibleHoraireDateArray.length > 0"  class="form-control p_input">
                                                            <option v-for="(times, index) in freeTimesCibleHoraireDateArray"  :key="index" :value="times.horaire_room_past_tim_coding">{{ times.time_cible?.time_cible_start.toString().substring(0,5) }}  -  {{ times.time_cible?.time_cible_end.toString().substring(0,5) }}</option>
                                                        </select>



                                                    <div v-for="(dat, index) in calendarFreesHorsRdvs.slice(0,2)"  :key="index" class="card shadow d-none">
                                                        <div class="card-header" :id="index">
                                                            <a role="button" :href="'#collapse'+ dat.horaire_coding" data-toggle="collapse" :data-target="'#collapse' + dat.horaire_coding" aria-expanded="false" :aria-controls="'#collapse'+ dat.horaire_coding">
                                                            <strong>{{ dat.horaire_date.toString().substring(0,10) }}</strong>
                                                            </a>
                                                        </div>
                                                        <div :id="'#collapse' + dat.horaire_coding" class="collapse show" :aria-labelledby="index" data-parent="#accordion2">
                                                            <div class="card-body"> 
                                                                <!-- <div class="custom-control custom-radio" v-for="(timer, index) in timersCible"  :key="index">
                                                                    <input type="radio" class="custom-control-input" :id="timersCible.time_cible_coding" name="customRadio">
                                                                    <label class="custom-control-label" for="customCheck1">{{ timer.time_cible_start.toString().substring(0,5) }}  -  {{ timer.time_cible_end.toString().substring(0, 5)  }}</label>
                                                                </div> -->

                                                                <div class="custom-control custom-radio" v-for="(timer, index) in timersCible"  :key="index">

                                                                    <input  
                                                                    v-if="this.calendarFrees.find((free) => free?.horaire_room_pastoral?.horaire_id == dat.id && free?.time_cible.id == timer.id) !=null"
                                                                    type="radio" :id="dat.horaire_coding+ timer.time_cible_coding" :name="dat.horaire_coding" class="custom-control-input" @click.prevent=" sollicite_rdv_entretien_with_horaire(free?.horaire_room_pastoral?.horaire_id)">
                                                                    <input  
                                                                    v-if="this.calendarFrees.find((free) => free?.horaire_room_pastoral?.horaire_id == dat.id && free?.time_cible.id == timer.id)==null"
                                                                    type="radio" :id="dat.horaire_coding+ timer.time_cible_coding" :name="dat.horaire_coding" class="custom-control-input" disabled>


                                                                    <label class="custom-control-label" :for="dat.horaire_coding + timer.time_cible_coding">{{ timer.time_cible_start.toString().substring(0, 5) }}  -  {{ timer.time_cible_end.toString().substring(0, 5) }} </label>
                                                                </div>
                                                                <!-- <div class="custom-control custom-radio">
                                                                    <input type="radio" name="radioDisabled" id="customRadioDisabled2" class="custom-control-input" disabled>
                                                                    <label class="custom-control-label" for="customRadioDisabled2">Disabled this custom radio</label>
                                                                </div> -->
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    <div class="card shadow d-none">
                                                        <div class="card-header" id="headingdd">
                                                            <a role="button" href="#collapseOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <strong>Collapse boxed One</strong>
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingdd" data-parent="#accordion2">
                                                            <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  class="list-group list-group-flush my-n3  py-4">
                                                    <div class="list-group-item bg-transparent ">
                                                        <div class="row align-items-center ">
                                                            <div class="col ">
                                                                <button type="button" class="btn mb-2 btn-primary  px-4 mx-2"  data-toggle="modal" data-target=".modalStoreEntretienHorsBureau" >Programmé hors Bureau</button>
                                                                <!-- <button type="button" class="btn mb-2 btn-primary  px-4 mx-2" data-toggle="modal" data-target=".calendrier-entretien">Programmé hors Bureau</button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- / .list-group -->
                            </div>
                            <div class="modal-footer ">
                                <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Clear All</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade modal-shortcut modal-slide " tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true ">
                    <div class="modal-dialog " role="document ">
                        <div class="modal-content ">
                            <div class="modal-header ">
                                <h5 class="modal-title " id="defaultModalLabel ">Shortcuts</h5>
                                <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
                                        <span aria-hidden="true ">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body px-5 ">
                                <div class="row align-items-center ">
                                    <div class="col-6 text-center ">
                                        <div class="squircle bg-success justify-content-center ">
                                            <i class="fe fe-cpu fe-32 align-self-center text-white "></i>
                                        </div>
                                        <p>Control area</p>
                                    </div>
                                    <div class="col-6 text-center ">
                                        <div class="squircle bg-primary justify-content-center ">
                                            <i class="fe fe-activity fe-32 align-self-center text-white "></i>
                                        </div>
                                        <p>Activity</p>
                                    </div>
                                </div>
                                <div class="row align-items-center ">
                                    <div class="col-6 text-center ">
                                        <div class="squircle bg-primary justify-content-center ">
                                            <i class="fe fe-droplet fe-32 align-self-center text-white "></i>
                                        </div>
                                        <p>Droplet</p>
                                    </div>
                                    <div class="col-6 text-center ">
                                        <div class="squircle bg-primary justify-content-center ">
                                            <i class="fe fe-upload-cloud fe-32 align-self-center text-white "></i>
                                        </div>
                                        <p>Upload</p>
                                    </div>
                                </div>
                                <div class="row align-items-center ">
                                    <div class="col-6 text-center ">
                                        <div class="squircle bg-primary justify-content-center ">
                                            <i class="fe fe-users fe-32 align-self-center text-white "></i>
                                        </div>
                                        <p>Users</p>
                                    </div>
                                    <div class="col-6 text-center ">
                                        <div class="squircle bg-primary justify-content-center ">
                                            <i class="fe fe-settings fe-32 align-self-center text-white "></i>
                                        </div>
                                        <p>Settings</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="this.OneTicket != null" class="modal fade modal-right modal-slide modalStoreEntretienHorsBureau" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                        <!-- <div class="modal-dialog modal-sm" role="document"> -->
                        <div class="modal-dialog modal-md" role="document">
                            <div class="modal-content">
                                <div class="modal-header d-none">
                                    <h5 class="modal-title" id="defaultModalLabel">Modal title</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row justify-content-center">
                                         <div class="col-12 ">
                                                <h6 class="color-text-phila text-uppercase">
                                                    Formaliser un entretien hors bureau
                                                </h6>
                                            </div>
                                            <div class="col-12 mt-5">
                                                <div class="form-group">
                                                    <label for="inputEmail" class="sr-only">But Entretien</label>
                                                    <textarea  cols="30" rows="2" class="form-control form-control-lg" placeholder="Raison de l'entretien" required="" autofocus="" v-model="this.formEHB.ehb_raison"></textarea>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Date Entretien <span class="text-danger">*</span></label>
                                                        <input  required="required" type="date"
                                                        class="form-control p_input" v-model="this.formEHB.ehb_date">
                                                        <!-- <input v-model="this.form.identity_firstname" required="required" type="text"
                                                        :class="'form-control p_input  ' + (this.checkForm && (this.form.identity_firstname == null || this.form.identity_firstname.trim() == '') ? ' border-danger' : ' ')"
                                                        placeholder="Prénom"> -->
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Heure début Entretien <span class="text-danger">*</span></label>
                                                        <input  type="time"
                                                        class="form-control p_input"
                                                        required="required"   v-model="this.formEHB.ehb_time">
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="inputEmail" class="sr-only">Localisation</label>
                                                    <textarea  cols="30" rows="2" class="form-control form-control-lg" placeholder="Lieu et référence de l'entretien" required=""  v-model="this.formEHB.ehb_localisation"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 text-right">
                                                <button type="button" class="btn mb-2 btn-primary  px-4 mx-2" data-toggle="modal" data-target=".calendrier-entretien" @click.prevent="sollicite_rdv_entretien_ehb">Sauvegarder</button>
                                                <button type="button" class="btn mb-2 btn-secondary mx-2" data-dismiss="modal" id="traiteFermer">Fermer</button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </main>
        </div>
</template>

<script>

import Composite from './../layouts/Composite.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
    name: 'LoadPage',
    components: {
        Composite
    },

  data() {
    return {
      user: null,
        tickets: [],
        calendarFrees: [],
        calendarFreesHorsRdvs: [],
        timersCible: [],
       OneTicket:null,
        allTickets: [],
        HorsRdvsArray: [],
        freeTimesCibleHoraireDateArray: [],
      requesting: false,
      formEHB: {
        rdv_id: null,
        ehb_note: null,
        ehb_raison: null,
        ehb_localisation: null,
        ehb_date: null,
        ehb_time: null,
      },
      form: {
        rdv_describe: null,
        typeresult: 0,
      },
      rdv_submit:false,
      formResearch: {
        search: ""
      },
      step: 1,
      checkForm: false,
      lottieloading: false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

    mounted() {
         this.gb_loadReFreshUser();
        if (this.$store.state.user_auth != null && this.$store.state.user_auth.status.id == 1) {
            this.all_rdvs();
            this.all_timers_cible();
            setInterval(() => {
                this.all_rdvs();
            }, 60000);
        }
    },
  methods: {

    toggleData(val){
        console.log(val);
       this.form.typeresult=val;
    },

    async all_rdvs() {
        if (this.$store.state.user_auth !=null  && this.$store.state.user_auth.identity != null   && this.$store.state.user_auth.identity.pastor != null) {
            var formPastor = {
                   "pastor_id":  this.$store.state.user_auth.identity.pastor.id
               }
           await axios.post(this.$store.state.app_url_dev + "rdvs_pastoral", formPastor).then(({ data }) => {
               if (data.status == 1) {
                   this.allTickets = data.data;
               } else {
                   this.toast.error("Une érreur est survenue, veuillez réessayer  !!!", {
                       timeout: 6000
                   });
               }
           }).catch(function (error) {
               console.log(error);
           });
        }
      },

    async all_timers_cible() {
        if (this.$store.state.user_auth !=null  && this.$store.state.user_auth.identity != null   && this.$store.state.user_auth.identity.pastor != null) {
           await axios.get(this.$store.state.app_url_dev + "time_cibles").then(({ data }) => {
               if (data.status == 1) {
                   this.timersCible = data.data;
               } else {
                   this.toast.error(data.message, {
                       timeout: 6000
                   });
               }
           }).catch(function (error) {
               console.log(error);
               this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                   timeout: 2000
               });
           });
        }
      },

    selectHoraireFreeTimeCible(horaire_coding) {
        if (this.$store.state.user_auth !=null  && this.$store.state.user_auth.identity != null   && this.$store.state.user_auth.identity.pastor != null && this.calendarFreesHorsRdvs.length  > 0 ) {

      var  freeTimesCibleHoraireDate   = this.HorsRdvsArray.filter((free) => {
            return free?.horaire_room_pastoral?.horaire?.horaire_coding == horaire_coding;
        })
            this.freeTimesCibleHoraireDateArray = [];
            if (freeTimesCibleHoraireDate.length > 0) {
                this.freeTimesCibleHoraireDateArray = freeTimesCibleHoraireDate;
            }else{
                this.freeTimesCibleHoraireDateArray = [];
            }
         
        }
      },
    
    async all_calendrier_free_pastor() {

        if (this.$store.state.user_auth !=null  && this.$store.state.user_auth.identity != null   && this.$store.state.user_auth.identity.pastor != null) {
           await axios.get(this.$store.state.app_url_dev + "horaire_times_free").then(({ data }) => {
               if (data.status == 1) {
                   var frees = data.data;
                   if (frees.length > 0) {
                       this.calendarFrees = frees.filter((free)=>{
                           return free.horaire_room_pastoral.pastor_id == this.$store.state.user_auth.identity.pastor.id
                       }).sort((a, b) => {
                            // return b?.horaire_room_pastoral?.horaire?.horaire_date - a?.horaire_room_pastoral?.horaire?.horaire_date
                            return a?.horaire_room_pastoral?.horaire?.horaire_date.toString() >  b?.horaire_room_pastoral?.horaire?.horaire_date.toString()
                       });

                       // vérifie que c'est pas un mardi ou un mercreedi
                        // calendarFreesHorsRdvs
                       var HorsRdvs = this.calendarFrees.filter((free) => {
                            return   new Date(free?.horaire_room_pastoral?.horaire?.horaire_date).getDay()  != 2 &&  new Date(free?.horaire_room_pastoral?.horaire?.horaire_date).getDay()  != 3
                       })

                       if (HorsRdvs.length > 0) {
                           var calendarFreesHorsRdvs = [];
                           HorsRdvs.forEach(free => {
                                calendarFreesHorsRdvs.push(free?.horaire_room_pastoral?.horaire);
                            });
                            var jsonObject = calendarFreesHorsRdvs.map(JSON.stringify);
                            var uniqueSet = new Set(jsonObject);
                            var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                          var FreesHorsRdvs = uniqueArray.sort((a, b) => {
                                    // return a?.horaire_date - b?.horaire_date
                                    return a?.horaire_date.toString() > b?.horaire_date.toString()
                           });
                           this.calendarFreesHorsRdvs = FreesHorsRdvs.filter((free) => {
                               console.log("tchek");
                               console.log(this.OneTicket?.horaire_room_pastoral_time?.horaire_room_pastoral?.horaire?.horaire_coding);
                               console.log(free?.horaire_coding);
                           console.log(this.OneTicket?.horaire_room_pastoral_time?.horaire_room_pastoral?.horaire?.horaire_date.toString());
                                return   this.OneTicket?.horaire_room_pastoral_time?.horaire_room_pastoral?.horaire?.horaire_date.toString()  !=  free?.horaire_date.toString()
                        });

                                console.log("toujours ticket");
                           console.log(this.calendarFreesHorsRdvs);

                           console.log(this.OneTicket);
                           console.log(HorsRdvs);
                           console.log(this.OneTicket?.horaire_room_pastoral_time?.horaire_room_pastoral?.horaire?.horaire_date.toString());
                            this.HorsRdvsArray = this.calendarFreesHorsRdvs.filter((free) => {
                                return   this.OneTicket?.horaire_room_pastoral_time?.horaire_room_pastoral?.horaire?.horaire_date.toString() !=  free?.horaire_date.toString()
                        });
                       }

                            // lundi = 1, ......... samedi = 6 et  dimanche = 0
                            // var jrDate = new Date(dateAdd).getDay();
                            // console.log(jrDate);
                            // if (jrDate == 2 || jrDate == 3) {
                   }
               } else {
                   this.toast.error(data.message, {
                       timeout: 6000
                   });
               }
           }).catch(function (error) {
               console.log(error);
               this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                   timeout: 2000
               });
           });
        }
    },

    one_rdv(ticket_id) {
        if (this.$store.state.user_auth != null && ticket_id != null && ticket_id.toString().trim() != "" && this.allTickets.length > 0) {
            var ticket = this.allTickets.find((tick) => tick.id == ticket_id);
            if (ticket !=null) {
                this.OneTicket = ticket;
            } else {
                this.OneTicket = null;
            }
        }
    },

    async traitement_rdv() {
        if (this.OneTicket !=null && this.OneTicket.id.toString().trim() != null &&  this.OneTicket.id.toString().trim() != "" && this.form.rdv_describe !=null && this.form.rdv_describe.toString() !="") {
            var formUpdateRdv = {
                "id": this.OneTicket.id,
                "rdv_describe": this.form.rdv_describe
            }

            await axios.put(this.$store.state.app_url_dev + "rdv_note_pastor", formUpdateRdv).then(({ data }) => {
                if (data.status == 1) {
                       this.toast.success("Note sauvegardé avec succès !!!", {
                            timeout: 4000
                       });
                    // this.OneTicket = null;
                    this.rdv_submit = true;
                    this.refreshForm();
                    // document.getElementById('traiteFermer').click();
                    setTimeout(() => {
                        this.all_rdvs();
                    }, 4002);
                    
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });
        } else {
            if (this.OneTicket == null) {
                this.toast.info("Veuillez sélectionné le rendez-vous a traité  !!!", {
                    timeout: 2000
                });
            } else if (this.form.rdv_describe == null || this.form.rdv_describe.toString() == "")  {
                this.toast.info("Veuillez remplir une note  !!!", {
                    timeout: 2000
                });
            } else {
                this.toast.info("Veuillez réessayer plus tard", {
                    timeout: 2000
                });
            }
            this.refreshForm();
        }
      },
    
    async sollicite_rdv_entretien() {
        if (this.OneTicket !=null && this.OneTicket.id.toString().trim() != null &&  this.OneTicket.id.toString().trim() != "" ) {
            // var formEntretien = {
            //     "id": this.OneTicket.id,
            //     "rdv_describe": this.form.rdv_describe
            // }
            var formEntretien = {
                    "rdv_id": this.OneTicket.id,
                    "entretien_note": null,
                    "entretien_raison": "Besoin d'un entretien avec vous",
                    "pastor_id": this.$store.state.user_auth.identity.pastor.id
                }

            await axios.post(this.$store.state.app_url_dev + "st_entretien", formEntretien).then(({ data }) => {
                if (data.status == 1) {

                         var message = "Sollicitation d'un entretien  avec le Past."+ this.$store.state.user_auth.identity.identity_firstname +" "+ this.$store.state.user_auth.identity.identity_lastname;
                        setTimeout(() => {
                            let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila C.E &telephone=" + this.getNumberFormatSms(data.rdv.demand_rdv.identity.identity_phone_number_one);

                            axios.get(urlgetsms).then(() => { });
                            this.$store.state.user_auth = data;
                            this.$store.state.user_store = data;
                            setTimeout(() => {
                                this.refreshForm();
                                this.step = 1;
                                this.lottieloading = false;
                                this.all_pastors();
                            }, 15001);
                        }, 4500);

                       this.toast.success("Entretien spuis avec succès !!!", {
                            timeout: 4000
                       });
                    this.OneTicket = null;
                    this.refreshForm();
                    document.getElementById('traiteFermer').click();
                    setTimeout(() => {
                        this.all_rdvs();
                    }, 4002);
                    
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });
        } else {
            if (this.OneTicket == null) {
                this.toast.info("Veuillez sélectionné le rendez-vous a traité  !!!", {
                    timeout: 2000
                });
            } else if (this.form.rdv_describe == null || this.form.rdv_describe.toString() == "")  {
                this.toast.info("Veuillez remplir une note  !!!", {
                    timeout: 2000
                });
            } else {
                this.toast.info("Veuillez réessayer plus tard", {
                    timeout: 2000
                });
            }
            this.refreshForm();
        }
      },
    
    async sollicite_rdv_entretien_with_horaire(horaireAletoire_id) {
        if (this.OneTicket !=null && this.OneTicket.id.toString().trim() != null &&  this.OneTicket.id.toString().trim() != "" ) {
            var formEntretien = {
                    "rdv_id": this.OneTicket.id,
                    "entretien_note": null,
                    "entretien_raison": "Besoin d'un entretien avec vous",
                    horaireAletoire_id
                }

            await axios.post(this.$store.state.app_url_dev + "st_entretien_with_horaire", formEntretien).then(({ data }) => {
                if (data.status == 1) {

                         var message = "Sollicitation d'un entretien  avec le Past."+ this.$store.state.user_auth.identity.identity_firstname +" "+ this.$store.state.user_auth.identity.identity_lastname;
                        setTimeout(() => {
                            let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila C.E &telephone=" + this.getNumberFormatSms(data.rdv.demand_rdv.identity.identity_phone_number_one);

                            axios.get(urlgetsms).then(() => { });
                            this.$store.state.user_auth = data;
                            this.$store.state.user_store = data;
                            setTimeout(() => {
                                this.refreshForm();
                                this.step = 1;
                                this.lottieloading = false;
                                this.all_pastors();
                            }, 15001);
                        }, 4500);

                       this.toast.success("Entretien spuis avec succès !!!", {
                            timeout: 4000
                       });
                    this.OneTicket = null;
                    this.refreshForm();
                    document.getElementById('traiteFermer').click();
                    setTimeout(() => {
                        this.all_rdvs();
                    }, 4002);
                    
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });
        } else {
            if (this.OneTicket == null) {
                this.toast.info("Veuillez sélectionné le rendez-vous a traité  !!!", {
                    timeout: 2000
                });
            } else if (this.form.rdv_describe == null || this.form.rdv_describe.toString() == "")  {
                this.toast.info("Veuillez remplir une note  !!!", {
                    timeout: 2000
                });
            } else {
                this.toast.info("Veuillez réessayer plus tard", {
                    timeout: 2000
                });
            }
            this.refreshForm();
        }
    },

    async sollicite_rdv_entretien_ehb() {
    if (this.OneTicket != null && this.OneTicket.id.toString().trim() != null && this.OneTicket.id.toString().trim() != "") {
        var form_ehb = {
            "rdv_id": this.OneTicket.id,
            "ehb_raison": this.formEHB.ehb_raison,
            "ehb_localisation": this.formEHB.ehb_localisation,
            "ehb_date":  this.formEHB.ehb_date,
            "ehb_time": this.formEHB.ehb_time
        }

        await axios.post(this.$store.state.app_url_dev + "st_entretien_hors_bureau", form_ehb).then(({ data }) => {
            if (data.status == 1) {

                var message = "Sollicitation entretien  avec le Past." + this.$store.state.user_auth.identity.identity_firstname + " " + this.$store.state.user_auth.identity.identity_lastname + ". Date : "+ this.formEHB.ehb_date +",Lieu : "+this.formEHB.ehb_localisation +", Heure : " + this.formEHB.ehb_time;

                console.log(message);
                setTimeout(() => {
                    let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila C.E &telephone=" + this.getNumberFormatSms(data.rdv.demand_rdv.identity.identity_phone_number_one);

                    axios.get(urlgetsms).then(() => { });
                    this.$store.state.user_auth = data;
                    this.$store.state.user_store = data;
                    setTimeout(() => {
                        this.refreshForm();
                        this.step = 1;
                        this.lottieloading = false;
                        this.all_pastors();
                    }, 15001);
                }, 4500);

                this.toast.success("Entretien planifié avec succès !!!", {
                    timeout: 4000
                });
                this.OneTicket = null;
                this.refreshForm();
                document.getElementById('traiteFermer').click();
                setTimeout(() => {
                    this.all_rdvs();
                }, 4002);

            } else {
                this.toast.error(data.message, {
                    timeout: 6000
                });
            }
        }).catch(function (error) {
            console.log(error);
            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                timeout: 2000
            });
        });
    } else {
        if (this.OneTicket == null) {
            this.toast.info("Veuillez sélectionné le rendez-vous a traité  !!!", {
                timeout: 2000
            });
        } else if (this.form.rdv_describe == null || this.form.rdv_describe.toString() == "") {
            this.toast.info("Veuillez remplir une note  !!!", {
                timeout: 2000
            });
        } else {
            this.toast.info("Veuillez réessayer plus tard", {
                timeout: 2000
            });
        }
        this.refreshForm();
    }
    },

    getNumberFormatSms(value) {
        if (value.length > 0) {
            if (value.length == 13 && value.substring(0, 1) == '+') {
                return value.substring(1, value.length);
            } else if (value.length == 12) {
                return value;
            } else if (value.length == 10) {
                return '243' + value.substring(1, value.length);
            }
        }
    },

    refreshForm() {
      this.form.rdv_describe = null,
        this.messageErrors = null
    },

    solliciteEntretien() {
        this.all_calendrier_free_pastor();
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },
    },
  computed: {
        getfilter_tickets() {
            if(this.form.typeresult == 0){
                return this.allTickets.filter((ticket) => {
                        return ticket.rdv_describe == null &&  (ticket.rdv_coding
                            .toLowerCase()
                            .includes(this.formResearch.search.toLowerCase()) ||   ticket.demand_rdv.identity.identity_firstname
                            .toLowerCase()
                            .includes(this.formResearch.search.toLowerCase()) || ticket.demand_rdv.identity.identity_middlename
                                .toLowerCase()
                                .includes(this.formResearch.search.toLowerCase()) || ticket.demand_rdv.identity.identity_phone_number_one
                                    .toLowerCase()
                                    .includes(this.formResearch.search.toLowerCase())  || ticket.demand_rdv.demand_describe
                                        .toLowerCase()
                                        .includes(this.formResearch.search.toLowerCase()));
                });
            }else{
                return this.allTickets.filter((ticket) => {
                        return ticket.rdv_describe != null && (ticket.rdv_coding
                            .toLowerCase()
                            .includes(this.formResearch.search.toLowerCase()) ||   ticket.demand_rdv.identity.identity_firstname
                            .toLowerCase()
                            .includes(this.formResearch.search.toLowerCase()) || ticket.demand_rdv.identity.identity_middlename
                                .toLowerCase()
                                .includes(this.formResearch.search.toLowerCase()) || ticket.demand_rdv.identity.identity_phone_number_one
                                    .toLowerCase()
                                    .includes(this.formResearch.search.toLowerCase())  || ticket.demand_rdv.demand_describe
                                        .toLowerCase()
                                        .includes(this.formResearch.search.toLowerCase()));
                });
                
            }
        },
    },
}
</script>
