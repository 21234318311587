<template>
  <div class="global-div">
    <div class="wrapper">
      <div class="block-login">
        <div class="avatar">
          <img src="assets/images/12.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/15.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/14.webp" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/student.jpg" alt="">
        </div>
        <div class="avatar">
          <img src="assets/images/team.jpg" alt="">
        </div>
        <div class="logo-app">
          <router-link to="/">
            <img src="assets/images/logo-app.png" alt="">
          </router-link>
        </div>
        <div class="block-cube">
          <div class="cube cube-1">
            <i class="bx bx-book"></i>
          </div>
          <div class="cube cube-2">
            <i class="bx bxs-graduation"></i>
          </div>
          <div class="cube cube-3">
            <i class="bx bxs-envelope"></i>
          </div>
          <div class="cube cube-4">
            <i class="bx bx-music"></i>
          </div>
          <div class="cube cube-5">
            <i class="bx bx-book-open"></i>
          </div>
          <div class="cube cube-6">
            <i class="bx bx-mobile"></i>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-xl-4 col-xxl-4">
              <div class="card card-login">
                <div class="text-center">
                  <h2 class="title-lg">Inscription</h2>
                </div>
                <form @submit.prevent="RegisterUser">
                  <div v-if="messageErrors != null" class="text-center">
                    <p class="font-weight-bold text-warning small" style="font-weight: bold;">{{ messageErrors }}</p>
                  </div>
                  <div class="form-group row g-lg-3 g-3">
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.prenom" type="text" class="form-control" required="required"
                          placeholder="prénom">
                        <div class="fake-label">Prénom</div>
                        <i class="bx bx-user"></i>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.nom" type="text" class="form-control" required="required"
                          placeholder="nom">
                        <div class="fake-label">Nom</div>
                        <i class="bx bx-user"></i>
                      </div>
                    </div>
                    <div class="col-12" style="margin-bottom: -10px">
                      <label for="" style="color: #fff;font-size:14px;">Genre</label>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6">
                      <div class="block-input" :class="{ active: isSexe }" @click.prevent="changesexe('M')">
                        <input v-model="this.form.sexe" type="radio" id="input-radio-1" name="sexe" value="M">
                        <label for="input-radio-1">Homme</label>
                        <div class="circle-sm"></div>
                      </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6">
                      <div class="block-input" :class="{ active: !isSexe }" @click.prevent="changesexe('F')">
                        <input v-model="this.form.sexe" type="radio" id="input-radio-2" name="sexe" value="F">
                        <label for="input-radio-2">Femme</label>
                        <div class="circle-sm"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.email" type="email" class="form-control" required="required"
                          placeholder="email">
                        <div class="fake-label">Email</div>
                        <i class="bx bx-envelope"></i>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.telephone_format" type="text" class="form-control" required="required"
                          placeholder="téléphone" id="phoneCountry">
                        <div class="fake-label">Numéro de tél</div>
                        <i class="bx bx-phone"></i>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="col-focus">
                        <input v-model="this.form.password" :type="!this.type_input_password ? 'password':'text'"
                          class="form-control input-password" required="required" placeholder=" mot de passe">
                        <div class="fake-label">Mot de passe</div>
                        <i class="bx bx-lock"></i>
                        <div
                          :class="'block-show-password ' + (this.form.password !=null  && this.form.password.length > 0 ? 'show '  :' ')"
                          @click.prevent="change_type_input">
                          <i :class="'bx bx-hide' + (!this.type_input_password ? ' ' : ' bx-show ')"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <button v-if="!this.requesting" class="btn btn-primary">
                        <span>S'inscrire</span>
                        <span>S'inscrire</span>
                        <span>S'inscrire</span>
                      </button>
                      <p v-else class="paragraph text-center text-white">Veuillez patientez...</p>
                      <vue-tel-input></vue-tel-input>
                    </div>
                    <div class="col-12 text-center col-hidden">
                      <p>Vous avez compte ? <br>
                        <router-link to="/login">
                          Connectez-vous
                        </router-link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div class="text-center col-hidden-mobile">
                <p class="paragraph">Vous avez un compte ? <br>
                  <router-link to="/login">
                    Connectez-vous
                  </router-link>
                </p>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'RegisterPage',
  data() {
    return {
      user: null,
      messageErrors: null,
      form: {
        nom: null,
        prenom: null,
        telephone: null,
        telephone_format: null,
        email: null,
        sexe: "M",
        password: null,
      },
      isSexe: true,
      requesting: false,
      type_input_password: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },

  methods: {
    async RegisterUser() {
      if (this.Online()) {
        this.requesting = true;
        if (this.form.nom != null && this.form.prenom != null && this.form.sexe != null && this.form.email != null && this.form.telephone_format != null && this.form.password != null) {

          if (this.getValidNumber(this.form.telephone_format) && this.IsEmail(this.form.email)) {
            if (this.form.nom.trim().length >= 4 && this.form.nom.trim().length <= 16 && this.form.prenom.trim().length >= 4 && this.form.prenom.trim().length <= 16) {
              this.form.telephone = this.getNumberFormat(this.form.telephone_format);
              this.form.nom = this.gb_capitalisingFormat(this.form.nom.trim());
              this.form.prenom = this.gb_capitalisingFormat(this.form.prenom.trim());

              // var postRequest = {
              //   ""
              // }

              await axios.post("https://www.inscription.schoolbac.com", this.form).then(({ data }) => {
                this.requesting = false;
                if (data.error == "false") {
                  this.user = data;
                  this.toast.success("Votre compte vient d'etre crée avec success !!!");
                  var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                  setTimeout(() => {
                    let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.telephone;

                    axios.get(urlgetsms).then(() => { });
                    this.$store.state.user_auth = data;
                    this.$store.state.user_store = data;
                    this.$store.state.user_store_password = this.form.password;
                    this.refreshForm();
                    this.$router.push({ name: 'Confirm' });
                  }, 4500);

                } else {
                  this.toast.error(data.message, {
                    timeout: 6000
                  });
                }
              }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                  timeout: 2000
                });
              });

            } else {
              if (this.form.nom.trim().length < 4 || this.form.prenom.trim().length < 4) {
                this.toast.info("Le nom ou le prenom ne doit pas etre inférieur à 4 caractères !!!", {
                  timeout: 6000
                });
              } else {
                // this.messageErrors = "";
                this.toast.info("Le nom ou le prenom ne doit pas dépasser 16 caractères !!!", {
                  timeout: 6000
                });
              }
            }
          }

        } else {
          this.toast.error("Veuillez completer les champs manquants !!!", {
            timeout: 6000
          });
        }
      }
    },

    refreshForm() {
      this.form.nom = null,
        this.form.prenom = null,
        this.form.sexe = "M",
        this.form.telephone = null,
        this.form.password = null,
        this.form.email = null,
        this.messageErrors = null
    },

    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.sexe = typesexe;
    },

    change_type_input() {
      if (this.type_input_password == false) {
        this.type_input_password = true;
      } else {
        this.type_input_password = false;
      }
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>


