import Dashboard from './components/Dashboard.vue'
import Room from './components/Room.vue'
// import Calendar from './components/horaire/Calendar.vue'
import Calendar from './components/fd/PlanningPastorals.vue'
// import PlanningNotTraitement from './components/pastor/PlanningNotTraitement.vue'
import PlanningNotTraitement from './components/pastor/PlanningNotTraitement.vue'
import BagRdvsTransfert from './components/pastor/BagRdvsTransfert.vue'
import BagRdvsApprouve from './components/pastor/BagRdvsApprouve.vue'
import Tickets from './components/Tickets.vue'
import Rdvs from './components/Rdvs.vue'
import RdvModule from './components/RdvModule.vue'
import NewsMember from './components/NewsMember.vue'
import CreateMember from './components/CreateMember.vue'
import RdvGood from './components/RdvGood.vue'
import Pastors from './components/fd/Pastors.vue'
import SetiingsParams from './components/settings/settings.vue'
import Login from './components/Login.vue'
import VerifConnexion from './components/VerifConnexion.vue'
import ResetPassword from './components/ResetPassword.vue'
import ConfirmCompte from './components/ConfirmCreateCompte.vue'
import Register from './components/Register.vue'
import RegisterToComplete from './components/RegisterToComplete.vue'
import Index from './components/Index.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
    name: "StartPage",
    component: Index,
    path: '/'
}, {
    name: "Home",
    component: Dashboard,
    path: '/home'
}, {
    name: "Room",
    component: Room,
    path: '/room'
}, {
    name: "Pastors",
    component: Pastors,
    path: '/pastors'
},{
    name: "Settings",
    component: SetiingsParams,
    path: '/settings'
}, {
    name: "Tickets",
    component: Tickets,
    path: '/tickets'
}, {
    name: "rdvs",
    component: Rdvs,
    path: '/rdvs'
}, {
    name: "rdvs-v1",
    component: RdvModule,
    path: '/rdvs-v1'
},{
    name: "news-member",
    component: NewsMember,
    path: '/news-member'
},{
    name: "create-member",
    component: CreateMember,
    path: '/create-member'
},{
    name: "rdvsEnd",
    component: RdvGood,
    path: '/rdvs-end'
}, {
    name: "PlanningsPastor",
    component: PlanningNotTraitement,
    path: '/plannings'
}, {
    name: "BagRdvsApprouvePastor",
    component: BagRdvsApprouve,
    path: '/rdv-approuve'
}, {
    name: "BagRdvsTransfertPastor",
    component: BagRdvsTransfert,
    path: '/rdv-transfert'
},{
    name: "Login",
    component: Login,
    path: '/login'
}, {
    name: "Calendar",
    component: Calendar,
    path: '/calendar'
}, {
    name: "VerifConnexion",
    component: VerifConnexion,
    path: '/verif-connexion'
}, {
    name: "RestPassword",
    component: ResetPassword,
    path: '/reset-password'
}, {
    name: "Confirm",
    component: ConfirmCompte,
    path: '/confirm'
}, {
    name: "Register",
    component: Register,
    path: '/register'
}, {
    name: "RegisterToComplete",
    component: RegisterToComplete,
    path: '/register_complete'
}, ];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
// ViewOtherUser