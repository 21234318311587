<template>
  <div>
    <nav class="topnav navbar navbar-light">
        <button type="button" class="navbar-toggler text-muted mt-2 p-0 mr-3 collapseSidebar">
      <i class="fe fe-menu navbar-toggler-icon"></i>
    </button>
        <form class="form-inline mr-auto searchform text-muted d-none">
            <input class="form-control mr-sm-2 bg-transparent border-0 pl-4 text-muted" type="search" placeholder="Type something..." aria-label="Search">
        </form>
        <ul class="nav">
            <!-- <li class="nav-item">
                 <router-link to="/rdvs" class="nav-link text-muted my-2" href="#" id="modeSwitcher" data-mode="dark">
                <span>RDV</span>
              </router-link>
          </li> -->
            <li class="nav-item">
                <!-- <a class="nav-link text-muted my-2" href="./#" data-toggle="modal" data-target=".modal-shortcut">
                    <span class="fe fe-grid fe-16"></span>
                </a> -->
                    <router-link to="/rdvs" class="nav-link text-muted my-2 d-none">
                    <span class="fe fe-grid fe-16"></span>
                  </router-link>
            </li>
            <li class="nav-item nav-notif d-none">
                <a class="nav-link text-muted my-2" href="./#" data-toggle="modal" data-target=".modal-notif">
                    <span class="fe fe-bell fe-16"></span>
                    <span class="dot dot-md bg-success"></span>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-muted pr-0" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="avatar avatar-sm mt-2">
                        <img v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.identity != null && this.$store.state.user_auth.identity.identity_sex =='M'" src="assets/images/avatars/homme.svg" alt="..." class="avatar-img rounded-circle">
                        <img v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.identity != null && this.$store.state.user_auth.identity.identity_sex !='M'" src="assets/images/avatars/femme.png" alt="..." class="avatar-img rounded-circle">
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item d-none" href="profile.html">Profile</a>
                    <!-- <a class="dropdown-item" href="#">Settings</a>
                  <a class="dropdown-item" href="#">Activities</a> -->

                    <a class="dropdown-item d-none" href="profile-settings.html">Settings</a>
                    <!-- <a class="dropdown-item" href="profile-security.html">Security</a>
                    <a class="dropdown-item" href="profile-notification.html">Notifications</a> -->
                    <router-link  to="/"  class="dropdown-item" @click.prevent="deconnexionUser">
                            Deconnexion
                    </router-link>
                </div>
            </li>
        </ul>
    </nav>
    <aside class="sidebar-left border-right bg-white shadow" id="leftSidebar" data-simplebar>
        <a href="#" class="btn collapseSidebar toggle-btn d-lg-none text-muted ml-2 mt-3" data-toggle="toggle">
            <i class="fe fe-x"><span class="sr-only"></span></i>
        </a>
        <nav class="vertnav navbar navbar-light">
            <!-- nav bar -->
            <div class="w-100 mb-4 d-flex">
                <a class="navbar-brand mx-auto mt-2 flex-fill text-center d-flex align-items-center" href="#">
                    <!-- <svg version="1.1" id="logo" class="navbar-brand-img brand-sm" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 120 120" xml:space="preserve"> -->
                    <img src="assets/images/cropped-Phila-1-1-192x192.png" alt="" class="navbar-brand-img brand-sm w-40">
                    <span class="small mx-3">ce.phila - FD </span>
                    <!-- <svg>
                    <g>
                        <polygon class="st0" points="78,105 15,105 24,87 87,87 	" />
                        <polygon class="st0" points="96,69 33,69 42,51 105,51 	" />
                        <polygon class="st0" points="78,33 15,33 24,15 87,15 	" />
                    </g>
                  </svg> -->
                </a>
            </div>
            <ul class="navbar-nav flex-fill w-100 mb-2 d-none">
                <li class="nav-item dropdown">
                    <!-- <a href="#dashboard" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link"> -->
                    <a href="./dashboard.html" class="nav-link">
                        <i class="fe fe-home fe-16"></i>
                        <span class="ml-3 item-text">Dashboard</span>
                        <!-- <span class="sr-only">(current)</span> -->
                    </a>
                    <!-- <ul class="collapse list-unstyled pl-4 w-100" id="dashboard">
                        <li class="nav-item active">
                            <a class="nav-link pl-3" href="./index.html"><span class="ml-1 item-text">Default</span></a>
                        </li>
                    </ul> -->
                </li>
            </ul>
            <p class="text-muted nav-heading mt-4 mb-1  d-none">
                <span>Apps</span>
            </p>
            <ul class="navbar-nav flex-fill w-100 mb-2">

                <li  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.profiler != null  && (this.$store.state.user_auth.profiler.profiler_designation == 'GESTIONNAIRE' || this.$store.state.user_auth.profiler.profiler_designation == 'FRONT-DESK' || this.$store.state.user_auth.profiler.profiler_designation == 'ADMIN')" class="nav-item dropdown">
                    <router-link  to="/news-member"  class="nav-link">
                        <i class="fe fe-home fe-16"></i>
                        <span class="ml-3">Acceuil</span>
                    </router-link>
                    <a href="#support" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">
                        <i class="fe fe-compass fe-16"></i>
                        <span class="ml-3 item-text">Rdvs</span>
                    </a>
                    <ul class="collapse list-unstyled pl-4 w-100" id="support">
                        <router-link  to="/rdvs-end"  class="nav-link pl-3">
                            <span class="ml-1">Traité</span>
                        </router-link>
                        <router-link  to="/rdvs"  class="nav-link pl-3">
                            <span class="ml-1">Non Traité</span>
                        </router-link>
                        <!-- <a class="nav-link pl-3" href="./support-ticket-detail.html"><span class="ml-1">Ticket Detail</span></a>
                        <a class="nav-link pl-3" href="./support-faqs.html"><span class="ml-1">FAQs</span></a> -->
                    </ul>
                </li>
                <li v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.profiler != null  && this.$store.state.user_auth.profiler.profiler_designation == 'PASTEUR'" class="nav-item dropdown">
                    <router-link  to="/plannings"  class="nav-link">
                        <i class="fe fe-home fe-16"></i>
                        <span class="ml-3">Acceuil</span>
                    </router-link>
                    <a href="#support" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">
                        <i class="fe fe-compass fe-16"></i>
                        <!-- <span class="ml-3 item-text">My Planning</span> -->
                        <span class="ml-3 item-text">Rendez-vous</span>
                    </a>
                    <ul class="collapse list-unstyled pl-4 w-100" id="support">
                        <!-- <a class="nav-link pl-3" href="./support-center.html"><span class="ml-1">Acceuil</span></a> -->
                        <!-- <router-link  to="/plannings"  class="nav-link pl-3">
                            <span class="ml-1">Non Traité</span>
                        </router-link>
                        <router-link  to="/plannings"  class="nav-link pl-3">
                            <span class="ml-1">Traité</span>
                        </router-link> -->
                       <router-link  to="/rdv-approuve"  class="nav-link pl-3">
                            <span class="ml-1">Approbation</span>
                        </router-link>
                        <router-link  to="/rdv-transfert"  class="nav-link pl-3">
                            <span class="ml-1">Transfert</span>
                        </router-link>
                    </ul>
                </li>
                <li  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.profiler != null  && (this.$store.state.user_auth.profiler.profiler_designation == 'GESTIONNAIRE' || this.$store.state.user_auth.profiler.profiler_designation == 'FRONT-DESK')" class="nav-item w-100 d-none">
                    <!-- <a class="nav-link" href="salle.html"> -->
                    <router-link  to="/room"  class="nav-link">
                        <i class="fe fe-home fe-16"></i>
                        <span class="ml-3 item-text">Salle</span>
                    </router-link>
                        <!-- <span class="ml-3 item-text">Salle</span> -->
                    <!-- </a> -->
                </li>
                <li  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.profiler != null  && this.$store.state.user_auth.profiler.profiler_designation == 'GESTIONNAIRE'" class="nav-item w-100">
                    <!-- <a class="nav-link" href="pastor.html">
                    </a> -->
                    <router-link  to="/pastors"  class="nav-link">
                        <i class="fe fe-user fe-16"></i>
                        <span class="ml-3 item-text">Pasteur</span>
                                <!-- <span class="ml-1">Tickets</span> -->
                    </router-link>
                </li>
                <li  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.profiler != null  && this.$store.state.user_auth.profiler.profiler_designation == 'ADMIN'" class="nav-item w-100">
                    <router-link  to="/settings"  class="nav-link">
                        <i class="fe fe-settings fe-16"></i>
                        <span class="ml-3 item-text">Settings</span>
                    </router-link>
                </li>

                <li class="nav-item w-100   d-none">
                    <router-link  to="/calendar" class="nav-link">
                        <i class="fe fe-calendar fe-16"></i>
                        <span class="ml-3 item-text">Calendar</span>
                    </router-link>
                </li>

                <li  v-if="this.$store.state.user_auth != null && this.$store.state.user_auth.profiler != null  && this.$store.state.user_auth.profiler.profiler_designation == 'FRONT-DESK'" class="nav-item dropdown d-none">
                    <a href="#contact" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">
                        <i class="fe fe-book fe-16"></i>
                        <span class="ml-3 item-text">Contacts</span>
                    </a>
                    <ul class="collapse list-unstyled pl-4 w-100" id="contact">
                        <a class="nav-link pl-3" href="./contacts-list.html"><span class="ml-1">Contact List</span></a>
                        <a class="nav-link pl-3" href="./contacts-grid.html"><span class="ml-1">Contact Grid</span></a>
                        <a class="nav-link pl-3" href="./contacts-new.html"><span class="ml-1">New Contact</span></a>
                    </ul>
                </li>
            </ul>
        </nav>
    </aside>
  </div>
</template>

<script>

export default {
  name: 'HeaderPage',
  data() {
    return {
      user: null,
      // url: "http://udps_numerique.cd:81",
      requesting: false,
    };
  },
    methods: {
        deconnexionUser() {
            this.$store.state.user_auth = null;
            this.$router.push({ name: 'StartPage' });
        }
    }
}
</script>
