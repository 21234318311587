import {
    createApp
} from 'vue';

import GlobalMixins from './mixins/GlobalMixins';

// import AxiosPlugin from 'vue-axios-cors';
import {
    createStore
} from 'vuex';
import App from './App.vue';
import router from './routers';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


// import VueTelInput from 'vue-tel-input'


const app = createApp(App);
const options = {
    transition: "Vue-Toastification__fade",
    maxToasts: 4,
    newestOnTop: true,
    timeout: 4000
        // You can set your default options here
};
app.use(Toast, options);
// app.use(AxiosPlugin);





const store = createStore({
    state() {
        return {

            app_url_dev: "https://apirdvs.ce.church/api/",
            app_url_prod: "https://apirdvs.ce.church/api/",
            app_url_dev_web_site: "https://fd-rdv.ce.church",
            app_url_img_localhost: "https://fd-rdv.ce.church",

            // app_url_dev_web_site: "https://fd-rdv.ce.church",
            // app_url_dev: "http://api_rdvs.cd/api/",
            // app_url_prod: "http://127.0.0.1:8000/api/",
            // app_url_img_localhost: "http://localhost:8080",

            // app_url_dev_web_site: "http://localhost:5000",
            // app_url_dev: "http://localhost:5000/api/",
            // app_url_prod: "http://localhost:5000/api/",
            app_url_api_storage_img: "http://izicodeapis.cd:81/api/api_store_image_schoolbac",
            app_url_api_storage_img_dev: "http://localhost:8080/assets/images/avatars/",
            langage: null,
            user_auth: null,
            user_pass_reinitialisation: null,
            user_store: null,
            user_store_password: null,
            params: null,

            // Modules Mode Dev
            modulePublish: false
                // // End Modules Mode Dev

            // Modules Mode Prod
            // modulePublish: true
            // End Modules Mode Prod
        }
    },
    mutations: {
        selectlang(state, n) {
            state.langage = n
        },
        AUTHENTIFICATE_USER(state, user) {
            state.user_auth = user != "" ? user : null;
        },
    },
    actions: {
        deconnexion({
            commit
        }) {
            commit('AUTHENTIFICATE_USER', "")
        }
    }
})

// createApp(App).use(AxiosPlugin);
createApp(App).use(store).use(router).mixin(GlobalMixins).mount('#app');