<template>
   <div class="wrapper">
        <composite v-if="this.$store.state.user_auth != null"> </composite>
        <main role="main" class="main-content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <h2 class="page-title">Salle</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10 col-xl-12">
                        <div class="row justify-content-center mt-4">
                            <div class="col-md-10">
                                <div class="card shadow">
                                    <div class="card-body">
                                        <div class=" text-center">
                                            <h2 class="mb-1">{{ this.rooms.length  }}</h2>
                                        </div>
                                        <div class="row align-items-center text-center mb-1">
                                            <div class="col-auto">
                                                <div class="my-2 text-left">
                                                    <small class="text-success mx-2">ACTIF</small>
                                                    <small class="text-danger mr-2">NON ACTIF</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center progress rounded mb-3" style="height:14px">
                                            <div class="progress-bar bg-success" role="progressbar" :style="'width:'+ (Math.round((this.rooms.filter(function(val){return val.status_id == 1}).length/this.rooms.length)*100)) +'%'" >{{ (Math.round((this.rooms.filter(function (val) { return val.status_id == 1 }).length / this.rooms.length) * 100)) }}%</div>
                                            <div class="progress-bar bg-danger" role="progressbar"  :style="'width:' + (Math.round((this.rooms.filter(function (val){ return val.status_id != 1 }).length / this.rooms.length) * 100)) + '%'">{{ (Math.round((this.rooms.filter(function (val) { return val.status_id != 1 }).length / this.rooms.length) * 100)) }}%</div>
                                        </div>
                                        <div class="row align-items-center text-center mb-1 d-none">
                                            <div class="col-auto">
                                                <div class="my-2 text-left">
                                                    <small class="text-warning mx-2">UTILISEE</small>
                                                    <small class="text-danger mr-2">NON UTILISEE</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center progress rounded mb-3  d-none" style="height:14px">
                                            <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">70%</div>
                                            <div class="progress-bar bg-danger" role="progressbar" style="width: 30%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">30%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end section -->
                    </div>
                    <!-- /.col -->
                </div>



                <div class="row  justify-content-center">
                    <div v-if="this.rooms.length > 0" class="col-10 mt-5">
                        <div class="row">
                            <div class="col-md-7">
                                <form class="searchform searchform-lg">
                                    <input class="form-control form-control-lg bg-white rounded-pill pl-5" type="search" placeholder="rechercher salle" aria-label="Search" v-model="this.form.search">
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <!-- table -->
                        <table class="table table-borderless table-striped" v-if="this.rooms.length > 0">
                            <thead>
                                <tr>
                                    <th class="d-none">ID</th>
                                    <th>Etat</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(room, index) in getfilter_rooms"  :key="index" >
                                    <td class="text-muted small d-none">2007</td>
                                    <td class="text-center"><span :class="'dot dot-lg  mr-2 ' + (room.status_id == 1 ? 'bg-success':'bg-danger')"></span></td>
                                    <th scope="col">{{ room.room_designation }}</th>
                                    <td><button class="btn btn-sm dropdown-toggle more-horizontal" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="text-muted sr-only">Action</span>
                          </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="#">Edit</a>
                                            <a class="dropdown-item" href="#">Remove</a>
                                            <a class="dropdown-item" href="#">Assign</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <nav v-if="this.rooms.length > 0" aria-label="Table Paging" class="my-3">
                            <ul class="pagination justify-content-end mb-0">
                                <li class="page-item"><a class="page-link" href="#">Précédent</a></li>
                                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">Suivant</a></li>
                            </ul>
                        </nav>

                        <p v-if="this.rooms.length == 0">Aucun élément  disponible !!!</p>
                    </div>
                    <!-- .col -->
                </div>
                <!-- .row -->
            </div>
            <!-- .container-fluid -->
            <div class="modal fade modal-notif modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="defaultModalLabel">Notifications</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body">
                            <div class="list-group list-group-flush my-n3">
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-box fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Package has uploaded successfull</strong></small>
                                            <div class="my-0 text-muted small">Package is zipped and uploaded</div>
                                            <small class="badge badge-pill badge-light text-muted">1m ago</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-download fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Widgets are updated successfull</strong></small>
                                            <div class="my-0 text-muted small">Just create new layout Index, form, table</div>
                                            <small class="badge badge-pill badge-light text-muted">2m ago</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-inbox fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Notifications have been sent</strong></small>
                                            <div class="my-0 text-muted small">Fusce dapibus, tellus ac cursus commodo</div>
                                            <small class="badge badge-pill badge-light text-muted">30m ago</small>
                                        </div>
                                    </div>
                                    <!-- / .row -->
                                </div>
                                <div class="list-group-item bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <span class="fe fe-link fe-24"></span>
                                        </div>
                                        <div class="col">
                                            <small><strong>Link was attached to menu</strong></small>
                                            <div class="my-0 text-muted small">New layout has been attached to the menu</div>
                                            <small class="badge badge-pill badge-light text-muted">1h ago</small>
                                        </div>
                                    </div>
                                </div>
                                <!-- / .row -->
                            </div>
                            <!-- / .list-group -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal">Clear All</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade modal-shortcut modal-slide" tabindex="-1" role="dialog" aria-labelledby="defaultModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="defaultModalLabel">Shortcuts</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                        </div>
                        <div class="modal-body px-5">
                            <div class="row align-items-center">
                                <div class="col-6 text-center">
                                    <div class="squircle bg-success justify-content-center">
                                        <i class="fe fe-cpu fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Control area</p>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-activity fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Activity</p>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-droplet fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Droplet</p>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-upload-cloud fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Upload</p>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-users fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Users</p>
                                </div>
                                <div class="col-6 text-center">
                                    <div class="squircle bg-primary justify-content-center">
                                        <i class="fe fe-settings fe-32 align-self-center text-white"></i>
                                    </div>
                                    <p>Settings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- main -->
    </div>
</template>

<script>

import Composite from './layouts/Composite.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
    name: 'RoomPage',
    components: {
        Composite
    },

    data() {
        return {
        user: null,
        rooms_search: [],
        rooms: [],
        requesting: false,
        form: {
            search: "",
        },
        step: 1,
        checkForm: false,
        lottieloading: false
        };
    },
  
    setup() {
        const toast = useToast();
        return { toast }
    },

    mounted() {
        this.gb_loadReFreshUser();

         if (this.$store.state.user_auth != null && this.$store.state.user_auth.status.id == 1) {
             this.all_rooms();
             setInterval(() => {
                 this.all_rooms();
             }, 60000);
        }
    },
    methods: {
        async all_rooms() {
            await axios.get(this.$store.state.app_url_dev + "rooms").then(({ data }) => {
                if (data.status == 1) {
                    this.rooms = data.data;
                } else {
                    this.toast.error(data.message, {
                        timeout: 6000
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                    timeout: 2000
                });
            });
        },

        async RegisterUser() {
            if (this.Online()) {
            this.requesting = true;
            if (this.form.nom != null && this.form.prenom != null && this.form.sexe != null && this.form.email != null && this.form.telephone_format != null && this.form.password != null) {

                if (this.getValidNumber(this.form.telephone_format) && this.IsEmail(this.form.email)) {
                if (this.form.nom.trim().length >= 4 && this.form.nom.trim().length <= 16 && this.form.prenom.trim().length >= 4 && this.form.prenom.trim().length <= 16) {
                    this.form.telephone = this.getNumberFormat(this.form.telephone_format);
                    this.form.nom = this.gb_capitalisingFormat(this.form.nom.trim());
                    this.form.prenom = this.gb_capitalisingFormat(this.form.prenom.trim());

                    // var postRequest = {
                    //   ""
                    // }

                    await axios.post("https://www.inscription.schoolbac.com", this.form).then(({ data }) => {
                    this.requesting = false;
                    if (data.error == "false") {
                        this.user = data;
                        this.toast.success("Votre compte vient d'etre crée avec success !!!");
                        var message = "Bonjour " + this.form.nom + ". Voici le code de confirmation du compte crée.  Code : " + data.codeUser + " .  Ce code expire dans une minute, ne le partager pas.";
                        setTimeout(() => {
                        let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=basiluantemo@gmail.com&password=systeminnov2022&message=" + message + "&expediteur=Schoolbac&telephone=" + this.form.telephone;

                        axios.get(urlgetsms).then(() => { });
                        this.$store.state.user_auth = data;
                        this.$store.state.user_store = data;
                        this.$store.state.user_store_password = this.form.password;
                        this.refreshForm();
                        this.$router.push({ name: 'Confirm' });
                        }, 4500);

                    } else {
                        this.toast.error(data.message, {
                        timeout: 6000
                        });
                    }
                    }).catch(function (error) {
                    console.log(error);
                    this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
                        timeout: 2000
                    });
                    });

                } else {
                    if (this.form.nom.trim().length < 4 || this.form.prenom.trim().length < 4) {
                    this.toast.info("Le nom ou le prenom ne doit pas etre inférieur à 4 caractères !!!", {
                        timeout: 6000
                    });
                    } else {
                    // this.messageErrors = "";
                    this.toast.info("Le nom ou le prenom ne doit pas dépasser 16 caractères !!!", {
                        timeout: 6000
                    });
                    }
                }
                }

            } else {
                this.toast.error("Veuillez completer les champs manquants !!!", {
                timeout: 6000
                });
            }
            }
        },

        refreshForm() {
            this.form.nom = null,
            this.form.prenom = null,
            this.form.sexe = "M",
            this.form.telephone = null,
            this.form.password = null,
            this.form.email = null,
            this.messageErrors = null
        },

        access_step_two() {
            if (this.form.prenom != null && this.form.prenom.trim() != "" && this.form.nom != null && this.form.nom.trim() != "") {
            this.lottieloading = true;
            setTimeout(() => {
                this.step = 2;
                this.lottieloading = false
            }, 3000);
            } else {
            this.checkForm = true;
            this.toast.error("Veuillez compléter les champs manquants", {
                timeout: 5000
            });
            }
        },

        to_access_step_three() {
            if (this.form.prenom != null && this.form.prenom.trim() != "" && this.form.nom != null && this.form.nom.trim() != "") {
            this.lottieloading = true;
            setTimeout(() => {
                this.step = 3;
            }, 4000);
            } else {
            this.checkForm = true;
            this.toast.error("Veuillez compléter les champs manquants", {
                timeout: 5000
            });
            }
        },

        changesexe(typesexe) {
            if (this.isSexe) {
            this.isSexe = false;
            } else {
            this.isSexe = true;
            }
            this.form.sexe = typesexe;
        },

        change_type_input() {
            if (this.type_input_password == false) {
            this.type_input_password = true;
            } else {
            this.type_input_password = false;
            }
        },

        IsEmail(email) {
            var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
            if (!regex.test(email)) {
            this.toast.error("Adresse email invalide", {
                timeout: 5000
            });
            return false;
            } else {
            return true;
            }
        },

        // verifi numero e telephone
        getValidNumber(value) {
            if (value.length == 10 || value.length == 12 || value.length == 13) {

            if (value.length == 10) {
                if (value.substring(0, 1) == '0') {
                if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
                    value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
                    value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }

            } else if (value.length == 12) {

                if (value.substring(0, 3) == '243') {

                if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
                    value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
                    value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
                    return true;
                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
            } else if (value.length == 13) {

                if (value.substring(0, 1) == '+') {

                if (value.substring(1, 4) == '243') {
                    if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                    value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                    value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                    return true;
                    } else {
                    this.toast.error("Numéro de téléphone invalide", {
                        timeout: 5000
                    });
                    return false;
                    }

                } else {
                    this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                    });
                    return false;
                }

                } else {
                this.toast.error("Numéro de téléphone invalide", {
                    timeout: 5000
                });
                return false;
                }
            }

            } else {
            this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
            });
            return false;
            }
            return false;
        },

        getNumberFormat(value) {
            if (value.length > 0) {
            if (value.length == 13 && value.substring(0, 1) == '+') {
                return value;
            } else if (value.length == 12) {
                return '+' + value;
            } else if (value.length == 10) {
                return '+243' + value.substring(1, value.length);
            }
            }
        },
    },

    computed: {
    getfilter_rooms() {
                return this.rooms.filter((room) => {
                    return (
                        room.room_designation
                            .toLowerCase()
                            .includes(this.form.search.toLowerCase()) 
                    );
                });
            },
    },
}
</script>
